<template>
  <div class="up-examine track">
    <div class="back" @click="backHome()">返回</div>
    <div class="up-examine-content">
      <div class="up-examine-in" @click="linkOrder()" 
          v-if="
            user.roles[0]!='financial_staff'
          "
        >
        <div >
          <img src="/img/track/icon-1.png" class="icon-1">
          <span>订单追踪系统</span>
        </div>
        <img src="/img/track/icon-3.png" class="icon-2">
      </div>
      <div class="up-examine-in " @click="linkActivity()" >
        <div>
          <img src="/img/track/icon-2.png" class="icon-1">
          <span>区域费用申请追踪系统</span>
        </div>
        <img src="/img/track/icon-3.png" class="icon-2">
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'Upbox',
  data: function () {
    return {

    }
  },
  props: {
    type: String,
    data:Object,
    user:Object
  },
  methods:{
    backHome(){
      this.close()
    },
    close(){
        this.$emit('close');
    },
    ok(){
      this.$emit('ok');
    },
    linkOrder:function(){
      this.$router.push({path:'/track/order'})
    },
    linkActivity:function(){
      this.$router.push({path:'/track/Activity'})
      // if(this.user.roles[0]=='sales_director'||this.user.roles[0]=='financial_staff'||this.user.roles[0]=='regional_marketing_manager'){
      //   this.$router.push({path:'/examine/director/activity'})
      // }else{
      //   this.$router.push({path:'/examine/manager/activity'})
      // }
      
    },
  },
  mounted(){

  }
}
</script>
