<template>
  <div class="share">
    <img src="/img/logo.png" class="logo">
    <div class="share-box" >
      <template v-if="!error">
        <div class="share-user">
          <img src="/img/share/bg.png" class="bg" >
          <div><span>{{data.userName}}</span><br>给您分享了文件</div>
        </div>
        <div class="share-in" v-if="data.object">
          <div class="share-img">
            <img :src="'/img/files/'+getDot(setFiles(data.object)[1])+'.png'" :onerror="errorImg">
          </div>
          <div class="share-name">{{setFiles(data.object)[1]}}<br><abbr>{{(data.objectSize/1000/1000).toFixed(2)}}MB</abbr> </div>
        </div>
        <div class="share-down" @click="linkUrl(data.objectDownloadUrl,setFiles(data.object)[1])">下载文件</div>

        <!-- <a class="share-down" :download="data.objectUrl" @click="linkUrl(data.objectUrl)">下载文件</a> -->
      </template>
      <div class="share-user" v-if="error"><span>{{error}}</span></div>
      <!-- <div class="share-down back-home" @click="linkUrl('/')">返回首页</div> -->
      <!-- <div class="share-tips">请勿在微信中点击“下载文件”按钮，应使用“浏览器”进行文件下载。</div> -->
    </div>
  </div>
</template>
<script>


export default {
  name: 'Home',
  data: function () {
    return {
      // user:{}
      data:{},
      error:'',
      errorImg:"this.src='/img/files/other.png'",
    }
  },
  components: { },
  methods:{

    linkUrl(url){
      window.open(url)
    },
    getDot(file){
      if(!file){return}
      let dot = file.split('.')
      return dot[dot.length-1].toLowerCase()
    },
    setFiles(d){
        if(!d){return}
        let vd = d.split('/')
        if(vd[vd.length-1]==''){
          return [true,vd[vd.length-2],d]
        }else{
          return [false,vd[vd.length-1],d]
        }
    }
  },
  mounted(){
 
    var vm = this
    this.$axios.get('/downloads/share/'+this.$route.query.id, {}).then(function (res) {
      console.log(res)
      if(res.code==200){
        vm.data=res.data
      }else{
        vm.error=res.msg
        // 
      }
      // vm.user=res.user
    })
  }

  
  
}
</script>
