<template>
  <div class="track-in" >
    <div class="back" @click="back()">返回</div>
    <div class="title">修改追踪详情</div>
    <div class="track-in-box" v-if="data.orderType">
      <div class="track-in-box-line">
        <div class="lable">订单号</div>
        <div class="info">{{data.orderId}}</div>
      </div>
      <div class="track-in-box-line">
        <div class="lable">订单商品</div>
        <div class="info" v-html="getOrderInfo(data.details)">
          <!-- 呼吸机 X 1 <br>
          彩页 X 10 <br>
          购物袋 X 10 <br> -->
        </div>
      </div>
      <div class="track-in-box-line">
        <div class="lable">订单金额</div>
        <div class="info">¥{{getFloat2(data.orderAmount)}}</div>
      </div>
      <div class="track-in-box-line">
        <div class="lable">订单类型</div>
        <div class="info">{{typeMaps[data.orderType]}}</div>
      </div>
      <!-- <div class="info">
        <div class="input-box select-box">
          <div class="select-icon">
            <img src="/img/shop/icon-3.png">
          </div>
          <div class="select-show">{{(data.reasonOfDept.meetingTime)}}</div>
          <input type="date" v-model="data.reasonOfDept.meetingTime" class="date-select" >
        </div>
      </div> -->
      <!-- 科室会 -->
      <template v-if="data.orderType==0">
        <div class="track-in-box-line" >
          <div class="lable">会议时间</div>
          <div class="info">
            <div class="input-box select-box">
              <div class="select-icon">
                <img src="/img/shop/icon-3.png">
              </div>
              <div class="select-show">{{cutTime(data.reasonOfDept.meetingTime)}}</div>
              <input type="date" v-model="data.reasonOfDept.meetingTime" class="date-select" >
            </div>
          </div>
        </div>
        <div class="track-in-box-line" >
          <div class="lable">医院名称</div>
          <div class="info">
            <input type="text" v-model="data.reasonOfDept.hospitalName" placeholder="请填写医院名称" >
            <img src="/img/close.png" @click="data.reasonOfDept.hospitalName=''" class="input-close">
          </div>
        </div>
        <div class="track-in-box-line" >
          <div class="lable">科室名称</div>
          <div class="info">
            <input type="text" v-model="data.reasonOfDept.deptName" placeholder="请填写科室名称" >
            <img src="/img/close.png" @click="data.reasonOfDept.deptName=''" class="input-close">
          </div>
        </div>
        <div class="track-in-box-line">
          <div class="lable">照片</div>
          <div class="info">
            <Images v-model="data.logoPics" :getImages="data.logoPics?data.logoPics.split(','):[]"></Images>
            <abbr>(图片需要含logo)</abbr>
          </div>
        </div>
        <div class="track-in-box-line">
          <div class="lable">覆盖人数</div>
          <div class="info">
            <input type="text" v-model="data.numberOfPeople" placeholder="请填写覆盖人数" >
            <img src="/img/close.png" @click="data.numberOfPeople=''" class="input-close">
          </div>
        </div>
      </template>

      <!-- 区域会 -->
      <template v-if="data.orderType==1">
        <div class="track-in-box-line" >
          <div class="lable">会议时间</div>
          <div class="info">
            <div class="input-box select-box">
              <div class="select-icon">
                <img src="/img/shop/icon-3.png">
              </div>
              <div class="select-show">{{cutTime(data.reasonOfRegion.meetingTime)}}</div>
              <input type="date" v-model="data.reasonOfRegion.meetingTime" class="date-select" >
            </div>
          </div>
        </div>
        <div class="track-in-box-line" >
          <div class="lable">会议名称</div>
          <div class="info">
            <input type="text" v-model="data.reasonOfRegion.meetingName" placeholder="请填写会议名称" >
            <img src="/img/close.png" @click="data.reasonOfRegion.meetingName=''" class="input-close">
          </div>
        </div>
        <div class="track-in-box-line" >
          <div class="lable">会议地点</div>
          <div class="info">
            <input type="text" v-model="data.reasonOfRegion.meetingAddr" placeholder="请填写会议地点" >
            <img src="/img/close.png" @click="data.reasonOfRegion.meetingAddr=''" class="input-close">
          </div>
        </div>
        <div class="track-in-box-line">
          <div class="lable">照片</div>
          <div class="info">
            <Images v-model="data.logoPics" :getImages="data.logoPics?data.logoPics.split(','):[]"></Images>
            <abbr>(图片需要含logo)</abbr>
          </div>
        </div>
        <div class="track-in-box-line">
          <div class="lable">覆盖人数</div>
          <div class="info">
            <input type="text" v-model="data.numberOfPeople" placeholder="请填写覆盖人数">
            <img src="/img/close.png" @click="data.numberOfPeople=''" class="input-close">
          </div>
        </div>
      </template>

      <!-- KOL拜访 -->
      <template v-if="data.orderType==2">
        <div class="track-in-box-line" >
          <div class="lable">拜访时间</div>
          <div class="info">
            <div class="input-box select-box">
              <div class="select-icon">
                <img src="/img/shop/icon-3.png">
              </div>
              <div class="select-show">{{cutTime(data.reasonOfKol.visitTime)}}</div>
              <input type="date" v-model="data.reasonOfKol.visitTime" class="date-select" >
            </div>
          </div>
        </div>

        <div class="track-in-box-line" >
          <div class="lable">医院名称</div>
          <div class="info">
            <input type="text" v-model="data.reasonOfKol.hospitalName" placeholder="请填写医院名称" >
            <img src="/img/close.png" @click="data.reasonOfKol.hospitalName=''" class="input-close">
          </div>
        </div>
        <div class="track-in-box-line" >
          <div class="lable">科室名称</div>
          <div class="info">
            <input type="text" v-model="data.reasonOfKol.deptName" placeholder="请填写科室名称" >
            <img src="/img/close.png" @click="data.reasonOfKol.deptName=''" class="input-close">
          </div>
        </div>
        <div class="track-in-box-line" >
          <div class="lable">拜访人</div>
          <div class="info">
            <input type="text" v-model="data.reasonOfKol.kolName" placeholder="请填写拜访人" >
            <img src="/img/close.png" @click="data.reasonOfKol.kolName=''" class="input-close">
          </div>
        </div>
      </template>

      <!-- 产品动销 -->
      <template v-if="data.orderType==3">
        <div class="track-in-box-line" >
          <div class="lable">活动类别</div>
          <!-- <div class="info">动销活动</div> -->
          <div class="info">
            <div class="input-box select-box ">
              <div class="select-icon">
                <img src="/img/shop/icon-3.png">
              </div>
              <div class="select-show">{{data.reasonOfSale.activeCate}}</div>
              <select class="select" v-model="data.reasonOfSale.activeCate">
                <option value="动销项目" >动销项目</option>
              </select>
            </div>
          </div>
          <!-- <div class="info">{{data.reasonOfSale.activeCate}}</div> -->
        </div>

        <div class="track-in-box-line">
          <div class="lable">地区</div>

          <div class="info">{{data.reasonOfSale.distributor.addrProvince}} {{data.reasonOfSale.distributor.addrCity}}</div>
          <!-- <div class="info select-2">
            <div class="input-box select-box ">
              <div class="select-icon">
                <img src="/img/shop/icon-3.png">
              </div>
              <div class="select-show">{{getProvinceName(data.reasonOfSale.distributor.provinceId)}}</div>
              <select class="select" v-model="data.reasonOfSale.distributor.provinceId" @change="data.reasonOfSale.distributor.distributorId=''">
                <option value="">请选择</option>
                <option :value="r.provinceId" v-for="(r,i) in region" v-bind:key="i">{{r.provinceName}}</option>
              </select>
            </div>
          </div>
          <div class="info select-2" >
            <div class="input-box select-box ">
              <div class="select-icon">
                <img src="/img/shop/icon-3.png">
              </div>
              <div class="select-show">{{
                getCityName(getCitys(data.reasonOfSale.distributor.provinceId),data.reasonOfSale.distributor.cityId)?
                getCityName(getCitys(data.reasonOfSale.distributor.provinceId),data.reasonOfSale.distributor.cityId):'请选择'
                }}</div>
              <select class="select" v-model="data.reasonOfSale.distributor.cityId" @change="getDealer();data.reasonOfSale.distributor.distributorId=''">
                <option value="">请选择</option>
                <option :value="r.cityId" v-for="(r,i) in getCitys(data.reasonOfSale.distributor.provinceId)" v-bind:key="i">{{r.cityName}}</option>
              </select>
            </div>
          </div> -->
        </div>
        <div class="track-in-box-line">
          <div class="lable">经销商</div>
          <div class="info">{{data.reasonOfSale.distributor.distributorName}}</div>
          <!-- <div class="info">
            <div class="input-box select-box ">
              <div class="select-icon">
                <img src="/img/shop/icon-3.png">
              </div>
              <div class="select-show">{{
                getDealerName(data.reasonOfSale.distributor.distributorId)?
                getDealerName(data.reasonOfSale.distributor.distributorId):'请选择'
                }}</div>
              <select class="select" v-model="data.reasonOfSale.distributor.distributorId" @change="setDealerOther(data.reasonOfSale.distributor.distributorId)">
                <option :value="d.distributorId" v-for="(d,i) in dealer"  v-bind:key="i">{{d.distributorName}}</option>
              </select>
            </div>
          </div> -->
        </div>
        <div class="track-in-box-line">
          <div class="lable">年度指标</div>
          <div class="info">
            <!-- <input type="text" v-model="data.reasonOfSale.distributor.annualIndicators" placeholder="请填写年度指标" >
            <img src="/img/close.png" @click="data.reasonOfSale.distributor.annualIndicators=''" class="input-close"> -->
            {{data.reasonOfSale.distributor.annualIndicators}}
          </div>
        </div>
        <div class="track-in-box-line">
          <div class="lable">地址</div>
          <div class="info">
            <!-- <input type="text" v-model="data.reasonOfSale.distributor.addrDetail" placeholder="请填写地址" >
            <img src="/img/close.png" @click="data.reasonOfSale.distributor.addrDetail=''" class="input-close"> -->
            {{data.reasonOfSale.distributor.addrDetail}}
            <!-- {{data.consigneeAddr}} -->
          </div>
        </div>
        <div class="track-in-box-line">
          <div class="lable">收货地址</div>
          <!-- <div class="info">{{data.reasonOfSale.distributor.addrDetail}}</div> -->
          <div class="info">{{data.consigneeAddr}}</div>
        </div>
        <div class="track-in-box-line">
          <div class="lable">照片</div>
          <div class="info">
            <Images v-model="data.logoPics" :getImages="data.logoPics?data.logoPics.split(','):[]"></Images>
            <abbr>(图片需要含logo)</abbr>
          </div>
        </div>
      </template>
      
      <!-- 其他原因 -->
      <template v-if="data.orderType==4">
        <div class="track-in-box-line">
          <div class="lable">具体原因</div>
          <div class="info">
            <input type="text" v-model="data.reasonDes" placeholder="请填写具体原因" >
            <img src="/img/close.png" @click="data.reasonDes=''" class="input-close">
          </div>
        </div>
        <div class="track-in-box-line">
          <div class="lable">照片</div>
          <div class="info">
            <Images v-model="data.logoPics" :getImages="data.logoPics?data.logoPics.split(','):[]"></Images>
            <abbr>(图片需要含logo)</abbr>
          </div>
        </div>
      </template>

      <div class="track-in-btns is-one is-eidt">
        <!-- <div @click="edit()">修改</div> -->
        <div @click="enter()">确定</div>
      </div>
    </div>
    <Upbox @close="sendError=''"  v-if="sendError" :type="'tips'" :data="{state:upState,back:upBack,title:sendError}"></Upbox>
  </div>
</template>
<script>



import Images from '../../../components/Images.vue'
import Upbox from '../../../components/Upbox.vue'
export default {
  name: 'Track',
  data: function () {
    return {
      user:{},
      data:{},
      numberOfPeople:'',
      sendError:'',
      upBack:'返回',
      upState:'error',
      dealer:[],
      region:[]
    }
  },
  props: {
    orderId: Number,
    typeMaps:Array
  },
  components: {Images,Upbox},
  methods:{
    edit(){
      this.$emit('edit');
    },
    enter(){
      let vm=this
      let pushData=this.data
      
      if(vm.data.orderType==2){
        // alert(1)
        pushData.reasonOfKol.visitTime=vm.resetTime(vm.data.reasonOfKol.visitTime)
      }
      if(vm.data.orderType==0){
        // alert(2)
        pushData.reasonOfDept.meetingTime=vm.resetTime(vm.data.reasonOfDept.meetingTime)
      }
      if(vm.data.orderType==1){
        // alert(2)
        pushData.reasonOfRegion.meetingTime=vm.resetTime(vm.data.reasonOfRegion.meetingTime)
      }
      // 重写经销商id
      if(vm.data.orderType==3){
        pushData.reasonOfSale.distributorId=vm.data.reasonOfSale.distributor.distributorId
      }
      
      

      this.$axios.put('/business/orderTrack/reason', pushData).then(function (res) {
        if(res.code==200){
          vm.sendError='提交成功'
          vm.upBack=''
          vm.upState='ok'
          setTimeout(()=>{
            vm.$emit('back');
          },1000)
        }else{
          vm.sendError=res.msg
          vm.upState='error'
          vm.upBack='返回'
        }
        // 
      })
      // alert('信息已输出到控制台，请查看')
      // console.log(this.data)
      // this.$emit('back');
    },
    back(){
      this.$emit('back');
      // 
      // 
    },
    resizeTime(t){
      
      if(t){
        if(t.split('.').length>1){
          t= t.split('.')[0]
          return t.split('T')[0]+' '+t.split('T')[1]
        }
        return t.split('T')[0]+' '+t.split('T')[1]+':00'
      }else{
        return ''
      }
      
    },
    getOrderInfo(data){
      if(!data){return}
      let rd = ''

      data.map(d=>{
        rd += d.productName +'x'+ d.number +'<br>'
      })

      return rd
    },
    getProvinceName:function(id){
      let data=''
      let vm = this
      vm.region.map(d=>{
        if(d.provinceId==id){
          data = d.provinceName
        }
      })
      return data
    },
    getCitys:function(id){
      let data=[]
      let vm = this
      vm.region.map(d=>{
        // console.log(d)
        if(d.provinceId==id){
          data = d.cities
        }
      })
      return data
    },
    getCityName:function(city,id){
      let data=''
      if(city){
         city.map(d=>{
          if(d.cityId==id){
            data = d.cityName
          }
        })
      }
     
      return data
    },
    getDealerName:function(id){
      let data=''
      let vm = this
      vm.dealer.map(d=>{
        if(d.distributorId==id){
          data = d.distributorName
        }
      })
      return data
    },
    getDealer:function(){
      var vm = this
      this.$axios.get('/business/distributor/list?provinceId='+vm.data.reasonOfSale.distributor.provinceId+'&cityId='+vm.data.reasonOfSale.distributor.cityId, {
      }).then(function (res) {
        vm.dealer=res.rows
      })
    },
    setDealerOther:function(id){
        let data=''
        let vm = this
        vm.dealer.map(d=>{
          if(d.distributorId==id){
            data = d
          }
        })
        vm.data.reasonOfSale.distributor.addrDetail=data.addrDetail
        vm.data.reasonOfSale.distributor.annualIndicators=data.annualIndicators
        // console.log(data)
    },
    getFloat2: function(x){
      if (x != '.'){
        var f = Math.round(x * 100) / 100;
        var s = f.toString();
        var rs = s.indexOf('.');
        if (rs <= 0) {
          rs = s.length;
          s += '.';
        }
        while (s.length <= rs + 2) {
          s += '0';
        }
        return s;
      }else{
        return '0.00';
      }
    },
    cutTime(date){
      return date.split(' ')[0]
    },
    resetTime(date){
      date = date.split(' ')[0]
      return date +' 00:00:00'
    }
    // linkShop:function(){
    //   this.$router.push({path:'/shop'})
    // },
    // linkOrder:function(){
    //   this.$router.push({path:'/order'})
    // },
    // linkActivity:function(){
    //   this.$router.push({path:'/activity'})
    // },
    // linkFiles:function(){
    //   this.$router.push({path:'/files'})
    // },
  },
  mounted(){
    var vm = this
    this.$axios.get('/business/orderTrack/'+this.orderId, {}).then(function (res) {
      vm.data=res.data
      // vm.getDealer()
    })

    // this.$axios.get('/business/region', {}).then(function (res) {
    //   vm.region=res.data
    // })
  }

  
  
}
</script>
