<template>
  <div class="footer">
    <div class="icon-box" v-if="user.roles">
      <div class="in-box" @click="linkHome()">
        <div class="img-box">
          <img :src="id==1?'/img/footer/icon-1-1.png':'/img/footer/icon-1-0.png'">
        </div>
        <div :class="id==1?'fonts-box on':'fonts-box '" >首页</div>
      </div>

      <div class="in-box" @click="linkShop()" v-if="
      user.roles[0]!='distributor' && user.roles&&
      (
        user.user.dept.channel=='常规渠道'||
        user.user.dept.channel=='广阔渠道'||
        user.user.dept.channel=='医疗创新'||
        user.user.dept.channel=='常规CSM'||
        user.user.dept.channel=='广阔CSM'||
        user.user.dept.channel=='管理员'||
        user.user.dept.channel=='总经理室'||
        user.user.dept.channel=='港台销售部门'||
        user.user.dept.channel=='大中华区'||
        user.user.dept.channel=='测试渠道'
      )
      ">
        <div class="img-box">
          <img :src="id==2?'/img/footer/icon-2-1.png':'/img/footer/icon-2-0.png'">
        </div>
        <div :class="id==2?'fonts-box on':'fonts-box '">订购系统</div>
      </div>

      <div class="in-box" @click="linkActivity()" v-if="user.roles[0]!='distributor'">
        <div class="img-box">
          <img :src="id==5?'/img/footer/icon-5-1.png':'/img/footer/icon-5-0.png'">
        </div>
        <div :class="id==5?'fonts-box on':'fonts-box '">区域费用申请</div>
      </div>

      <div class="in-box" @click="linkFiles()" v-if="user.roles[0]=='distributor'">
        <div class="img-box">
          <img :src="id==3?'/img/footer/icon-3-1.png':'/img/footer/icon-3-0.png'">
        </div>
        <div :class="id==3?'fonts-box on':'fonts-box '">下载系统</div>
      </div>
      <!-- <div class="in-box" @click="noShow()">
        <div class="img-box">
          <img :src="id==4?'/img/footer/icon-4-1.png':'/img/footer/icon-4-0.png'">
        </div>
        <div :class="id==4?'fonts-box on':'fonts-box '">更多</div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data: function () {
    return {
      user:{}
    }
  },
  props: {
    id: Number
  },
  methods:{
    noShow(){
      alert('暂未开放')
    },
    linkFiles:function(){
      // this.$router.push({path:'/shop'})
      window.location.href='/files'
    },
    linkShop:function(){
      // this.$router.push({path:'/shop'})
      window.location.href='/shop'
    },
    linkHome:function(){
      window.location.href='/'
      // this.$router.push({path:'/'})
    },
    linkActivity:function(){
      window.location.href='/activity'
      // this.$router.push({path:'/'})
    }
  },
  mounted(){
    var vm = this
    this.$axios.get('/getInfo', {}).then(function (res) {
      console.log(res)
      if(res.code!=200){
        window.location.href='/login'
        return
      }
      vm.user=res
      vm.$emit('input',res);
    })
  }
}
</script>
