<template>
  <div class="up-boxs">
    
    <div class="eject-box">
      <div class="tips-box" v-if="type=='tips'">
        <img src="/img/upbox/error.png" class="tips-icon" v-if="data.state=='error'">
        <img src="/img/upbox/ok.png" class="tips-icon" v-if="data.state=='ok'">
        <div class="tips-title">{{data.title}}</div>
        <div class="tips-info" v-html="data.desc"></div>
        <div class="tips-btn" @click="close" v-if="data.back">{{data.back}}</div>
        <div class="tips-btn ok" @click="ok" v-if="data.ok&&!isClick">{{data.ok}}</div>
        <div class="tips-btn ok"  v-if="data.ok&&isClick">处理中，请稍后</div>
      </div>
      <div class="tips-box" v-if="type=='express'">
        <div class="up-title">查看快递详情</div>
        <img src="/img/order/icon-4.png" class="express-close" @click="close">
        <div v-for="(e,i) in express" v-bind:key="i" class="express-box" >
          <div class="express-info">快递公司<span>{{e.expressName}}</span></div>
          <div class="express-info">快递单号<span>{{e.expressNumber}}</span></div>
          <div class="tips-btn" @click="linkUrl(e.expressNumber)">查看快递详情</div>
        </div>
      </div>
      <div class="text-box" v-if="type=='text'">
        <div class="tips-title">{{data.title}}</div>
        <textarea v-model="approvalDes"></textarea>
        <div class="text-btns-box">
          <div class="tips-btn" @click="close" v-if="data.back">{{data.back}}</div>
          <div class="tips-btn ok" @click="ok" v-if="data.ok">{{data.ok}}</div>
<!--          <div class="tips-btn ok"  v-if="data.ok&&isClick">处理中</div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Upbox',
  data: function () {
    return {
      isClick:false,
      approvalDes:''
    }
  },
  props: {
    type: String,
    data:Object,
    express:Array
  },
  methods:{
    close(){
        this.$emit('close');
    },
    ok(){
      this.isClick=true
      this.$emit('ok',this.approvalDes);
    },
    linkUrl(o){
      window.open('https://www.baidu.com/s?wd='+o)
    }
  }
}
</script>
