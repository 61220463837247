<template>
  <div class="files oc">
    <div class="top-search">
      <div class="top-search-box">
        <div class="btns mr" v-if="minPath&&name==''" @click="back= 'time' + new Date().getTime()">返回</div>
        <div class="top-inpus">
          <img src="/img/files/icon-1.png">
          <input type="text" placeholder="搜索" v-model="name">
        </div>
        <div class="btns ml" v-if="name!=''" @click="name=''">取消</div>
      </div>
    </div>
    <div class="files-home-box" v-if="user.roles">
      
      <div class="files-list" v-if="!minPath&&name==''">
        <div  class="files-inline" @click="minPath='产品课件/'" >
          <div class="fonts-box">
            <div class="img-box">
              <img src="/img/files/icon-2.png">
            </div>
            <span>产品课件</span>
          </div>
          <img src="/img/files/icon-7.png">
        </div>
        <div  class="files-inline" @click="minPath='宣传资料/'">
          <div class="fonts-box">
            <div class="img-box">
              <img src="/img/files/icon-3.png">
            </div>
            <span>宣传资料</span>
          </div>
          <img src="/img/files/icon-7.png">
        </div>
        <div  class="files-inline" @click="minPath='文献证据/'" >
          <div class="fonts-box">
            <div class="img-box">
              <img src="/img/files/icon-4.png">
            </div>
            <span>文献证据</span>
          </div>
          <img src="/img/files/icon-7.png">
        </div>
        <div  class="files-inline" @click="minPath='说明书/'">
          <div class="fonts-box">
            <div class="img-box">
              <img src="/img/files/icon-5.png">
            </div>
            <span>说明书</span>
          </div>
          <img src="/img/files/icon-7.png">
        </div>
        <div  class="files-inline" @click="minPath='注册证/'">
          <div class="fonts-box">
            <div class="img-box">
              <img src="/img/files/icon-10.png">
            </div>
            <span>注册证</span>
          </div>
          <img src="/img/files/icon-7.png">
        </div>
        <div  class="files-inline" @click="minPath='视频/'" >
          <div class="fonts-box">
            <div class="img-box">
              <img src="/img/files/icon-6.png">
            </div>
            <span>视频</span>
          </div>
          <img src="/img/files/icon-7.png">
        </div>
        <div  class="files-inline" @click="minPath='其他/'" v-if="user.roles[0]!='homecare_distributor'&&user.roles[0]!='medical_distributor'">
          <div class="fonts-box">
            <div class="img-box">
              <img src="/img/files/icon-9.png">
            </div>
            <span>其他资料</span>
          </div>
          <img src="/img/files/icon-7.png">
        </div>
      </div>
      <Folder @back="minPath=''" :user="user" @change="change" :minPath='minPath' :name="name" :back="back" v-if="minPath||name!=''"></Folder>
    </div>
    
    <Footer v-model="user" :id="3"></Footer>
  </div>
</template>
<script>
import Footer from '../components/Footer.vue'
import Folder from '../components/Files/Folder.vue'


export default {
  name: 'Files',
  data: function () {
    return {
      examineShow:false,
      user:{},
      step:0,
      search:'',
      name:'',
      minPath:'',
      back:''

    }
  },
  components: { Footer,Folder},
  methods:{
    change(e){
      this.name=''
      this.minPath=e
    }
  },
  mounted(){
    // var vm = this
    // this.getFiles()
  }

  
  
}
</script>
