<template>
  <div class="track-in" >
    <div class="back" @click="back()">返回</div>
    <div class="title">订单追踪详情</div>
    <div class="track-in-box" >
      <div class="track-in-box-line">
        <div class="lable">活动名称</div>
        <div class="info">{{data.activityName}}</div>
      </div>
      <div class="track-in-box-line">
        <div class="lable">活动开始时间</div>
        <div class="info" >{{data.activityBeginTime}}</div>
      </div>
      <div class="track-in-box-line">
        <div class="lable">活动结束时间</div>
        <div class="info" >{{data.activityEndTime}}</div>
      </div>
      <div class="track-in-box-line">
        <div class="lable">活动地址</div>
        <div class="info">{{data.activityAddr}}</div>
      </div>
      <div class="track-in-box-line">
        <div class="lable">申请金额</div>
        <div class="info">{{data.activityAmount}}元</div>
      </div>
      <div class="track-in-box-line" v-if="data.signInPics">
        <div class="lable">电子签到表</div>
        <div class="info">
          <img :src="baseUrl+i" v-for="i in data.signInPics.split(',')" v-bind:key="i" class="images" @click="showImg=baseUrl+i">
        </div>
      </div>
      <div class="track-in-box-line" v-if="data.scenePics">
        <div class="lable">现场照片</div>
        <div class="info">
          <img :src="baseUrl+i" v-for="i in data.scenePics.split(',')" v-bind:key="i" class="images" @click="showImg=baseUrl+i">
        </div>
      </div>
      
      <div class="track-in-btns is-one">
        <div @click="edit()">修改</div>
        <!-- <div @click="enter()">确定</div> -->
      </div>
    </div>
    <Upbox @close="sendError=''"  v-if="sendError" :type="'tips'" :data="{state:upState,back:upBack,title:sendError}"></Upbox>
    <div class="show-img-box" v-if="showImg" @click="showImg=''">
      <img :src="showImg">
    </div>
  </div>
</template>
<script>



// import Images from '../../../components/Images.vue'
import Upbox from '../../../components/Upbox.vue'
export default {
  name: 'Track',
  data: function () {
    return {
      user:{},
      data:{},
      numberOfPeople:'',
      sendError:'',
      upBack:'返回',
      upState:'error',
      baseUrl:process.env.VUE_APP_BASE_URL,
      showImg:''
    }
  },
  props: {
    orderId: Number,
    typeMaps:Array
  },
  components: {Upbox},
  methods:{
    edit(){
      this.$emit('edit');
    },
    enter(){
      let vm=this
      this.$axios.put('/business/orderTrack', {
        orderId:this.orderId,
        logoPics:this.data.logoPics,
        numberOfPeople:this.numberOfPeople
      }).then(function (res) {
        if(res.code==200){
          vm.sendError='提交成功'
          vm.upBack=''
          vm.upState='ok'
          setTimeout(()=>{
            vm.$emit('back');
          },1000)
        }else{
          vm.sendError=res.msg
          vm.upState='error'
          vm.upBack='返回'
        }
        // 
      })
    },
    back(){
      this.$emit('back');
      // 
      // 
    },
    getOrderInfo(data){
      if(!data){return}
      let rd = ''

      data.map(d=>{
        rd += d.productName +'x'+ d.number +'<br>'
      })

      return rd
    },
    cutTime(date){
      return date.split(' ')[0]
    },
    getFloat2: function(x){
      if (x != '.'){
        var f = Math.round(x * 100) / 100;
        var s = f.toString();
        var rs = s.indexOf('.');
        if (rs <= 0) {
          rs = s.length;
          s += '.';
        }
        while (s.length <= rs + 2) {
          s += '0';
        }
        return s;
      }else{
        return '0.00';
      }
    }
    // linkShop:function(){
    //   this.$router.push({path:'/shop'})
    // },
    // linkOrder:function(){
    //   this.$router.push({path:'/order'})
    // },
    // linkActivity:function(){
    //   this.$router.push({path:'/activity'})
    // },
    // linkFiles:function(){
    //   this.$router.push({path:'/files'})
    // },
  },
  mounted(){
    var vm = this
    this.$axios.get('/business/activityTrack/'+this.orderId, {}).then(function (res) {
      console.log(res)
      vm.data=res.data
    })
    // if(this.$route.query.type){
    //   window.location.href='/'+this.$route.query.type+window.location.search
    // }
  }

  
  
}
</script>
