<template>
  <div class="viewers" >
      <div class="video-viewers">
        <video 
          :src="mp4" 
          controls
          webkit-playsinline="true"
          x-webkit-airplay="true"
          playsinline="true"
          x5-video-player-type="h5"
          x5-video-orientation="h5"
          x5-video-player-fullscreen="true"
          preload="auto"
          style="object-fit:fill"
        ></video>
      </div>
      <div class="back" @click="back()">返回</div>
  </div>
</template>
<script>
export default {
  name: 'Folder',
  data: function () {
    return {

    }
  },
  props: {
    mp4:String
  },
  methods:{
    back(){
    this.$emit('back');
    }
  },
  mounted(){

  }
}
</script>
