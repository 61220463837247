<template>
  <div class="global-goods-line">
    <div class="global-goods-img">
      <img :src="data.pictures?baseUrl+data.pictures.split(',')[0]:'/img/shop/black.jpg'"  >
    </div>
    
    <div class="global-goods-info">
      <div class="global-goods-desc">
        <div>{{data.productName}}</div>
        <div v-if="data.productCode">产品型号：{{data.productCode}}</div>
        <div v-if="!isOrder">产品库存：{{productSize}}</div>
      </div>
      <div :class="isOrder?'global-goods-control order-szie':'global-goods-control'" >
        <div class="global-goods-control-price">¥{{getFloat2(data.productPrice)}}</div>
        <div class="global-goods-control-box" v-if="!isOrder" >
          <div  @click="cut()">
            <img src="/img/shop/cut.png" v-if="size>0">
            <img src="/img/shop/cut-1.png" v-if="size<=0">
          </div>
          <div>
            <input type="text" v-model="size" @change="inputChange()">
            <!-- {{size}} -->
          </div>
          <!-- :class="size<(data.productCount-data.applyCount)?'global-control-box on':'global-control-box'" -->
          <!-- {{data.applyCount}} -->
          <div   @click="add()">
            <img src="/img/shop/add.png" v-if="size<productSize">
            <img src="/img/shop/add-1.png" v-if="size>=productSize">
          </div>
        </div>
        <div class="global-goods-control-box " v-if="isOrder">

          <div>X {{size}}</div>
          
        </div>
      </div>
    </div>
    <Upbox @close="sendError=''"  v-if="sendError" :type="'tips'" :data="{back:'确认',title:sendError}"></Upbox>
  </div>
</template>

<script>
import Upbox from '../components/Upbox.vue'
export default {
  name: 'Goods',
  components: {Upbox},
  data: function () {
    return {
      size:this.data.size?this.data.size:0,
      baseUrl:process.env.VUE_APP_BASE_URL,
      productSize:this.data.productCount-this.data.applyCount,
      sendError:''
    }
  },
  props: {
    id: Number,
    isOrder:Boolean,
    data:Object
  },
  methods:{
    change(){
      this.$emit('change');
    },
    inputChange(){
      if(this.size[0]==0){
        this.size=this.size.replace(/\b(0+)/gi,"")
      }
      let has = this.data.productCount-this.data.applyCount
      if(this.size>has){
        this.size=has
        this.inputChange()
        return
      }
      this.$emit('input', this.size)
      this.change()
    },
    cut:function(){
      if(this.size<=0){this.sendError='订单数不可小于0';return}
      this.size--
      this.$emit('input', this.size)
      this.change()
    },
    add:function(){
      if(this.size>=(this.data.productCount-this.data.applyCount)){this.sendError='库存不足';return}
      this.size++
      this.$emit('input', this.size)
      this.change()
    },
    getFloat2: function(x){
      if (x != '.'){
        var f = Math.round(x * 100) / 100;
        var s = f.toString();
        var rs = s.indexOf('.');
        if (rs <= 0) {
          rs = s.length;
          s += '.';
        }
        while (s.length <= rs + 2) {
          s += '0';
        }
        return s;
      }else{
        return '0.00';
      }
    }
  },
  mounted(){
    // console.log(this.data.productCount)
    // console.log(this.size<this.productSize)
  }
}
</script>
