<template>

    <div class="up-examine weekly">
      <div class="up-examine-content">
        <div class="up-examine-in" @click="weeklyListShow=true;weeklyState='send'" v-if="user.user&&(user.user.weeklyRole!=5 || user.user.phonenumber!=13911899820)">
          <div  >
            <img src="/img/weekly/icon-1.png" class="icon-1">
            <span>提交周报</span>
          </div>
          <img src="/img/weekly/icon-1-1.png" class="icon-">
        </div>
        <div class="up-examine-in" @click="weeklyListShow=true;weeklyState='get'" v-if="user.user&&(user.user.weeklyRole!=5 || user.user.phonenumber!=13911899820)">
          <div>
            <img src="/img/weekly/icon-2.png" class="icon-1">
            <span>我的周报</span>
          </div>
          <img src="/img/weekly/icon-2-1.png" class="icon-2">
        </div>

        <div class="up-examine-in" @click="weeklyTypes='1';weeklyType='1';region=true" v-if="user.user&&user.user.weeklyRole==2" >
          <div>
            <img src="/img/weekly/icon-3.png" class="icon-1">
            <span>查看省区销售周报</span>
          </div>
          <img src="/img/weekly/icon-3-1.png" class="icon-2">
        </div>

<!--        <div class="up-examine-in" @click="weeklyTypes='1,2';commissioner=true" v-if="user.user&&user.user.weeklyRole==5"   >-->
        <div class="up-examine-in" @click="weeklyTypes='1';commissioner=true" v-if="user.user&&(user.user.weeklyRole==5 || user.user.weeklyRole==6)">
          <div>
            <img src="/img/weekly/icon-3.png" class="icon-1">
            <span>查看省区销售周报</span>
          </div>
          <img src="/img/weekly/icon-3-1.png" class="icon-2">
        </div>
        <div class="up-examine-in" @click="weeklyTypes='2';commissioner=true" v-if="user.user&&(user.user.weeklyRole==5 || user.user.weeklyRole==6)">
          <div>
            <img src="/img/weekly/icon-1.png" class="icon-1">
            <span>查看大区经理周报</span>
          </div>
          <img src="/img/weekly/icon-3-1.png" class="icon-2">
        </div>
        <div class="up-examine-in" @click="weeklyTypes='6';weeklyType=6;region=true" v-if="user.user&&(user.user.weeklyRole==5)">
          <div>
            <img src="/img/weekly/icon-2.png" class="icon-1">
            <span>查看渠道经理周报</span>
          </div>
          <img src="/img/weekly/icon-3-1.png" class="icon-2">
        </div>
        <div class="up-examine-in" @click="weeklyTypes='4';commissioner=true" v-if="user.user&&user.user.weeklyRole==5">
          <div>
            <img src="/img/weekly/icon-2.png" class="icon-1">
            <span>查看客户成功经理周报</span>
          </div>
          <img src="/img/weekly/icon-3-1.png" class="icon-2">
        </div>

        <div class="up-examine-in" @click="weeklyTypes='3';weeklyType='3';region=true" v-if="user.user&&(user.user.weeklyRole==5 || user.hasKaRole)">
          <div>
            <img src="/img/weekly/icon-4.png" class="icon-1">
            <span>查看KA周报</span>
          </div>
          <img src="/img/weekly/icon-3-1.png" class="icon-2">
        </div>




        <div class="up-examine-in" @click="weeklyTypes='4';weeklyType='4';region=true" v-if="user.user&&user.user.weeklyRole==2">
          <div>
            <img src="/img/weekly/icon-4.png" class="icon-1">
            <span>查看客户成功经理周报</span>
          </div>
          <img src="/img/weekly/icon-4-1.png" class="icon-2">
        </div>


        <div class="up-examine-in" @click="stc=true;setWeeklyType=1" v-if="user.user&&( user.user.weeklyRole==3 || user.hasKaRole    )"  >
          <div>
            <img src="/img/weekly/icon-3.png" class="icon-1">
            <span>查看省区销售STC信息</span>
          </div>
          <img src="/img/weekly/icon-3-1.png" class="icon-2">
        </div>

        <div class="up-examine-in" @click="stc=true;setWeeklyType=2" v-if="user.user&&( user.user.weeklyRole==3 || user.hasKaRole  )" >
          <div>
            <img src="/img/weekly/icon-4.png" class="icon-1">
            <span>查看大区经理STC信息</span>
          </div>
          <img src="/img/weekly/icon-3-1.png" class="icon-2">
        </div>


        <div class="up-examine-in" @click="weeklyTypes='1';weeklyType='1';region=true" v-if="user.user&&user.user.weeklyRole==4" >
          <div>
            <img src="/img/weekly/icon-3.png" class="icon-1">
            <span>查看省区销售周报</span>
          </div>
          <img src="/img/weekly/icon-3-1.png" class="icon-2">
        </div>

        <div class="up-examine-in" @click="weeklyTypes='2';weeklyType='2';region=true" v-if="user.user&&user.user.weeklyRole==4" >
          <div>
            <img src="/img/weekly/icon-4.png" class="icon-1">
            <span>查看大区经理周报</span>
          </div>
          <img src="/img/weekly/icon-3-1.png" class="icon-2">
        </div>

      </div>
      <Footer v-model="user" :id="3"></Footer>
      <WeeklyList v-if="weeklyListShow" :weeklyState="weeklyState" @close="close()"></WeeklyList>
      <Region v-if="region" @close="close()" :commissioner='commissioner' :weeklyTypes="weeklyTypes" :weeklyType="weeklyType"></Region>
      <Stc v-if="stc" @close="close()" :setWeeklyType="setWeeklyType"></Stc>
      <Commissioner v-if="commissioner" @close="close()" :weeklyTypes="weeklyTypes"></Commissioner>
    </div>

</template>
<script>
import Footer from '../components/Footer.vue'
import WeeklyList from '../components/weekly/WeeklyList.vue'
import Region from '../components/weekly/Region.vue'
import Stc from '../components/weekly/Stc.vue'
import Commissioner from '../components/weekly/Commissioner.vue'
export default {
  name: 'Files',
  data: function () {
    return {
      user:{},
      weeklyListShow:false,
      weeklyState:'get',
      region:false,
      stc:false,
      weeklyTypes:"1",
      weeklyType:"1",
      commissioner:false,
      setWeeklyType:1
    }
  },
  components: { Footer,WeeklyList,Region,Stc,Commissioner},
  methods:{
    close(){
      this.weeklyListShow=false
      this.region=false
      this.stc=false
      this.commissioner = false
    },
    change(e){
      // this.name=''
      // this.minPath=e
    }
  },
  mounted(){
    // var vm = this
    // this.getFiles()
  }



}
</script>
