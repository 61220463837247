<template>
  <div class="track" >
    <div class="back" @click="backHome()">返回</div>
    <div class="progress-all" v-if="list.length!=0">
      <span>总进度</span>
      <div class="progress-box">
        <div class="progress-line" :style="{width:otherData.rateOfProcess+'%'}"></div>
      </div>
      <abbr>{{otherData.rateOfProcess}}%</abbr>
    </div>
    <div class="progress-all" v-if="list.length==0">
      <span>暂无追踪详情</span>
    </div>
    <div class="track-box" :key="demoKey">
      
      
      <div class="track-info" v-for="(l,i) in list" v-bind:key="i">
        <div class="order-number">活动名称：{{l.activityName}}</div>
        <div class="order-info">
          活动开始时间：{{l.activityBeginTime}}<br>
          活动结束时间：{{l.activityEndTime}}<br>
          活动地址：{{l.activityAddr}}<br>
          申请金额：{{l.activityAmount}}元<br>
        </div>
        <div class="order-progress">总进度：{{l.rateOfProcess==100?'已完成':'未完成'}}</div>
        <div class="track-btns">
          <div @click="orderId=l.activityId;trackState='track'" v-if="l.rateOfProcess!=100">填写追踪详情</div>
          <div @click="orderId=l.activityId;trackState='show'" v-if="l.rateOfProcess==100">查看追踪详情</div>
          <div @click="orderId=l.activityId;trackState='edit'" v-if="l.rateOfProcess==100">修改追踪详情</div>
        </div>
      </div>

      <!-- <div class="track-info" >
        <div class="order-number">活动名称：北京世界睡眠日会议</div>
        <div class="order-info">
          活动时间：2022-03-21至2022年-03-31<br>
          活动地址：北京市国贸三期<br>
          申请金额：5000元<br>
        </div>
        <div class="order-progress">总进度：已完成</div>
        <div class="track-btns">
          <div @click="trackState='show'">查看追踪详情</div>
          <div @click="trackState='edit'" >修改追踪详情</div>
        </div>
      </div> -->
    </div>

    <Footer v-model="user" :id="2"></Footer>
    <TrackPage :typeMaps="typeMaps" :orderId="orderId" @edit="trackState='edit'" @back="trackState=''" v-if="trackState=='track'"></TrackPage>

    <TrackPageShow :typeMaps="typeMaps" :orderId="orderId" @edit="trackState='edit'" @back="trackState=''" v-if="trackState=='show'"></TrackPageShow>

    <TrackPageEdit :typeMaps="typeMaps" :orderId="orderId"  @back="trackState=''" v-if="trackState=='edit'"></TrackPageEdit>

  </div>
</template>
<script>


import Footer from '../../components/Footer.vue'
import TrackPage from '../../components/Track/Activity/Track.vue'
import TrackPageShow from '../../components/Track/Activity/Show.vue'
import TrackPageEdit from '../../components/Track/Activity/Edit.vue'
export default {
  name: 'Track',
  data: function () {
    return {
      user:{},
      progres:50,
      trackState:'',
      pageNum:1,
      pageSize:99999,
      otherData:{},
      list:[],
      typeMaps:['科室会','区域会','KOL拜访','产品动销','其他原因'],
      orderId:0,
      demoKey:'demo-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
    }
  },
  components: { Footer,TrackPage,TrackPageShow,TrackPageEdit},
  watch:{
    trackState(){
      this.getList()
    }
  },
  methods:{
    backHome(){
      this.$router.push({path:'/'})
    },
    changeDemo(){
      this.demoKey='demo-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
    },
    getOrderInfo(data){
      if(data.length!=1){
        return '包含'+data[0].productName+'*'+data[0].number+'等'+data.length+'件商品'
      }else{
        return data[0].productName+'*'+data[0].number
      }
    },
    getList(){
      var vm = this
      this.$axios.get('/business/activityTrack/list', {pageNum:this.pageNum,pageSize:this.pageSize}).then(function (res) {
        // console.log(res)
        vm.list=res.rows
        vm.otherData=res.otherData
        // this.changeDemo()
      })
    }
    // linkShop:function(){
    //   this.$router.push({path:'/shop'})
    // },
    // linkOrder:function(){
    //   this.$router.push({path:'/order'})
    // },
    // linkActivity:function(){
    //   this.$router.push({path:'/activity'})
    // },
    // linkFiles:function(){
    //   this.$router.push({path:'/files'})
    // },
  },
  mounted(){
    this.getList()
    // if(this.$route.query.type){
    //   window.location.href='/'+this.$route.query.type+window.location.search
    // }
  }

  
  
}
</script>
