<template>
  <div class="shop">
    <div class="top-bar">
      <div class="in-bar">
        <div class="icon-btn" v-if="isSearch" @click="isSearch=false">
          <img src="/img/shop/icon-2.png">
        </div>
        <div class="select-box" v-if="!isSearch">
          <div :class="isSelect=='daily'?'on':''" @click="isSelect='daily';clearSize();selectClose=true;reasonState=false;reason='科室会'">日常资料</div>
          <div :class="isSelect=='sample'?'on':''" @click="isSelect='sample';clearSize();selectClose=true;reasonState=false;reason='科室会'">样品相关</div>
          <div :class="isSelect=='material'?'on':''" @click="isSelect='material';clearSize();selectClose=false;reasonState=false;reason='产品动销'">动销物料</div>
        </div>
        <div class="icon-btn" v-if="!isSearch" @click="isSearch=true">
          <img src="/img/shop/icon-1.png">
        </div>
        <div class="search-box" v-if="isSearch" >
          <!-- @click="tipsShow=true" -->
          <input type="text" placeholder="请输入搜索内容" v-model="search" >
        </div>
      </div>

    </div>
    <div class="content-box" >
      <!-- 日常资料 -->
      <div class="daily" v-if="isSelect=='daily'">
        <div class="left">
          <div :class="dailySelect=='0'?'on':''" @click="dailySelect='0'">品牌提示物</div>
          <div :class="dailySelect=='1'?'on':''" @click="dailySelect='1'">彩页</div>
          <!-- <div :class="dailySelect=='2'?'on':''" @click="dailySelect='2'">购物袋</div> -->
        </div>
        <div class="right"  >
          <template v-for="(l,i) in list">
            <div  class="goods-line" @click="setReason()"  v-bind:key="i" v-if="l.firstCategory==0&&l.secondCategory==dailySelect&&l.productName.indexOf(search) != -1">
              <Goods @change="changeDemo()" :key="demoKey" :id="i" :data="l" v-model="l.size" ></Goods>
            </div>
          </template>
        </div>
      </div>
      <!-- 动销物料 -->
      <div class="sample" v-if="isSelect=='material'">
        <template v-for="(l,i) in list">
          <div class="goods-line" @click="setReason()" v-bind:key="i" v-if="l.firstCategory==1&&l.productName.indexOf(search) != -1">
            <Goods  @change="changeDemo()" :key="demoKey" :id="i" :data="l" v-model="l.size" ></Goods>
          </div>
        </template>
      </div>
        <!-- 样品相关 -->
      <div class="sample" v-if="isSelect=='sample'">
        <template v-for="(l,i) in list">
          <div class="goods-line" @click="setReason()" v-bind:key="i" v-if="l.firstCategory==2&&l.productName.indexOf(search) != -1">
            <Goods  @change="changeDemo()" :key="demoKey" :id="i" :data="l" v-model="l.size" ></Goods>
          </div>
        </template>
      </div>
    </div>

    <!-- 购物车显示 -->
    <div class="buy-up" v-if="buyCar">

      <!-- 日常资料 -->
      <div class="buy-box" v-if="isSelect=='daily'">
        <div class="buy-inline" v-if="secondCategoryHas(0)">
          <div class="left">品牌提示物</div>
          <div class="right">
            <template v-for="(l,i) in list">
              <div class="goods-line" @click="setReason()" v-bind:key="i" v-if="l.firstCategory==0&&l.secondCategory==0&&l.size">
                <Goods  @change="changeDemo()" :id="i" :data="l" v-model="list[i].size" ></Goods>
              </div>
            </template>
            <div class="info">
              <div >品牌提示物订单总价：¥{{secondCategoryAllPrice(0)}}</div>

              <div>品牌提示物剩余预算：¥{{getFloat2(user.user.budgetGift-user.user.budgetGiftApply-secondCategoryAllPrice(0))}}</div>
            </div>
          </div>
        </div>
        <div class="buy-inline" v-if="secondCategoryHas(1)">
          <div class="left">彩页</div>
          <div class="right">
            <template v-for="(l,i) in list">
              <div class="goods-line" @click="setReason()"  v-bind:key="i" v-if="l.firstCategory==0&&l.secondCategory==1&&l.size">
                <Goods  @change="changeDemo()" :id="i" :data="l" v-model="list[i].size" ></Goods>
              </div>
            </template>
            <div class="info">
              <div >彩页订单总价：¥{{secondCategoryAllPrice(1)}}</div>
              <div>彩页剩余预算：¥{{getFloat2(user.user.budgetColorPage-user.user.budgetColorPageApply-secondCategoryAllPrice(1))}}</div>
            </div>
          </div>
        </div>
        <div class="buy-inline" v-if="secondCategoryHas(2)">
          <div class="left">样品</div>
          <div class="right">
            <template v-for="(l,i) in list">
              <div class="goods-line" @click="setReason()"  v-bind:key="i" v-if="l.firstCategory==0&&l.secondCategory==2&&l.size">
                <Goods  @change="changeDemo()" :id="i" :data="l" v-model="list[i].size" ></Goods>
              </div>
            </template>
            <div class="info">
              <div >样品订单总价：¥{{secondCategoryAllPrice(2)}}</div>
              <div>样品剩余预算：¥{{getFloat2(user.user.budgetShoppingBag-user.user.budgetShoppingBagApply-secondCategoryAllPrice(2))}}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- 其他 -->
      <div class="buy-box" v-if="isSelect!='daily'">

        <div class="buy-inline" v-if="firstCategoryHas()">
          <div class="left"></div>
          <div class="right">
            <template v-for="(l,i) in list">
              <div class="goods-line" @click="setReason()"  v-bind:key="i" v-if="l.firstCategory!=0&&l.size">
                <Goods  @change="changeDemo()" :id="i" :data="l" v-model="list[i].size" ></Goods>
              </div>
            </template>
            <div class="info">
              <div >{{isSelect=='material'?'动销物料':'样品相关'}}订单总价：¥{{firstCategoryAllPrice(selectMap[isSelect])}}</div>
              <div>
                {{isSelect=='material'?'动销物料':'样品相关'}}剩余预算
                {{
                  isSelect=='material'?
                  getFloat2(user.user.budgetMovingSales-user.user.budgetMovingSalesApply-firstCategoryAllPrice(selectMap[isSelect])):
                  getFloat2(user.user.budgetSample-user.user.budgetSampleApply-firstCategoryAllPrice(selectMap[isSelect]))
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="buy-line">
      <div @click="changeBuyCar()">已选</div>
      <div >订单总价：¥{{firstCategoryAllPrice(selectMap[isSelect])}}</div>
      <div @click="clearSize()">清空</div>
      <div @click="next()">下一步</div>
    </div>

    <div class="reason-up" v-if="reasonShow">
      <div class="reason-box">
        <div class="reason-in">

          <div class="reason-line">
            <div class="lable">申请原因</div>
            <div class="input-box select-box">
              <div class="select-icon">
                <img src="/img/shop/icon-3.png">
              </div>
              <div class="select-show">{{reason}}</div>
              <select v-model="reason" :disabled="reasonNoSelect">
                <option value="科室会" v-if="selectClose">科室会</option>
                <option value="区域会" v-if="selectClose">区域会</option>
                <option value="KOL拜访" v-if="selectClose">KOL拜访</option>
                <option value="产品动销" v-if="!selectClose" >产品动销</option>
                <option value="其他原因" v-if="selectClose">其他原因</option>
              </select>
            </div>
          </div>

          <!-- 循环输出所需输入、选择框 -->
          <div v-for="(r,i) in reasonData[reason]" v-bind:key="i">


            <!-- 经销商选择 -->
            <div class="reason-line" v-if="r.type=='dealer'">
              <div class="lable">{{r.name}}</div>
              <div class="input-box select-box">
                <div class="select-icon">
                  <img src="/img/shop/icon-3.png">
                </div>
                <div class="select-show">{{r.placeholder&&!reasonCache[r.data]?r.placeholder:reasonCache[r.data]? r.option[reasonCache[r.data]-1].name:reasonCache[r.data]=r.option[0]}}</div>
                <select v-model="reasonCache[r.data]" @change="dealerChange(r.option[reasonCache[r.data]-1].annualIndicators,r.option[reasonCache[r.data]-1].addrDetail)">
                  <option :value="a+1" v-for="(o,a) in r.option" v-bind:key="a">{{o.name}}</option>
                </select>
              </div>
            </div>

            <!-- select选择框 -->
            <div class="reason-line" v-if="r.type=='select'">
              <div class="lable">{{r.name}}</div>
              <div class="input-box select-box">
                <div class="select-icon">
                  <img src="/img/shop/icon-3.png">
                </div>
                <div class="select-show">{{r.placeholder&&!reasonCache[r.data]?r.placeholder:reasonCache[r.data]?reasonCache[r.data]:reasonCache[r.data]=r.option[0]}}</div>
                <select v-model="reasonCache[r.data]">
                  <option :value="o" v-for="(o,a) in r.option" v-bind:key="a">{{o}}</option>
                </select>
              </div>
            </div>

            <!-- 时间选择器 -->
            <div class="reason-line" v-if="r.type=='time'">
              <div class="lable">{{r.name}}</div>
              <div class="input-box select-box">
                <div class="select-icon">
                  <img src="/img/shop/icon-3.png">
                </div>
                <div class="select-show">{{reasonCache[r.data]}}</div>
                <input type="date" v-model="reasonCache[r.data]" class="date-select" >
              </div>
            </div>

            <!-- input输入框 -->
            <div class="reason-line" v-if="r.type=='input'">
              <div class="lable">{{r.name}}</div>
              <div class="input-box">
                <input type="text" class="input" v-model="reasonCache[r.data]" :placeholder="r.placeholder">
              </div>
            </div>

            <!-- 长文本输入框 -->
            <div class="reason-line textarea-box" v-if="r.type=='textarea'">
              <div class="lable">{{r.name}}</div>
              <div class="input-box">
                <textarea class="textarea" v-model="reasonCache[r.data]" :placeholder="r.placeholder"></textarea>
              </div>
            </div>


            <!-- 底部信息标注 -->
            <div class="reason-info" v-if="r.type=='info'">{{r.content}}</div>

            <!-- 区域选择 -->
            <div class="reason-line region-box" v-if="r.type=='region'">
              <div class="lable">{{r.name}}</div>
              <div class="input-box select-box select-box-2">
                <div class="select-icon">
                  <img src="/img/shop/icon-3.png">
                </div>
                <div class="select-show">{{reasonCache[r.data[0]]?region[reasonCache[r.data[0]]-1].provinceName:'请选择'}}</div>
                <select v-model="reasonCache[r.data[0]]" @change="reasonCache[r.data[1]]=0;reasonCache.dealer=''">
                  <option :value="(a+1)" v-for="(o,a) in region" v-bind:key="a">{{o.provinceName}}</option>
                </select>
              </div>
              <div class="input-box select-box select-box-2">
                <div class="select-icon">
                  <img src="/img/shop/icon-3.png">
                </div>
                <div class="select-show">{{reasonCache[r.data[1]]?region[reasonCache[r.data[0]]-1].cities[reasonCache[r.data[1]]-1].cityName:'请选择'}}</div>
                <select v-model="reasonCache[r.data[1]]" v-if="reasonCache[r.data[0]]" @change="getDealer()">
                  <option :value="(a+1)" v-for="(o,a) in region[reasonCache[r.data[0]]-1].cities" v-bind:key="a">{{o.cityName}}</option>
                </select>
              </div>
            </div>

          </div>
          <div class="reason-enter" @click="cacheReason()">确认</div>
          <div class="reason-enter back" @click="reasonShow=false">返回</div>
        </div>
      </div>
    </div>
    <Order :key="demoKey" :user="user" :reason="{cache:reasonCache,reason:reasonMap[reason],order:selectMap[isSelect],dealer:dealer[reasonCache.dealer-1]}"  @reset="selectReason" :isSelect="isSelect" v-if="orderShow" :list="list"></Order>


    <Upbox @close="upError=''"  v-if="upError" :type="'tips'" :data="{back:'返回',state:'error',title:'操作失败！',desc:upError}"></Upbox>
    <Upbox @close="tipsShow=false"  v-if="tipsShow" :type="'tips'" :data="{back:'返回',state:'error',title:'提交失败！',desc:getTipsDesc()}"></Upbox>
    <Upbox @close="typeError=false;reasonShow=true"  v-if="typeError" :type="'tips'" :data="{back:'我知道了',state:'error',title:'提交失败！',desc:'信息填写不完整，<br>请完善信息后再提交。'}"></Upbox>
    <Footer v-model="user" :id="2"></Footer>
  </div>
</template>
<script>
import Footer from '../components/Footer.vue'
import Goods from '../components/Goods.vue'
import Upbox from '../components/Upbox.vue'
import Order from '../components/Order.vue'
// import VueDatepickerLocal from 'vue-datepicker-local'
export default {
  name: 'Shop',
  components: {Footer,Goods,Upbox,Order},
  data: function () {
    return {
      isSearch:false,
      isSelect:'daily',
      tipsDesc:'',
      upError:'',
      user:{},
      selectMap:{
        daily:0,
        material:1,
        sample:2
      },
      reasonNoSelect:false,
      dailySelect:'0',
      buyCar:false,
      reason:'科室会',
      // 0-科室会，1-区域会，2-KOL拜访，3-产品动销，4-其他原因
      reasonMap:{
        '科室会':0,
        '区域会':1,
        'KOL拜访':2,
        '产品动销':3,
        '其他原因':4
      },
      time:'',
      region:{},
      regionSelect:0,
      dealer:[],
      typeError:false,
      selectClose:true,
      reasonCache:{
        time:'',
        hospital:'',
        department:'',
        explain:'',
        meetingTitle:"",
        meetingPlace:"",
        userName:"",
        activityCategory:"",
        province:"",
        city:"",
        dealer:"",
        target:"",
        address:""
      },
      reasonShow:false,
      reasonState:false,
      tipsShow:false,
      orderShow:false,
      list:[],
      search:'',
      demoKey:'demo-' + +new Date() + ((Math.random() * 1000).toFixed(0) + ''),
      reasonRestrict:{
        '科室会':500,
        '区域会':2000,
        'KOL拜访':600,
        '产品动销':0,
        '其他原因':3000
      },
      reasonData:{
        '科室会':[
          {
            type:'time',
            data:'time',
            name:'时间'
          },
          {
            type:'input',
            data:'hospital',
            name:'医院',
            placeholder:'请填写医院名称'
          },
          {
            type:'input',
            data:'department',
            name:'科室',
            placeholder:'请填写科室名称'
          },
          {
            type:'input',
            data:'explain',
            name:'申请说明',
            placeholder:'请填写申请说明'
          },
          {
            type:'info',
            content:'单次订购品牌提示物预算上限为500元'
          }
        ],
        '区域会':[
          {
            type:'time',
            data:'time',
            name:'时间'
          },
          {
            type:'input',
            data:'meetingTitle',
            name:'会议名称',
            placeholder:'请填写会议名称'
          },
          {
            type:'input',
            data:'meetingPlace',
            name:'会议地点',
            placeholder:'请填写会议地点'
          },
          {
            type:'input',
            data:'explain',
            name:'申请说明',
            placeholder:'请填写申请说明'
          },
          {
            type:'info',
            content:'单次订购品牌提示物预算上限为2000元'
          }
        ],
        'KOL拜访':[
          {
            type:'time',
            data:'time',
            name:'时间'
          },
          {
            type:'input',
            data:'hospital',
            name:'医院',
            placeholder:'请填写医院名称'
          },
          {
            type:'input',
            data:'department',
            name:'科室',
            placeholder:'请填写科室名称'
          },
          {
            type:'input',
            data:'userName',
            name:'姓名',
            placeholder:'请填写KOL姓名'
          },
          {
            type:'input',
            data:'explain',
            name:'申请说明',
            placeholder:'请填写申请说明'
          },
          {
            type:'info',
            content:'单次订购品牌提示物预算上限为600元'
          }
        ],
        '产品动销':[
          {
            type:'select',
            name:'活动类别',
            data:'activityCategory',
            option:[
              '动销项目'
            ],
            init:0
          },
          {
            type:'region',
            data:['province','city'],
            name:'地区',
            provinceData:'',
            province:[
              '省份1',
              '省份2',
              '省份3',
              '省份4'
            ],
            areaData:'',
            area:[
              '地区1',
              '地区2',
              '地区3',
              '地区4'
            ]
          },
          {
            type:'dealer',
            data:'dealer',
            name:'经销商',
            placeholder:'请选择经销商',
            option:[],
            init:0
          },
          {
            type:'input',
            data:'target',
            name:'年度指标',
            placeholder:'年度指标'
          },
          {
            type:'input',
            data:'address',
            name:'地址',
            placeholder:'经销商地址'
          }
        ],
        '其他原因':[
          {
            type:'textarea',
            data:'explain',
            name:'原因',
            placeholder:'请填写具体原因'
          },
          {
            type:'info',
            content:'单次订购品牌提示物预算上限为3000元'
          }
        ]
      }
    }
  },
  methods:  {
    getTipsDesc(){
      let c = ''
      this.reasonData[this.reason].map(r=>{
        if(r.type=='info'){
          c=r.content
        }
      })
      return c
    },
    dealerChange(data,addr){
      console.log(addr)
      this.reasonCache.target=data
      this.reasonCache.address=addr
    },

    getDealer(){
      let vm = this
      vm.reasonCache.dealer=''
      this.$axios.get('/business/distributor/list?cityId='+this.region[this.reasonCache.province-1].cities[this.reasonCache.city-1].cityId, {}).then(function (res) {
        vm.dealer=res.rows
        vm.reasonData['产品动销'].map((r,i)=>{
          if(r.data=='dealer'){
            vm.reasonData['产品动销'][i].option=[]
            vm.dealer.map(d=>{
              vm.reasonData['产品动销'][i].option.push({name:d.distributorName,id:d.distributorId,annualIndicators:d.annualIndicators,addrDetail:d.addrDetail})
            })
          }
        })
      })
    },
    setReason(){
      if(!this.reasonState){
        this.reasonShow=true
      }
    },
    next(){
      let cacheSize=0
      this.list.map(l=>{
        if(l.size){
          cacheSize+=l.size
        }
      })
      if(cacheSize==0){
        this.upError='请选择您要下单的产品'
        return
      }

      if(this.reasonCache.dealer){
        this.reasonCache.distributorId=this.dealer[this.reasonCache.dealer-1].distributorId
      }
      if(this.reasonState){
        if(this.reasonRestrict[this.reason]==0){
          this.orderShow=true
        }else{
          if(this.secondCategoryAllPrice(0)>this.reasonRestrict[this.reason]){
            this.tipsShow=true
          }else{
            this.orderShow=true
          }
        }
      }else{
        this.reasonShow=true
      }
    },
    cacheReason(){
      // console.log(this.reasonCache)
      if(this.reason=='请选择'){
          this.reasonShow=false
          this.typeError=true
          return;
      }
      if(this.reason=='科室会'){
        if(this.reasonCache.time==''||this.reasonCache.hospital==''||this.reasonCache.department==''||this.reasonCache.explain==''){
          this.reasonShow=false
          this.typeError=true
          return;
        }
      }
      if(this.reason=='区域会'){
        if(this.reasonCache.time==''||this.reasonCache.meetingTitle==''||this.reasonCache.meetingPlace==''||this.reasonCache.explain==''){
          this.reasonShow=false
          this.typeError=true
          return;
        }
      }
      if(this.reason=='KOL拜访'){
        if(this.reasonCache.time==''||this.reasonCache.hospital==''||this.reasonCache.department==''||this.reasonCache.userName==''||this.reasonCache.explain==''){
          this.reasonShow=false
          this.typeError=true
          return;
        }
      }
      if(this.reason=='产品动销'){
        if(this.reasonCache.activityCategory==''||this.reasonCache.province==''||this.reasonCache.city==''||this.reasonCache.dealer==''||this.reasonCache.target==''||this.reasonCache.address==''){
          this.reasonShow=false
          this.typeError=true
          return;
        }
      }
      if(this.reason=='其他原因'){
        if(this.reasonCache.explain==''){
          this.reasonShow=false
          this.typeError=true
          return;
        }
      }
      this.reasonNoSelect=false
      this.reasonState=true
      this.reasonShow=false
      this.changeDemo()
    },
    clearSize(){
      let newList=[]
      this.list.map(l=>{
        l.size=0
        newList.push(l)
      })
      this.list=newList
      this.changeDemo()
    },
    getFloat2: function(x){
      if (x != '.'){
        var f = Math.round(x * 100) / 100;
        var s = f.toString();
        var rs = s.indexOf('.');
        if (rs <= 0) {
          rs = s.length;
          s += '.';
        }
        while (s.length <= rs + 2) {
          s += '0';
        }
        return s;
      }else{
        return '0.00';
      }
    },
    secondCategoryAllPrice(n){
      let price=0
      this.list.map(l=>{
        if(l.firstCategory==0&&l.secondCategory==n&&l.size){
          price+=l.productPrice*l.size
        }
      })
      return this.getFloat2(price)
    },
    secondCategoryHas(n){
      let has=false
      this.list.map(l=>{
        if(l.firstCategory==0&&l.secondCategory==n&&l.size){
          has=true
        }
      })
      return has
    },
    firstCategoryAllPrice(n){
        let price=0
        // console.log(this.list)
        this.list.map(l=>{
          if(l.firstCategory==n&&l.size){
            price+=l.productPrice*l.size
          }
        })

        return this.getFloat2(price)
    },
    firstCategoryHas(){
      let has=false
      this.list.map(l=>{
        if(l.firstCategory!=0&&l.size){
          has=true
        }
      })
      return has
    },
    changeBuyCar(){
      this.buyCar=! this.buyCar;
      this.changeDemo()
    },
    changeDemo(){
      this.demoKey='demo-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
    },
    selectReason(){
      this.reason='产品动销'
      this.reasonShow=true
      this.reasonNoSelect=true
    }
  },
  mounted(){
    var vm = this
    this.$axios.get('business/product/list', {}).then(function (res) {
      vm.list=res.rows
      // console.log(res.rows)
    })
    this.$axios.get('/business/region', {}).then(function (res) {
      // console.log(res.data)
      vm.region=res.data
    })

    // this.$axios.get('/getInfo', {}).then(function (res) {
    //   console.log(res.user)
    //   vm.user=res.user
    // })
    // user
  }
}
</script>
