<template>
  <div class="record">
    <a target="_blank" href="https://beian.miit.gov.cn">京ICP备12039434号-6</a>
  </div>
</template>
<script>
export default {
  name: 'Footer',
  data: function () {
    return {
      user:{}
    }
  },
  props: {

  },
  methods:{
    
  },
  mounted(){

  }
}
</script>
