<template>
  <div class="address-page">
    <div class="back-btn" @click="back()">返回</div>
    <!-- 收货地址列表 -->
    <div class="address-center" v-if="!isEdit">
      <div class="address-in" v-for="(l,i) in list" v-bind:key="i" @click="upAddress(i)">
        <div class="dots"><span v-if="i==myAddressSelect"></span></div>
        <div class="address-info">
          <div class="name">{{l.consignee}}</div>
          <div class="phone">{{l.consigneeTelephone}}</div>
          <div class="address">{{l.addrDetail}}</div>
        </div>
        <div class="btns" >
          <span v-on:click.stop="setEditData(i)">编辑</span>
          <span v-on:click.stop="deleteID=l.addrId;info=true">删除</span>
        </div>
      </div>
      <div class="address-in" v-if="list.length==0">
        <div class="address-info">
          <div class="name">暂无收货地址</div>
          <div class="phone">请点击下方按钮，新增收货地址</div>
        </div>
      </div>
    </div>
    <div class="add-address" v-if="!isEdit" @click="isEdit=true">新增收货地址</div>
    <!-- 收货地址编辑 -->
    <div class="address-center" v-if="isEdit">
      <div class="address-input-center">
        <div class="address-input-in">
          <span>联系人</span>
          <div class="input-box">
            <input type="text" v-model="consignee" placeholder="请填写联系人姓名">
          </div>
        </div>
        <div class="address-input-in">
          <span>手机号码</span>
          <div class="input-box">
            <input type="text" v-model="consigneeTelephone" placeholder="请填写联系人手机号码">
          </div>
        </div>
        <div class="address-input-in">
          <span>所在地区</span>
          <div class="input-box is-select">
              <div class="select-box">
                <div class="fonts">{{reasonCache['province']?region[reasonCache['province']-1].provinceName:'请选择'}}</div>
                <div class="icons">
                  <img src="/img/shop/icon-3.png">
                </div>
                <select v-model="reasonCache['province']" @change="reasonCache['city']=0;reasonCache.dealer=''">
                  <option :value="(a+1)" v-for="(o,a) in region" v-bind:key="a">{{o.provinceName}}</option>
                </select>
              </div>
              <div class="select-box">
                <div class="fonts">{{reasonCache['city']?region[reasonCache['province']-1].cities[reasonCache['city']-1].cityName:'请选择'}}</div>
                <div class="icons">
                  <img src="/img/shop/icon-3.png">
                </div>
                <select v-model="reasonCache['city']" v-if="reasonCache['province']" >
                  <option :value="(a+1)" v-for="(o,a) in region[reasonCache['province']-1].cities" v-bind:key="a">{{o.cityName}}</option>
                </select>
                
              </div>
          </div>
        </div>

        <div class="address-input-in">
          <span>详细地址</span>
          <div class="input-box">
            <input type="text" v-model="addrDetail" placeholder="请填写详细地址">
          </div>
        </div>
      </div>
    </div>
    <div class="add-address" v-if="isEdit" @click="addAddress()">保存并使用</div>

    <Upbox @close="error=false;"  v-if="error" :type="'tips'" :data="{back:'我知道了',state:'error',title:'保存失败！',desc:'请完善内容后进行保存。'}"></Upbox>

    <Upbox @close="info=false;" @ok="deleteAddress(deleteID);info=false;"  v-if="info" :type="'tips'" :data="{back:'取消',ok:'继续',title:'删除操作',desc:'该操作不可恢复，是否继续?'}"></Upbox>
  </div>
</template>

<script>
import Upbox from '../components/Upbox.vue'
export default {
  name: 'Address',
  components: {Upbox},
  data: function () {
    return {
      info:false,
      error:false,
      isEdit:false,
      myAddressSelect:0,
      deleteID:"",
      editId:"",
      list:[],
      consignee:'',
      consigneeTelephone:'',
      provinceId:'',
      cityId:'',
      addrDetail:'',
      region:[],
      reasonCache:{
        province:'',
        city:''
      }
    }
  },
  props: {
    type: String,
    data:Object,
    addressSelect:Number
  },
  methods:{
    close(){
        this.$emit('close');
    },
    ok(){
      this.$emit('ok');
    },
    back(){
      this.editId=''
      if(this.isEdit){
        this.isEdit=false
      }else{
        this.$emit('close');
      }
    },
    upAddress(i){
      this.myAddressSelect=i
      this.$emit('input', this.myAddressSelect)
      this.$emit('close');
      
      
    },
    setEditData(i){
      // 重组数据
      this.isEdit=true;
      this.editId=this.list[i].addrId;
      this.consignee=this.list[i].consignee
      this.consigneeTelephone=this.list[i].consigneeTelephone
      this.region.map((r,b)=>{
        if(r.provinceId==this.list[i].provinceId){
          this.reasonCache.province=b+1
          r.cities.map((c,a)=>{
            if(c.cityId==this.list[i].cityId){
              this.reasonCache.city=a+1
            }
          })
        }
      })
      this.addrDetail=this.list[i].addrDetail
    },
    deleteAddress(id){
      var vm=this
      vm.$axios.delete('/business/address/'+id).then(   function (res) {
        vm.getAddressList(true)
      }).catch(function (error) {
        alert(error);
        return
      });
    },
    setAddressSelect(i){
      this.myAddressSelect=i
    },
    addAddress(){
      var vm=this
      if(!vm.reasonCache.province||!vm.reasonCache.city){
        vm.error=true
        return;
      }
      vm.provinceId=vm.region[vm.reasonCache.province-1].provinceId
      vm.cityId=vm.region[vm.reasonCache.province-1].cities[vm.reasonCache.city-1].cityId
      if(!this.editId){
        vm.$axios.post('/business/address', {
          consignee:vm.consignee,
          consigneeTelephone:vm.consigneeTelephone,
          provinceId:vm.provinceId,
          cityId:vm.cityId,
          addrDetail:vm.addrDetail
        }).then(function (res) {
          console.log(res);
          if(res.code==200){
            vm.isEdit=false
            vm.getAddressList()
          }else{
            
            vm.error=true
          }
        }).catch(function (error) {
          console.log(error);
          return
        });
      }else{
        vm.$axios.put('/business/address', {
          addrId:vm.editId,
          consignee:vm.consignee,
          consigneeTelephone:vm.consigneeTelephone,
          provinceId:vm.provinceId,
          cityId:vm.cityId,
          addrDetail:vm.addrDetail
        }).then(function (res) {
          if(res.code==200){
            vm.isEdit=false
            vm.getAddressList()
            vm.editId=''
          }else{
            vm.error=true
          }
        }).catch(function (error) {
          console.log(error);
          return
        });
      }
      
    },
    getAddressList(select){
      var vm = this
      this.$axios.get('/business/address/list', {}).then( async function (res) {
        vm.list=res.rows
        if(select){
          await vm.setAddressSelect(vm.list.length-1)
        }
        // console.log(res.rows)
      }) 
    }
  },
  mounted(){
    var vm = this
    this.$axios.get('/business/region', {}).then(function (res) {
      // console.log(res.data)
      vm.region=res.data
    })
    this.getAddressList()
    console.log(this.addressSelect)
    this.myAddressSelect=this.addressSelect
  }
}
</script>
