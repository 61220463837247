<template>


<!--周报详情-->
      <div class="weekly-box" v-if="myWeekById">
        <div class="info-top">
          <div>
            <img src="/img/weekly/icon-1.png">
            <div>
              <span>{{ myWeekById.year }}年第{{ myWeekById.week}}周 周报</span>
              <span>{{ myWeekById.weekBeginTime && myWeekById.weekBeginTime.split('T')[0] }} - {{ myWeekById.weekEndTime && myWeekById.weekEndTime.split('T')[0] }}</span>
            </div>
          </div>
          <div>
            <img src="/img/weekly/icon-7.png">
            <span>{{ myWeekById.createTime }}</span>
          </div>
        </div>
        <template v-if="myWeekById.workPlan.length>0&&myWeekById.workPlan[0].content">
          <div class="title">工作和计划</div>
          <div class="sub-title">本周工作 TOP3 事项</div>
          <div class="input-box max">
            <div class="max-in" v-if="myWeekById.workPlan.length>=1">
              <span>TOP1</span>
              <div class="info">{{ check(myWeekById.workPlan[0].content) }}</div>
            </div>
            <div class="max-in" v-if="myWeekById.workPlan.length>=2">
              <span>TOP2</span>
              <div class="info">{{ check(myWeekById.workPlan[1].content) }}</div>
            </div>
            <div class="max-in" v-if="myWeekById.workPlan.length>=3">
              <span>TOP3</span>
              <div class="info">{{ check(myWeekById.workPlan[2].content) }}</div>
            </div>
          </div>
        </template>

        <template v-if="myWeekById.workSummary.length>0&&myWeekById.workSummary[0].content">
          <div class="sub-title">下周工作 TOP3 事项</div>
          <div class="input-box max">
            <div class="max-in" v-if="myWeekById.workSummary.length>=1">
              <span>TOP1</span>
              <div class="info">{{ check(myWeekById.workSummary[0].content) }}</div>
            </div>
            <div class="max-in" v-if="myWeekById.workSummary.length>=2">
              <span>TOP2</span>
              <div class="info">{{ check(myWeekById.workSummary[1].content) }}</div>
            </div>

            <div class="max-in" v-if="myWeekById.workSummary.length>=3">
              <span>TOP3</span>
              <div class="info">{{ check(myWeekById.workSummary[2].content) }}</div>
            </div>
          </div>
        </template>

        <template v-if="myWeekById.completedAmount || myWeekById.completedRatio || myWeekById.riskCrisisFaced ||myWeekById.termsOfSettlement">
          <div class="title">订单进展</div>
          <div class="input-box max">
            <div class="max-in">
              <span>季度完成金额</span>
              <div class="info">{{check(myWeekById.completedAmount)}}</div>
            </div>
            <div class="max-in">
              <span>季度完成比例</span>
              <div class="info">{{check(myWeekById.completedRatio)}}%</div>
            </div>
            <div class="max-in">
              <span>面临风险危机</span>
              <div class="info">{{check(myWeekById.riskCrisisFaced)}}</div>
            </div>
            <div class="max-in">
              <span>解决办法</span>
              <div class="info">{{check(myWeekById.termsOfSettlement)}}</div>
            </div>
          </div>
        </template>


        <div class="title cut-line" v-if="myWeekById.agentVisits.length>0||myWeekById.expertVisits.length>0">客户拜访</div>
        <template v-if="myWeekById.agentVisits.length>0">
          <div class="sub-title">代理商</div>
          <template v-for="(m,i) in myWeekById.agentVisits">
            <div class="input-info-box max"  v-bind:key="'agentVisits_'+i" >
              <div class="in-line-box">
                <div class="in-line">
                  <span>姓名</span>
                  <abbr>{{ check(m.name) }}</abbr>
                </div>
                <div class="in-line">
                  <span>单位</span>
                  <abbr>{{ check(m.company) }}</abbr>
                </div>
                <div class="in-line">
                  <span>拜访主题</span>
                  <abbr>{{ check(m.theme) }}</abbr>
                </div>
                <div class="in-line">
                  <span>客户反馈</span>
                  <abbr>{{ check(m.feedback) }}</abbr>
                </div>
              </div>
            </div>
          </template>
        </template>


        <template v-if="myWeekById.expertVisits.length>0">
          <div class="sub-title">专家</div>
          <template v-for="(m,i) in myWeekById.expertVisits">
            <div class="input-info-box max" v-bind:key="'expertVisits_'+i" >
              <div class="in-line-box ">
                <div class="in-line">
                  <span>姓名</span>
                  <abbr>{{ check(m.name) }}</abbr>
                </div>
                <div class="in-line">
                  <span>单位</span>
                  <abbr>{{ check(m.company) }}</abbr>
                </div>
                <div class="in-line">
                  <span>拜访主题</span>
                  <abbr>{{ check(m.theme) }}</abbr>
                </div>
                <div class="in-line">
                  <span>客户反馈</span>
                  <abbr>{{ check(m.feedback) }}</abbr>
                </div>
              </div>
            </div>
          </template>
        </template>

        <template v-if="myWeekById.dealerDevProgresses.length>0">
          <div class="title cut-line">新经销商开发进展</div>
          <template v-for="(m,i) in myWeekById.dealerDevProgresses">
            <div class="input-info-box max" v-bind:key="'dealerDevProgresses_'+i" >
              <div class="in-line-box">
                <div class="in-line">
                  <span>公司名称</span>
                  <abbr>{{ check(m.company) }}</abbr>
                </div>
                <div class="in-line">
                  <span>经销商类型</span>
                  <abbr>{{ check(DictionariesDealerDevProgressesGm[m.distributorType])}}</abbr>
                </div>
                <div class="in-line">
                  <span>进展</span>
                  <abbr>{{check(DictionariesDealerDevProgresses[m.progress])}}</abbr>
                </div>
                <div class="in-line">
                  <span>背景及现状</span>
                  <abbr>{{ check(m.situation) }}</abbr>
                </div>
              </div>
            </div>
          </template>
        </template>

        <template v-if="myWeekById.chainDevProgresses.length>0">
          <div class="title cut-line">新连锁开发进展</div>
          <template v-for="(m,i) in myWeekById.chainDevProgresses">
            <div class="input-info-box max" v-bind:key="'chainDevProgresses_'+i" >
              <div class="in-line-box">
                <div class="in-line">
                  <span>公司名称</span>
                  <abbr>{{ check(m.company) }}</abbr>
                </div>
                <div class="in-line">
                  <span>进展</span>
                  <abbr>{{check(DictionariesDealerDevProgresses[m.progress])}}</abbr>
                </div>
                <div class="in-line">
                  <span>背景及现状</span>
                  <abbr>{{ check(m.situation) }}</abbr>
                </div>
              </div>
            </div>
          </template>
        </template>


        <div class="title" v-if="(myWeekById.type==1 || myWeekById.type==2) && !weeklyInfoCache && myWeekById.status!='0'">STC项目进展</div>
        <div class="title" v-if="myWeekById.type==3&&myWeekById.stcList.length>0">终端客户拜访</div>
        <div class="title" v-if="myWeekById.type==4 && myWeekById.b4bList.length>0">B4B项目进展</div>

        <template v-if="(myWeekById.type==1||myWeekById.type==2) && !weeklyInfoCache&& myWeekById.status!='0'">
          <div class="item-checkbox">
            <div class="item-checkbox__title">是否涉及STC项目进展：</div>
            <div class="item-checkbox__value">
              <label v-if="myWeekById.hasStc==1">是</label>
              <label v-if="myWeekById.hasStc==0">否</label>
            </div>
          </div>
        </template>

          <template v-if="(myWeekById.hasStc==1 && myWeekById.stcList.length>0)||(myWeekById.type==3 && myWeekById.stcList.length>0)||myWeekById.b4bList.length>0">
<!--          <div class="sub-title" v-if="myWeekById.stcList.length>0 && myWeekById.type==1">STC</div>-->
            <template v-for="(m,i) in myWeekById.stcList">
              <div class="input-info-box max"  v-bind:key="'stcList_'+i" >
                <div class="in-line-box" v-if="myWeekById.type==1">
                  <div class="in-line">
                    <span>建码进展</span>
                    <abbr>{{ check(m.codeBuildingProgress) }}</abbr>
                  </div>
                  <div class="in-line">
                    <span>贴柜培训</span>
                    <abbr>{{ check(m.storeTraining) }}</abbr>
                  </div>
                  <div class="in-line">
                    <span>陈列维护</span>
                    <abbr>{{ check(m.displayItems) }}</abbr>
                  </div>
                  <div class="in-line">
                    <span>销售管理</span>
                    <abbr>{{ check(m.salesManagement) }}</abbr>
                  </div>
                  <div class="in-line">
                    <span>用户活动</span>
                    <abbr>{{ check(m.userActivity) }}</abbr>
                  </div>
                </div>
                <div class="in-line-box" v-if="myWeekById.type==2">
                  <div class="in-line">
                    <span>药房经销商开发维护</span>
                    <abbr>{{ check(m.pharmacyDealerDev) }}</abbr>
                  </div>
                  <div class="in-line">
                    <span>重点连锁维护开发维护</span>
                    <abbr>{{ check(m.keyChainMaintenDev) }}</abbr>
                  </div>
                </div>
                <div class="in-line-box" v-if="myWeekById.type==3">
                  <div class="in-line">
                    <span>药店名称</span>
                    <abbr>{{ check(m.pharmacyName) }}</abbr>
                  </div>
                  <div class="in-line">
                    <span>门店名称</span>
                    <abbr>{{ check(m.storeName) }}</abbr>
                  </div>
                  <div class="in-line">
                    <span>陈列项目</span>
                    <abbr>{{ check(m.displayItems) }}</abbr>
                  </div>
                  <div class="in-line">
                    <span>贴店培训</span>
                    <abbr>{{ check(m.storeTraining) }}</abbr>
                  </div>
                  <div class="in-line">
                    <span>售后处理</span>
                    <abbr>{{ check(m.afterSales) }}</abbr>
                  </div>
                  <div class="in-line">
                    <span>用户活动</span>
                    <abbr>{{ check(m.userActivity) }}</abbr>
                  </div>
                  <div class="in-line">
                    <span>情报收集</span>
                    <abbr>{{ check(m.intelligenceCollection) }}</abbr>
                  </div>
                </div>
              </div>
            </template>
        </template>

        <template v-if="myWeekById.b4bList.length>0">
<!--          <div class="title" v-if="myWeekById.b4bList.length>0">项目进展</div>-->
          <template v-for="(m,i) in myWeekById.b4bList">
            <div class="input-info-box max"  v-bind:key="'b4bList_'+i" >
              <div class="in-line-box">
                <div class="in-line">
                  <span>项目名称</span>
                  <abbr>{{ check(m.projectName) }}</abbr>
                </div>
                <div class="in-line">
                  <span>项目进展</span>
                  <abbr>{{ check(m.progress) }}</abbr>
                </div>
              </div>
            </div>
          </template>
        </template>
        <template v-if="myWeekById.doorHead || myWeekById.experienceCenter">
          <div class="title">店面陈列</div>
          <div class="input-box max">
            <div class="max-in">
              <span>门头</span>
              <div class="info">{{ check(myWeekById.doorHead) }}</div>
            </div>
            <div class="max-in">
              <span>体验中心</span>
              <div class="info">{{ check(myWeekById.experienceCenter) }}</div>
            </div>
          </div>
        </template>
        <template v-if="myWeekById.activities.length>0">
          <div class="title cut-line">市场活动</div>
          <template v-for="(m,i) in myWeekById.activities">
            <div class="input-info-box max" v-bind:key="'activities_'+i" >
              <div class="in-line-box">
                <div class="in-line">
                  <span>活动名称</span>
                  <abbr>{{ check(m.activityName) }}</abbr>
                </div>
                <div class="in-line">
                  <span>活动日期</span>
                  <abbr>{{check(m.activityDate)}}</abbr>
                </div>
                <div class="in-line">
                  <span>目标及反馈</span>
                  <abbr>{{ check(m.feedback) }}</abbr>
                </div>
              </div>
            </div>
          </template>
        </template>

        <template v-if="myWeekById.trainings.length>0">
          <div class="title cut-line">培训</div>
          <template v-for="(m,i) in myWeekById.trainings">
            <div class="input-info-box max" v-bind:key="'trainings_'+i" >
              <div class="in-line-box">
                <div class="in-line">
                  <span>公司名称</span>
                  <abbr>{{ check(m.company) }}</abbr>
                </div>
                <div class="in-line">
                  <span>培训日期</span>
                  <abbr>{{check(m.trainingDate)}}</abbr>
                </div>
                <div class="in-line">
                  <span>主题</span>
                  <abbr>{{ check(m.theme) }}</abbr>
                </div>
              </div>
            </div>
          </template>
        </template>




        <template v-if="myWeekById.newAgent">
          <div class="title">潜力代理商</div>
          <div class="input-box max">
            <div class="max-in">
              <div class="info only">{{ check(myWeekById.newAgent) }}</div>
            </div>
          </div>
        </template>

        <template v-if="myWeekById.keyAgent">
          <div class="title">重点代理商</div>
          <div class="input-box max">
            <div class="max-in">
              <div class="info only">{{ check(myWeekById.keyAgent) }}</div>
            </div>
          </div>
        </template>

        <template v-if="myWeekById.memberStatus ||myWeekById.progressPlan">
          <div class="title">团队人员情况</div>
          <div class="input-box max">
            <div class="max-in">
              <span>成员现状</span>
              <div class="info ">{{ check(myWeekById.memberStatus) }}</div>
            </div>
            <div class="max-in">
              <span>进步计划</span>
              <div class="info ">{{ check(myWeekById.progressPlan) }}</div>
            </div>
          </div>
        </template>





        <div class="title cut-line" v-if="myWeekById.marketAndRival">市场及竞品情况</div>
        <div class="input-box max" v-if="myWeekById.marketAndRival">
          <div class="max-in">
            <div class="info only">{{check(myWeekById.marketAndRival)}}</div>
          </div>
        </div>
        <div class="add-more" v-if="weeklyInfoCache" @click=" addWeekInfo() ">填写周报</div>
        <div class="add-comment">
          <div class="add-more" @click=" back() ">返回</div>
          <div class="add-more" @click="setUpboxShow=true">留言</div>
        </div>

        <div class="show-my-comment">
          <div class="line"   v-for="(r,i) in myWeekComment" v-bind:key="i">
            <span class="line-title">{{r.regionName}} <span>{{r.nickName}}</span> 的留言：</span>
            <span class="line-info">{{r.comment}}</span>
          </div>

        </div>


        <Upbox @close="setUpboxShow=false" v-if="setUpboxShow" :type="'text'" @ok="(e)=>{setComment(e)}" :data="{title:'请填写留言',ok:'提交',back:'返回'}"></Upbox>
        <Upbox @close="sendError=''"  v-if="sendError" :type="'tips'" :data="{back:'确认',title:sendError}"></Upbox>
      </div>


</template>

<script>

import Upbox from '../../components/Upbox.vue'
export default {
  name: 'Files',
  props:{
    weeklyId:Number,
    weeklyInfoCache:String
  },
  watch:{
  },
  data: function () {
    return {
      sendError:'',
      setUpboxShow:false,
      myWeekComment:null,
      myWeekById:"",
      DictionariesDealerDevProgresses:[
          '初步沟通','确认意向','洽谈指标','签约','进货'
      ],
      DictionariesDealerDevProgressesGm:[
          '院线','流通'
      ],

    }
  },
  components: { Upbox},
  methods:{
    check(t){
      return t?t:'-'
    },
    back(){
      this.$emit('back')
    },
    addWeekInfo(){
      this.$emit('add')
    },
    setComment(e){
      let vm =this
      vm.$axios.post('/business/weekly/comment', {
        weeklyId:vm.weeklyId,
        comment:e
      },).then(function (res) {
        if(res.code!=200){
          vm.sendError=res.msg
        }else{
          vm.sendError="提交成功"
          vm.setUpboxShow=false
          vm.getComment()
        }
      })
    },
    getComment(){
      let vm =this
      vm.$axios.get('/business/weekly/comment/list/'+vm.weeklyId, null,).then(function (res) {
        console.log(res)
        vm.myWeekComment =  res.data
      })
      //
      //
    },
    nullToStr(data) {
      for (var x in data) {
        if (data[x] === null) { // 如果是null 把直接内容转为 ''
          data[x] = '';
        } else {
          if (Array.isArray(data[x])) { // 是数组遍历数组 递归继续处理
            data[x] = data[x].map(z => {
              return this.nullToStr(z);
            });
          }
          if(typeof(data[x]) === 'object'){ // 是json 递归继续处理
            data[x] = this.nullToStr(data[x])
          }
        }
      }
      return data;
    },
    getMyWeekById(id){
      let vm =this
      vm.$axios.get('/business/weekly/'+id, null,{
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(function (res) {
        console.log(res)
        vm.myWeekById = res.data
        vm.info = true
      })
    },
    close(){
      this.$emit('close')
    }
  },
  mounted(){
    this.getMyWeekById(this.weeklyId)
    this.getComment()
  }



}
</script>
