var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footer"},[(_vm.user.roles)?_c('div',{staticClass:"icon-box"},[_c('div',{staticClass:"in-box",on:{"click":function($event){return _vm.linkHome()}}},[_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":_vm.id==1?'/img/footer/icon-1-1.png':'/img/footer/icon-1-0.png'}})]),_c('div',{class:_vm.id==1?'fonts-box on':'fonts-box '},[_vm._v("首页")])]),(
    _vm.user.roles[0]!='distributor' && _vm.user.roles&&
    (
      _vm.user.user.dept.channel=='常规渠道'||
      _vm.user.user.dept.channel=='广阔渠道'||
      _vm.user.user.dept.channel=='医疗创新'||
      _vm.user.user.dept.channel=='常规CSM'||
      _vm.user.user.dept.channel=='广阔CSM'||
      _vm.user.user.dept.channel=='管理员'||
      _vm.user.user.dept.channel=='总经理室'||
      _vm.user.user.dept.channel=='港台销售部门'||
      _vm.user.user.dept.channel=='大中华区'||
      _vm.user.user.dept.channel=='测试渠道'
    )
    )?_c('div',{staticClass:"in-box",on:{"click":function($event){return _vm.linkShop()}}},[_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":_vm.id==2?'/img/footer/icon-2-1.png':'/img/footer/icon-2-0.png'}})]),_c('div',{class:_vm.id==2?'fonts-box on':'fonts-box '},[_vm._v("订购系统")])]):_vm._e(),(_vm.user.roles[0]!='distributor')?_c('div',{staticClass:"in-box",on:{"click":function($event){return _vm.linkActivity()}}},[_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":_vm.id==5?'/img/footer/icon-5-1.png':'/img/footer/icon-5-0.png'}})]),_c('div',{class:_vm.id==5?'fonts-box on':'fonts-box '},[_vm._v("区域费用申请")])]):_vm._e(),(_vm.user.roles[0]=='distributor')?_c('div',{staticClass:"in-box",on:{"click":function($event){return _vm.linkFiles()}}},[_c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":_vm.id==3?'/img/footer/icon-3-1.png':'/img/footer/icon-3-0.png'}})]),_c('div',{class:_vm.id==3?'fonts-box on':'fonts-box '},[_vm._v("下载系统")])]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }