<template>
    <div class=" up-examine weekly">
      <div class="weekly-box region" v-if="!regionIn&&!commissionerShow">
<!--        @click="regionIn=true"-->
        <div class="top-back-btn" @click="close()" >返回</div>
          <div :class="'weekly-box-line state-'+(w.status==1?2:w.status==2?3:1)" v-for="(w,i) in weeksGroupData" v-bind:key="'weeksGroup_'+i" @click="
                    w.allNum==0?upError='暂无周报，无法查看':getCommissionerData(w.year,w.week)
                "
          >

          <div class="weekly-box-line-left">
            <span></span>
            <abbr v-if="w.status==0">进行中</abbr>
            <abbr v-if="w.status==1">全部完成</abbr>
            <abbr v-if="w.status==2">部分完成</abbr>
            <abbr v-if="w.status==3">未完成</abbr>


          </div>
          <div class="weekly-box-line-center">
            <span>{{w.year}}年第{{ w.week }}周</span>
            <abbr>{{ w.beginTime.split('T')[0] }} - {{ w.endTime.split('T')[0] }}</abbr>
            <div class="progress">
              <div class="progress-box">
                <div class="progress" :style="'width:'+(w.submitNum/w.allNum*100)+'%'"></div>
              </div>
              <abbr>{{w.submitNum }}/{{ w.allNum }}</abbr>
            </div>
          </div>
          <img src="/img/weekly/icon-5.png" class="weekly-box-line-right">
        </div>
        <div class="add-more n-bottom" @click="getWeeksGroup()" v-if="weeksGroupData.length!=0 && weeksGroup.list.total>weeksGroupData.length">加载更多</div>
<!--        <div class="add-more back" @click="close()">返回</div>-->
      </div>
<!--      总监列表-->
      <div class="weekly-box region" v-if="commissionerShow&weeklyTypes!='4'">

        <div class="info-top">
          <div>
            <img src="/img/weekly/icon-8.png">
            <div>
              <span>{{ commissionerData.weekInf.year }}年第{{ commissionerData.weekInf.week }}周 周报</span>
              <span>{{ commissionerData.weekInf.beginTime.split('T')[0] }} - {{ commissionerData.weekInf.endTime.split('T')[0] }}</span>
            </div>
          </div>
          <div>
            <img src="/img/weekly/icon-9.png">
            <span>状态</span>
            <abbr v-if="commissionerData.weekInf.status==0">进行中</abbr>
            <abbr v-if="commissionerData.weekInf.status==1">全部完成</abbr>
            <abbr v-if="commissionerData.weekInf.status==2">部分完成</abbr>
            <abbr v-if="commissionerData.weekInf.status==3">未完成</abbr>
          </div>
          <div>
            <img src="/img/weekly/icon-10.png">
            <span>进度</span>
            <div class="progress">
              <div class="progress-box">
                <div class="progress-box-in" :style="'width:'+(commissionerData.weekInf.submitNum/commissionerData.weekInf.allNum*100)+'%'"></div>
              </div>
              <div class="progress-fonts">{{commissionerData.weekInf.submitNum }}/{{ commissionerData.weekInf.allNum }}</div>
            </div>
          </div>
        </div>
        <div class="weekly-commissioner-box " v-for="(c,i) in commissionerData.list" v-bind:key="i" >
            <div class="weekly-commissioner-box-title">{{ c.regionName }}</div>
            <div :class="'weekly-commissioner-box-info state-'+(c.allNum1==0?3:c.submitNum1/c.allNum1==1?2:1)"
                 @click=" regionId = c.regionId ;weeklyType= '1'; getPageInfo(c.allNum1,commissionerData.weekInf.year,commissionerData.weekInf.week)"
                 v-if="weeklyTypes=='1,2'"
            >
              <span>省区销售周报</span>
              <div class="progress-box">
                <div class="progress-box-in" :style="'width:'+(c.allNum1==0?100:c.submitNum1/c.allNum1*100)+'%'"></div>
                <div class="progress-box-text" v-if="c.allNum1!=0&c.allNum1!=c.submitNum1">{{ c.submitNum1 }}/{{c.allNum1}}</div>
                <div class="progress-box-text" v-if="c.allNum1==0">未完成</div>
                <div class="progress-box-text" v-if="c.allNum1==c.submitNum1&c.allNum1!=0">已完成</div>
              </div>
              <img src="/img/weekly/icon-5.png">
            </div>

            <div :class="'weekly-commissioner-box-info state-'+(c.allNum2==0?3:c.submitNum2/c.allNum2==1?2:1)"
                 @click=" regionId = c.regionId ;weeklyType= '2'; getPageInfo(c.allNum2,commissionerData.weekInf.year,commissionerData.weekInf.week)"
                 v-if="weeklyTypes=='1,2'"
            >
              <span>大区经理周报</span>
              <div class="progress-box">
                <div class="progress-box-in" :style="'width:'+(c.allNum2==0?100:c.submitNum2/c.allNum2*100)+'%'"></div>
                <div class="progress-box-text" v-if="c.allNum2!=0&c.allNum2!=c.submitNum2">{{ c.submitNum2 }}/{{c.allNum2}}</div>
                <div class="progress-box-text" v-if="c.allNum2==0">未提交</div>
                <div class="progress-box-text" v-if="c.allNum2==c.submitNum2&c.allNum2!=0">已完成</div>
              </div>
              <img src="/img/weekly/icon-5.png">
            </div>

<!--          <div :class="'weekly-commissioner-box-info state-'+(c.allNum3==0?3:c.submitNum3/c.allNum3==1?2:1)"-->
<!--               @click=" regionId = c.regionId ;weeklyType= '4'; getPageInfo(c.allNum3,commissionerData.weekInf.year,commissionerData.weekInf.week)"-->
<!--               v-if="weeklyTypes=='4'"-->
<!--          >-->
<!--            <span>客户成功经理</span>-->
<!--            <div class="progress-box">-->
<!--              <div class="progress-box-in" :style="'width:'+(c.allNum3==0?100:c.submitNum3/c.allNum3*100)+'%'"></div>-->
<!--              <div class="progress-box-text" v-if="c.allNum3!=0&c.allNum3!=c.submitNum3">{{ c.submitNum3 }}/{{c.allNum3}}</div>-->
<!--              <div class="progress-box-text" v-if="c.allNum3==0">未提交</div>-->
<!--              <div class="progress-box-text" v-if="c.allNum3==c.submitNum3&c.allNum3!=0">已完成</div>-->
<!--            </div>-->
<!--            <img src="/img/weekly/icon-5.png">-->
<!--          </div>-->
        </div>
        <div class="add-more back" @click="commissionerShow=false">返回</div>
      </div>

      <!--      详情列表-->
      <div class="weekly-box region" v-if="regionIn&!weeklyListShow">
<!--        <div class="top-back-btn" @click="close()" >返回</div>-->
        <div class="info-top">
          <div>
            <img src="/img/weekly/icon-8.png">
            <div>
              <span>{{ weeksGroupWeekType.weekInf.year }}年第{{ weeksGroupWeekType.weekInf.week }}周 周报</span>
              <span>{{ weeksGroupWeekType.weekInf.beginTime.split('T')[0] }} - {{ weeksGroupWeekType.weekInf.endTime.split('T')[0] }}</span>
            </div>
          </div>
          <div>
            <img src="/img/weekly/icon-9.png">
            <span>状态</span>
            <abbr v-if="weeksGroupWeekType.weekInf.status==0">进行中</abbr>
            <abbr v-if="weeksGroupWeekType.weekInf.status==1">全部完成</abbr>
            <abbr v-if="weeksGroupWeekType.weekInf.status==2">部分完成</abbr>
            <abbr v-if="weeksGroupWeekType.weekInf.status==3">未完成</abbr>
          </div>
          <div>
            <img src="/img/weekly/icon-10.png">
            <span>进度</span>
            <div class="progress">
              <div class="progress-box">
                <div class="progress-box-in" :style="'width:'+(weeksGroupWeekType.weekInf.submitNum/weeksGroupWeekType.weekInf.allNum*100)+'%'"></div>
              </div>
              <div class="progress-fonts">{{weeksGroupWeekType.weekInf.submitNum }}/{{ weeksGroupWeekType.weekInf.allNum }}</div>
            </div>
          </div>
        </div>
        <div :class="'weekly-box-line state-'+(parseFloat(w.weeklyStatus))" v-for="(w,i) in weeksGroupWeekTypeData" v-bind:key="i" @click="weeklyId=w.weeklyId; weeklyListShow=true">
          <div class="weekly-box-line-left">
            <span></span>
            <abbr v-if="w.weeklyStatus==0">未填报</abbr>
            <abbr v-if="w.weeklyStatus==2">完成</abbr>
            <abbr v-if="w.weeklyStatus==3">超期完成</abbr>
          </div>
          <div class="weekly-box-line-center">
            <span>{{ w.nickName }}  {{ w.regionName }}</span>
            <abbr :class="{'on':w.commentUnReadNum!=0}" v-if="w.commentUnReadNum!=0 || w.commentNum!=0">
              <p :class="{'dot':true,'on':w.commentUnReadNum!=0}"></p>
              <p>{{w.commentUnReadNum?w.commentUnReadNum:w.commentNum}}条留言</p>
            </abbr>
          </div>
          <img src="/img/weekly/icon-5.png" class="weekly-box-line-right">
        </div>
        <div class="add-more back" @click="backClose()">返回</div>
      </div>


      <Footer v-model="user" :id="3"></Footer>



      <WeeklyShow v-if="weeklyListShow"  :weeklyId="weeklyId"  @back="weeklyListShow=false"></WeeklyShow>
      <Upbox @close="upError=''"  v-if="upError" :type="'tips'" :data="{back:'返回',state:upState,title:'操作失败！',desc:upError}"></Upbox>
    </div>

</template>
<script>
import Footer from '../../components/Footer.vue'
import WeeklyShow from '../../components/weekly/WeeklyShow.vue'
import Upbox from '../../components/Upbox.vue'
export default {
  name: 'Files',
  props:{
    weeklyTypes:String
  },
  data: function () {
    return {
      // weeklyTypes:'1,2',
      weeklyType:'1',
      user:{},
      regionIn:false,
      weeklyListShow:false,
      weeklyId:0,

      weeklyListPages:0,
      weeksGroupData:[],
      weeksGroup:{
        list:{
          rows:[]
        }
      },
      weeksGroupWeekTypeData:[],
      weeksGroupWeekType:{},
      weeksGroupWeekShowId:0,
      commissionerShow:false,
      commissionerData:[],
      regionId:null,
      upError:'',
      upState:'error'
    }
  },
  components: { Footer,WeeklyShow,Upbox},
  watch:{
    user(){
      this.getWeeksGroup()
    }
  },
  methods:{
    getPageInfo(allNum1,year,week){
      if(allNum1==0){
        this.upError='暂无周报，无法查看'
      }else{
        this.getWeeksGroupWeekType(year,week)
      }

    },
    change(e){
      // this.name=''
      // this.minPath=e
    },
    backClose(){
      if(this.weeklyTypes!='4'&&this.weeklyTypes!='1'&&this.weeklyTypes!='2'){
        this.weeksGroupWeekTypeData=[];
        this.regionIn=false;
        this.commissionerShow=true
      }else{
        this.weeksGroupWeekTypeData=[];
        this.regionIn=false;
        this.weeklyListShow=false;
        this.commissionerShow=false
      }
    },
    close(){
      this.$emit('close')
    },
    getWeeksGroup(){
      let vm =this
      vm.weeklyListPages++
      vm.$axios.get('/business/weekly/list/group/data?pageSize=10&pageNum='+vm.weeklyListPages+'&weeklyTypes='+ vm.weeklyTypes, null,{
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(function (res) {
        console.log(res)
        vm.weeksGroup = res.data
        //
        res.data.list.rows.map(r=>{
          vm.weeksGroupData.push(r)
        })
      })
    },
    getCommissionerData(year,week){
      let vm =this

      if(this.weeklyTypes==4||this.weeklyTypes==2||this.weeklyTypes==1){
        vm.weeklyType = this.weeklyTypes
        vm.regionId = ''
        vm.getWeeksGroupWeekType(year,week)
        return;
      }

      vm.weeklyListPages++
      vm.$axios.get('/business/weekly/list/group/type?year='+year+'&week='+week, null,{
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(function (res) {
        console.log(res)
        vm.commissionerData = res.data
        vm.commissionerShow=true
      })
    },
    getWeeksGroupWeekType(year,week,id) {
      let vm =this

      vm.$axios.get('/business/weekly/list/by/week_type?year='+year+'&week='+week+'&weeklyType='+ vm.weeklyType+'&regionId='+vm.regionId, null,{
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(function (res) {
        console.log(res)
        vm.weeksGroupWeekType = res.data
        vm.weeksGroupWeekTypeData = []
        res.data.list.map(r=>{
          vm.weeksGroupWeekTypeData.push(r)
        })
        vm.regionIn=true
        vm.commissionerShow=false
      })
    }
    },
  mounted(){
    // alert(this.weeklyTypes)

    // var vm = this
    // this.getFiles()
  }

  
  
}
</script>
