<template>
    <div class=" up-examine weekly">
      <div class="stc-list" v-if="!info">
        <div class="stc-top-box">
          <div class="reason-line">
            <div class="input-box">
              <span>提交人</span>
              <div class="select-box">
                <div class="select-show">{{ weeklyType==1?'省区销售':'大区经理' }}</div>
                <div class="down-icon">
                  <img src="/img/shop/icon-3.png">
                </div>
                <select v-model="weeklyType">
                  <option value="1">省区销售</option>
                  <option value="2">大区经理</option>
                </select>
              </div>
            </div>

            <div class="input-box">
              <span>来源周报</span>
              <div class="select-box">
                <div class="select-show">{{weeklyListSelect=='全部'?'全部':weeklyList[weeklyListSelect].year+'年第'+weeklyList[weeklyListSelect].week+'周'}}</div>
                <div class="down-icon">
                  <img src="/img/shop/icon-3.png">
                </div>
                <select v-model="weeklyListSelect">
                  <option value="全部">全部</option>
                  <option v-for="(w,i) in weeklyList" :key="'weeklyList_'+i" :value="i">{{ w.year }}年第{{w.week}}周</option>
                </select>
              </div>
            </div>

<!--            <div class="input-box">-->
<!--              <span>药店名称</span>-->
<!--              <div class="select-box">-->
<!--                <input type="text" v-model="keyWord" placeholder="筛选药店名称">-->
<!--              </div>-->
<!--            </div>-->
            <div class="search" @click="listData=[];listPageNum=1;getList()">搜索</div>
          </div>

        </div>
        <div class="stc-title" v-if="list&&list.rows.length!=0"> </div>
        <div class="stc-title" style="text-align: center" v-if="list&&list.rows.length==0">暂无信息，请更改筛选条件</div>
        <div class="stc-info-box" v-for="(l,i) in listData" :key="'listData_'+i" @click="getInfo(l.detailId)" >
          <div class="stc-info-box-in">
<!--            <div class="stc-info-title">-->
<!--              <div class="stc-img-box"><img src="/img/weekly/icon-11.png" ></div>-->
<!--              <span>{{ l.pharmacyName }} {{ l.storeName }}</span>-->
<!--            </div>-->
            <div class="stc-info-line">
              <div class="stc-img-box"><img src="/img/weekly/icon-12.png" ></div>
              <span>提交人</span>
              <span>{{l.nickName}}</span>
            </div>
            <div class="stc-info-line">
              <div class="stc-img-box"><img src="/img/weekly/icon-13.png" ></div>
              <span>提交于</span>
              <span>{{ l.year }}年第{{ l.week }}周周报</span>
            </div>
            <div class="stc-info-line">
              <div class="stc-img-box"><img src="/img/weekly/icon-14.png" ></div>
              <span>提交时间</span>
              <span>{{ l.submitTime.split('T')[0] }} {{l.submitTime.split('T')[1]}}</span>
            </div>
          </div>
          <img src="/img/weekly/icon-1-1.png"  class="icon-1-1">
        </div>
        <div class="add-more n-bottom" @click="getList()" v-if="listData.length!=0 && list.total>listData.length">加载更多</div>
        <div class="add-more back" @click="close()">返回</div>
      </div>
      <div class="stc-list no-search-bottom" v-if="info">
        <div class="stc-list-top">
          <div class="stc-info-line">
            <div class="stc-img-box"><img src="/img/weekly/icon-15.png" ></div>
            <span>提交人</span>
            <span>{{infoData.weeklyType==1?'省区销售':'大区经理' }} {{infoData.nickName}}</span>
          </div>
          <div class="stc-info-line">
            <div class="stc-img-box"><img src="/img/weekly/icon-16.png" ></div>
            <span>提交于</span>
            <span>{{infoData.year }}年第{{infoData.week}}周周报</span>
          </div>
          <div class="stc-info-line">
            <div class="stc-img-box"><img src="/img/weekly/icon-17.png" ></div>
            <span>提交时间</span>
            <span>{{ infoData.submitTime.split('T')[0] }} {{ infoData.submitTime.split('.')[0].split('T')[1]}}</span>
          </div>
        </div>

        <div class="stc-list-box">
          <template v-if="infoData.weeklyType==1">
            <div class="stc-list-box-in">
              <span>建码进展</span>
              <span>{{ infoData.stc.codeBuildingProgress }}</span>
            </div>
            <div class="stc-list-box-in">
              <span>贴柜培训</span>
              <span>{{ infoData.stc.storeTraining }}</span>
            </div>
            <div class="stc-list-box-in">
              <span>陈列维护</span>
              <span>{{ infoData.stc.displayItems }}</span>
            </div>
            <div class="stc-list-box-in">
              <span>销售管理</span>
              <span>{{ infoData.stc.salesManagement }}</span>
            </div>
            <div class="stc-list-box-in">
              <span>用户活动</span>
              <span>{{ infoData.stc.userActivity }}</span>
            </div>
          </template>
          <template v-if="infoData.weeklyType==2">
            <div class="stc-list-box-in">
              <span>药房经销商开发维护</span>
              <span>{{ infoData.stc.pharmacyDealerDev }}</span>
            </div>
            <div class="stc-list-box-in">
              <span>重点连锁维护开发维护</span>
              <span>{{ infoData.stc.keyChainMaintenDev }}</span>
            </div>
          </template>
        </div>
        <div class="search" @click="info=false">返回</div>
      </div>
      <Footer v-model="user" :id="3"></Footer>
    </div>
</template>
<script>
import Footer from '../../components/Footer.vue'

export default {
  name: 'Files',
  props:{
    setWeeklyType:Number
  },
  data: function () {
    return {
      user:{},
      info:false,
      weeklyType:1,
      week:'',
      year:'',
      weeklyList:null,
      weeklyListSelect:'全部',
      keyWord:'',
      listPageNum:0,
      list:null,
      listData:[],
      infoData:null,
      isloading:false,
    }
  },
  components: { Footer},
  methods:{

    close(e){
      this.$emit('close')
    },
    getWeeklyList(){
      let vm =this
      vm.$axios.get('/business/weekly/week/30', null,{
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(function (res) {
        vm.weeklyList = res.data
        vm.getList()
      })
    },
    getInfo(id){
      let vm =this
      vm.$axios.get('/business/weekly/stc/info/'+id, null,{
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(function (res) {
        console.log(res)
        vm.infoData = res.data
        vm.info = true
      })
    },
    getList(){
      let vm =this
      if(vm.isloading){return}
      vm.isloading=true
      vm.listPageNum++
      if(vm.weeklyListSelect!='全部'){
        vm.year = vm.weeklyList[vm.weeklyListSelect].year
        vm.week = vm.weeklyList[vm.weeklyListSelect].week
      }else{
        vm.year = ''
        vm.week = ''
      }
      vm.$axios.get('/business/weekly/list/stc?weeklyType='+vm.weeklyType+'&week='+vm.week+'&year='+vm.year+'&keyWord='+vm.keyWord+'&pageNum='+vm.listPageNum+'&pageSize=10', null,{
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(function (res) {
        vm.isloading=false
        vm.list = res
        console.log(res)
        res.rows.map(r=>{
          vm.listData.push(r)
        })
      }).catch(e=>{
        vm.isloading=false
      })
    },
  },
  mounted(){
    // var vm = this
    // this.getFiles()
    this.getWeeklyList()
    this.weeklyType = this.setWeeklyType
  }

  
  
}
</script>
