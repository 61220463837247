import axios from "axios";
// import qs from "qs";
import Cookies from 'js-cookie'
import router from './../router/index'
console.log(process.env.VUE_APP_API)
const instance = axios.create({
    baseURL: process.env.VUE_APP_API ,
    // timeout: 30000,  //延迟时间
});

// 拦截器
instance.interceptors.request.use(
    function (config) {
        //在发送请求之前做某事

        // 后台仅接收json数据配置
        config.headers['Authorization']='Bearer '+Cookies.get('token')
        
        if(config.headers['Content-Type']==undefined){
            config.headers['Content-Type']='application/json'
        }
        // if(config.headers['Content-Type'])
        // 

        // if (config.formData === undefined) {
        //     config.formData = true;
        // }
        // if (config.formData === true && config.method.toLowerCase() !== "get") {
        //     if (config.headers['Content-Type'] && config.headers['Content-Type'] === 'application/json') {
        //       config.data = JSON.stringify(config.data);
        //     } else {
        //       config.data = qs.stringify(config.data);
        //     }
        // }
        // console.log(config)
        return config;
    },
    function (error) {
        // console.log(error)
        //请求错误时做些事
        return Promise.reject(error);
    }
);
 
// 拦截器,响应数据
instance.interceptors.response.use(
    function (response) {
        //对响应数据做些事
        let {
            data
        } = response;
        // console.log(data.code)
        if(data.code==401){
            router.push({path:'/login' + window.location.search})
            return
        }
        if (data.code === "200") {
            return data;
        } else if (data.code === "401") { // 未登录
            return Promise.reject("未登录，请登录！");
        } 
        // console.log(data.code)
        return data;
    },
    function (error) {
      if (window.navigator.onLine == true) {
          let {
              response: {
                  status
              },
              message
          } = error;
        //   console.log(JSON.parse(error.request.response))
            if(status === 401){

                Cookies.set('token','')
                router.push({name:'Login'})
                // alert('用户验证失败，请登陆')
            }
          if(status === 400){
            // alert('msg')
            return Promise.reject(JSON.parse(error.request.response).data);
          }
          if (status === 404 || status === 500) {
              message = "未能连接服务器，请稍后重试";
          }
          return Promise.reject(message);
      }  else {
          return Promise.reject('网络连接不可用,请稍后再试...');
      }
    }
);
export default instance;