<template>
  <div class="track airview">


    <div class="track-in" >
      <div class="track-in-box" >

        <template>
          
          <div class="track-in-box-line" >
            <div class="lable">反馈人</div>
            <div class="info">
              <input type="text" v-model="name"  placeholder="请填反馈人姓名" >
            </div>
          </div>
          <div class="track-in-box-line" >
            <div class="lable">反馈人联系方式</div>
            <div class="info">
              <input type="text" v-model="contact"  placeholder="请填写反馈人联系方式" >
            </div>
          </div>
          <!-- <div class="track-in-box-line" >
            <div class="lable">所属经销商</div>
            <div class="info">
              <input type="text" v-model="distributorId"  placeholder="请填写所属经销商" >
            </div>
          </div> -->
          <div class="track-in-box-line">
          <div class="lable">地区</div>

          <!-- <div class="info">{{data.reasonOfSale.distributor.addrProvince}} {{data.reasonOfSale.distributor.addrCity}}</div> -->
          <div class="info select-2">
            <div class="input-box select-box ">
              <div class="select-icon">
                <img src="/img/shop/icon-3.png">
              </div>
              <div class="select-show">{{getProvinceName(provinceId)?getProvinceName(provinceId):'请选择'}}</div>
              <select class="select" v-model="provinceId" @change="distributorId=''">
                <option value="">请选择</option>
                <option :value="r.provinceId" v-for="(r,i) in region" v-bind:key="i">{{r.provinceName}}</option>
              </select>
            </div>
          </div>
          <div class="info select-2" >
            <div class="input-box select-box ">
              <div class="select-icon">
                <img src="/img/shop/icon-3.png">
              </div>
              <div class="select-show">{{
                getCityName(getCitys(provinceId),cityId)?
                getCityName(getCitys(provinceId),cityId):'请选择'
                }}</div>
              <select class="select" v-model="cityId" @change="getDealer();distributorId=''">
                <option value="">请选择</option>
                <option :value="r.cityId" v-for="(r,i) in getCitys(provinceId)" v-bind:key="i">{{r.cityName}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="track-in-box-line">
          <div class="lable">所属经销商</div>
          <!-- <div class="info">{{distributorName}}</div> -->
          <div class="info">
            <div class="input-box select-box ">
              <div class="select-icon">
                <img src="/img/shop/icon-3.png">
              </div>
              <div class="select-show">{{
                getDealerName(distributorId)?
                getDealerName(distributorId):'请选择'
                }}</div>
              <select class="select" v-model="distributorId" >
                <option :value="d.distributorId" v-for="(d,i) in dealer"  v-bind:key="i">{{d.distributorName}}</option>
              </select>
            </div>
          </div>
        </div>

          <div class="track-in-box-line">
            <div class="lable">附加照片</div>
            <div class="info">
              <Images v-model="photos" :getImages="[]"></Images>
            </div>
          </div>
          <div class="track-in-box-line" >
            <div class="lable">反馈时间</div>
            <div class="info">
              <div class="input-box select-box">
                <div class="select-icon">
                  <img src="/img/shop/icon-3.png">
                </div>
                <div class="select-show">{{cutTime(theTime)}}</div>
                <input type="datetime-local" v-model="theTime" class="date-select" >
              </div>
            </div>
          </div>
          <div class="track-in-box-line">
            <div class="lable">问题及建议</div>
            <div class="info">
              <!-- <input type="text"  placeholder="请填写具体原因" > -->
              <textarea v-model="content"  placeholder="请填写具体原因"></textarea>
            </div>
          </div>
        </template>

        <div class="track-in-btns is-one is-eidt">
          <div @click="airviewSend()">提交</div>
        </div>
      </div>
      <Upbox @close="sendError=''"  v-if="sendError" :type="'tips'" :data="{state:upState,back:upBack,title:sendError}"></Upbox>
    </div>
    <Footer v-model="user" :id='0' ></Footer>
  </div>
</template>
<script>


import Footer from '../components/Footer.vue'
import Images from '../components/Images.vue'
import Upbox from '../components/Upbox.vue'
var moment = require('moment');
export default {
  name: 'Track',
  data: function () {
    return {
      user:{},
      sendError:'',
      upBack:'返回',
      upState:'error',
      name:'',
      contact:'',
      photos:'',
      theTime:new Date(),
      provinceId:'',
      distributorId:'',
      region:[],
      distributorName:'',
      cityId:'',
      dealer:[],
      content:''
    }
  },
  props: {
    orderId: Number,
    typeMaps:Array
  },
  components: {Images,Upbox,Footer},
  watch:{
    user(){
      this.name=this.user.user.nickName
      this.contact =this.user.user.phonenumber
    }
  },
  methods:{
    airviewSend(){
      
      let vm = this
      // console.log(new Date().toLocaleString())
      // return;
      this.$axios.post('/business/airview', {
        name:vm.name,
        contact:vm.contact,
        distributorId:vm.distributorId,
        photos:vm.photos,
        theTime:vm.cutTime(vm.theTime),
        content:vm.content
      }).then(function (res) {
        // console.log(res)
        if(res.code!=200){
          vm.upState='error'
          vm.sendError=res.msg
        }else{
          vm.upState='ok'
          vm.sendError='提交成功！'
          setTimeout(()=>{
            window.location.href='/'
          },1000)
          // vm.provinceId=''
          // vm.distributorId=''
          // vm.content=''
          // vm.photos=''
        }
      })
    },
    edit(){
      this.$emit('edit');
    },
    enter(){
      
    },
    back(){
      this.$emit('back');
      // 
      // 
    },
    resizeTime(t){
      
      if(t){
        if(t.split('.').length>1){
          t= t.split('.')[0]
          return t.split('T')[0]+' '+t.split('T')[1]
        }
        return t.split('T')[0]+' '+t.split('T')[1]+':00'
      }else{
        return ''
      }
      
    },
    getOrderInfo(data){
      if(!data){return}
      let rd = ''

      data.map(d=>{
        rd += d.productName +'x'+ d.number +'<br>'
      })

      return rd
    },
    getProvinceName:function(id){
      let data=''
      let vm = this
      vm.region.map(d=>{
        if(d.provinceId==id){
          data = d.provinceName
        }
      })
      return data
    },
    getCitys:function(id){
      let data=[]
      let vm = this
      vm.region.map(d=>{
        // console.log(d)
        if(d.provinceId==id){
          data = d.cities
        }
      })
      return data
    },
    getCityName:function(city,id){
      let data=''
      if(city){
         city.map(d=>{
          if(d.cityId==id){
            data = d.cityName
          }
        })
      }
     
      return data
    },
    getFloat2: function(x){
      if (x != '.'){
        var f = Math.round(x * 100) / 100;
        var s = f.toString();
        var rs = s.indexOf('.');
        if (rs <= 0) {
          rs = s.length;
          s += '.';
        }
        while (s.length <= rs + 2) {
          s += '0';
        }
        return s;
      }else{
        return '0.00';
      }
    },
    cutTime(date){
      // console.log()
      
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    resetTime(date){
      date = date.split(' ')[0]
      return date +' 00:00:00'
    },
    getDealerName:function(id){
      let data=''
      let vm = this
      vm.dealer.map(d=>{
        if(d.distributorId==id){
          data = d.distributorName
        }
      })
      return data
    },
    getDealer:function(){
      var vm = this
      this.$axios.get('/business/distributor/list?provinceId='+vm.provinceId+'&cityId='+vm.cityId, {
      }).then(function (res) {
        vm.dealer=res.rows
      })
    },
    // setDealerOther:function(id){

    //     // let data=''
    //     // let vm = this
    //     // vm.dealer.map(d=>{
    //     //   if(d.distributorId==id){
    //     //     data = d
    //     //   }
    //     // })
    //     // vm.data.reasonOfSale.distributor.addrDetail=data.addrDetail
    //     // vm.data.reasonOfSale.distributor.annualIndicators=data.annualIndicators
    //     // console.log(data)
    //     // console.log(data.annualIndicators)
    // },
    // linkShop:function(){
    //   this.$router.push({path:'/shop'})
    // },
    // linkOrder:function(){
    //   this.$router.push({path:'/order'})
    // },
    // linkActivity:function(){
    //   this.$router.push({path:'/activity'})
    // },
    // linkFiles:function(){
    //   this.$router.push({path:'/files'})
    // },
  },
  mounted(){
    console.log(new Date().toLocaleDateString())
    var vm = this
    this.$axios.get('/business/orderTrack/'+this.orderId, {}).then(function (res) {
      vm.data=res.data
      // vm.getDealer()
    })

    // var vm = this
    // this.$axios.get('/business/orderTrack/'+this.orderId, {}).then(function (res) {
    //   vm.data=res.data
    //   // vm.getDealer()
    // })

    this.$axios.get('/business/region', {}).then(function (res) {
      vm.region=res.data
    })
  }

  
  
}
</script>
