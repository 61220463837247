<template>
  <div class="news oc">
    <div class="top-search">
      <div class="top-search-box">
        <div class="btns mr" v-if="minPath&&name==''" @click="back= 'time' + new Date().getTime()">返回</div>
        <div class="top-inpus">
          <img src="/img/files/icon-1.png">
          <input type="text" placeholder="搜索" v-model="name">
        </div>
        <div class="btns ml" v-if="name!=''" @click="name=''">取消</div>
      </div>
    </div>
    <div class="news-home-box" v-if="user.roles" :key="demoKey">

      
      <div :class="l.readStatus?'in-line on':'in-line'"  v-for="(l,i) in list" v-bind:key="i" @click="showNews(l)">
        <div class="title-box">
          <span></span>
          <div class="title">{{l.title}}</div>
        </div>
        <div class="time">{{cutTime(l.createTime)}}</div>
      </div>

      <div class="is-null" v-if="list.length==0">
        <svg style="opacity:0.5" t="1655442012418" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2828" width="200" height="200"><path d="M974.966667 91.46a21.333333 21.333333 0 0 0-21.713334-5.033333l-896 298.666666a21.333333 21.333333 0 0 0-4.226666 38.533334L256 545.413333V832a21.333333 21.333333 0 0 0 36.42 15.086667L448 691.506667l240.913333 240.913333a21.333333 21.333333 0 0 0 35.426667-8.666667l256-810.666666a21.333333 21.333333 0 0 0-5.373333-21.626667zM796.666667 183.606667L277.106667 508.32 114.746667 410.906667zM298.666667 545.16l537.82-336.14-403.873334 437.533333L298.666667 780.5z m395.573333 332.24l-216.666667-216.666667 433.333334-469.426666z" fill="#5C5C66" p-id="2829"></path></svg>
        <p>这里空空如也~</p>
      </div>

    </div>
    <News :list="rowsData" :user="user" :id='0' v-if="isShow" @back="isShow=false"></News>
    <Footer v-model="user" :id='0' ></Footer>
    
  </div>
</template>
<script>
import Footer from '../components/Footer.vue'
import News from '../components/News.vue'


export default {
  name: 'Files',
  data: function () {
    return {
      examineShow:false,
      user:{},
      step:0,
      search:'',
      name:'',
      minPath:'',
      back:'',
      isShow:false,
      list:[],
      rowsData:{},
      demoKey:'demo-' + +new Date() + ((Math.random() * 1000).toFixed(0) + ''),
    }
  },
  components: { Footer,News},
  methods:{
    change(e){
      this.name=''
      this.minPath=e
    },
    cutTime(date){
      return date.split(' ')[0]
    },
    changeDemo(){
      this.demoKey='demo-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
    },
    getNews(){
      var vm = this
      this.$axios.get('business/information/list', {}).then(function (res) {
        vm.list=res.rows
        vm.changeDemo()
      })
    },
    showNews(data){
      var vm = this
      this.$axios.get('business/information/'+data.informationId, {}).then(function (res) {
        // console.log(res)
        vm.isShow=true
        vm.rowsData=res.data
        vm.getNews()
      })
    }
  },
  mounted(){
    this.getNews()
    // var vm = this
    // this.getFiles()

    // /business/information/list
  }

  
  
}
</script>
