<template>

    <div :class=" isSelect=='send'?'up-examine weekly w':'up-examine weekly '">
      <div class="nav-bar" v-if="!info">
        <div class="select-box" >
          <div :class="isSelect=='send'?'on':''" @click="isSelect='send'">提交周报</div>
          <div :class="isSelect=='get'?'on':''" @click="isSelect='get'">我的周报</div>
        </div>
      </div>
<!--      填写周报信息-->
      <div class="weekly-box-up" v-if="up">
        <div class="weekly-box up">

          <template v-for="(u,i) in upData" >
            <template>
              <div class="input-box"  :key="i" v-if="u.state=='input'">
                <span>{{ u.title }}</span>
                <input v-model="u.value" placeholder="请填写">
              </div>

              <div class="input-box" :key="i" v-if="u.state=='date'">
                <span>{{ u.title }}</span>
                <div class="select-box">
                  <div class="info-show">{{u.value}}</div>
                  <div class="down-icon">
                    <img src="/img/shop/icon-3.png">
                  </div>
                  <input v-model="u.value" placeholder="请填写" type="date">
                </div>
              </div>

              <div class="input-box" :key="i" v-if="u.state=='select'">
                <span>{{ u.title }}</span>
                <div class="select-box">
                  <div class="info-show">{{u.value?u.dictionaries[u.value]:'请选择'}}</div>
                  <div class="down-icon">
                    <img src="/img/shop/icon-3.png">
                  </div>
                  <select v-model="u.value">
                    <option :value="i" v-for="(s,i) in u.select" :key="i">{{ s }}</option>
                  </select>
                </div>
              </div>

              <div class="input-box" :key="i" v-if="u.state=='textarea'">
                <span>{{ u.title }}</span>
                <textarea v-model="u.value" placeholder="请填写具体事项"></textarea>
              </div>
            </template>
          </template>

          <div class="btns-box" >
            <div @click="saveUp(upId)">保存</div>
            <div @click="up=false">返回</div>
          </div>

<!--          v-if=" minSize < maxSize"-->
          <div class="add-more"  @click="removeUp(upId)">删除</div>
        </div>
      </div>
<!--      我的周报-->
      <div class="weekly-box" v-if="isSelect=='get'&&!info">
<!--        getMyWeekById(m.weeklyId)-->
        <div :class="'weekly-box-line state-'+m.weeklyStatus" v-for="(m,i) in myWeeklyListData"  v-bind:key="i" @click="showWeeklyInfo(m)">
          <div class="weekly-box-line-left" >
            <span></span>
            <abbr v-if="m.weeklyStatus==1||m.weeklyStatus==0">待填写</abbr>
            <abbr v-if="m.weeklyStatus==2">完成</abbr>
            <abbr v-if="m.weeklyStatus==3">超期完成</abbr>
          </div>
          <div class="weekly-box-line-center">
            <span>{{m.year}}年第{{ m.week}}周</span>
            <abbr>{{ m.beginTime}} - {{m.endTime }}</abbr>
            <abbr :class="{'on':m.commentUnReadNum!=0}" v-if="m.commentUnReadNum!=0 || m.commentNum!=0 ">
              <p :class="{'dot':true}"></p>
              <p>{{m.commentUnReadNum?m.commentUnReadNum:m.commentNum}}条留言</p>
            </abbr>
          </div>
          <img src="/img/weekly/icon-5.png" class="weekly-box-line-right">
        </div>
        <div class="add-more n-bottom" @click="getMyWeeklyList();" v-if="myWeeklyListData.length!=0 && myWeeklyList.total>myWeeklyListData.length">加载更多</div>
        <div class="add-more back" @click="close()">返回</div>
      </div>
<!--      提交周报-->
      <div class="weekly-box" v-if="isSelect=='send'&&!info&&(weeklyProgressState==2||weeklyProgressState==3)">
        <div class="sub-title" style="text-align: center">本周周报已提交,可在我的周报查看</div>
        <div class="add-more back" @click="close()">返回</div>
      </div>
<!--      -->
      <div class="weekly-box" v-if="isSelect=='send'&&!info&&weeklyProgressState!=2&&weeklyProgressState!=3">
          <template v-if="weeklyShowState===1||weeklyShowState===2||weeklyShowState===3||weeklyShowState===4">
              <div class="title">工作和计划</div>
              <div class="sub-title">本周工作 TOP3 事项</div>
              <div class="input-box">
                <span>TOP1</span>
                <textarea placeholder="请填写具体事项" v-model="workPlan[0].content"></textarea>
              </div>
              <div class="input-box">
                <span>TOP2</span>
                <textarea placeholder="请填写具体事项" v-model="workPlan[1].content"></textarea>
              </div>
              <div class="input-box">
                <span>TOP3</span>
                <textarea placeholder="请填写具体事项" v-model="workPlan[2].content"></textarea>
              </div>

              <div class="sub-title">下周工作 TOP3 事项</div>
              <div class="input-box">
                <span>TOP1</span>
                <textarea placeholder="请填写具体事项" v-model="workSummary[0].content"></textarea>
              </div>
              <div class="input-box">
                <span>TOP2</span>
                <textarea placeholder="请填写具体事项" v-model="workSummary[1].content"></textarea>
              </div>
              <div class="input-box">
                <span>TOP3</span>
                <textarea placeholder="请填写具体事项" v-model="workSummary[2].content"></textarea>
              </div>
          </template>
        <template v-if="weeklyShowState===1||weeklyShowState===2">
          <div class="title">订单进展</div>
          <!--        【省区销售、大区经理传】季度完成金额-->
          <div class="input-box">
            <span>季度完成金额</span>
            <input placeholder="请填写金额" v-model="completedAmount">
          </div>
          <!--        【省区销售、大区经理传】季度完成比例-->
          <div class="input-box">
            <span>季度完成比例</span>
            <input placeholder="请填写比例" v-model="completedRatio">
            <abbr>%</abbr>
          </div>
          <!--        【省区销售、大区经理传】面临的风险危机-->
          <div class="input-box">
            <span>面临风险危机</span>
            <textarea placeholder="请填写具体事项" v-model="riskCrisisFaced"></textarea>
          </div>
          <!--        【省区销售、大区经理传】解决办法-->
          <div class="input-box">
            <span>解决办法</span>
            <textarea placeholder="请填写具体事项" v-model="termsOfSettlement"></textarea>
          </div>
        </template>
        <template v-if="weeklyShowState===2">
          <div class="title">省区渠道进展</div>
          <!--        【大区经理传】潜力代理商-->
          <div class="input-box">
            <span>潜力代理商</span>
            <textarea placeholder="请填写" v-model="newAgent"></textarea>
          </div>
          <!--        【大区经理传】重点代理商-->
          <div class="input-box">
            <span>重点代理商</span>
            <textarea placeholder="请填写" v-model="keyAgent"></textarea>
          </div>

          <div class="title">团队人员情况</div>

          <div class="input-box">
            <span>成员现状</span>
            <input placeholder="请填写" v-model="memberStatus">
          </div>
          <!--        【大区经理传】团队人员情况-进步计划-->
          <div class="input-box">
            <span>进步计划</span>
            <input placeholder="请填写" v-model="progressPlan">
          </div>
        </template>
<!--        客户拜访—代理商列表-->
          <template v-if="weeklyShowState===1||weeklyShowState===2||weeklyShowState===3||weeklyShowState===4">
            <div class="title cut-line">客户拜访</div>
            <div class="sub-title" v-if="weeklyShowState===1||weeklyShowState===2||weeklyShowState===4">代理商</div>

            <template v-for="(e,i) in agentVisits">
              <div class="input-info-box" :key="'agentVisits_'+i" v-if="e.name!=''">
                <div class="in-line-box">
                  <div class="in-line">
                    <span>姓名</span>
                    <abbr>{{ e.name }}</abbr>
                  </div>
                  <div class="in-line">
                    <span>单位</span>
                    <abbr>{{ e.company }}</abbr>
                  </div>
                  <div class="in-line">
                    <span>拜访主题</span>
                    <abbr>{{ e.theme }}</abbr>
                  </div>
                  <div class="in-line">
                    <span>客户反馈</span>
                    <abbr>{{ e.feedback }}</abbr>
                  </div>
                </div>
                <img src="/img/weekly/icon-6.png" @click="upSet('agentVisits',i)">
              </div>

              <div class="input-info-box add" @click="upSet('agentVisits',i)" :key="'agentVisits_'+i" v-if="e.name==''">
                <img src="/img/weekly/icon-6.png">
                <span>完善拜访信息</span>
              </div>
            </template>
            <div class="add-more" @click="upAdd('agentVisits')">添加更多</div>
          </template>
<!--        【省区销售、大区经理、客户成功经理传】客户拜访—专家列表-->
        <template v-if="weeklyShowState===1||weeklyShowState===2||weeklyShowState===4">
          <div class="sub-title">专家</div>
          <template v-for="(e,i) in expertVisits">
            <div class="input-info-box" :key="'expertVisits_'+i" v-if="e.name!=''">
              <div class="in-line-box">
                <div class="in-line">
                  <span>姓名</span>
                  <abbr>{{ e.name }}</abbr>
                </div>
                <div class="in-line">
                  <span>单位</span>
                  <abbr>{{ e.company }}</abbr>
                </div>
                <div class="in-line">
                  <span>拜访主题</span>
                  <abbr>{{ e.theme }}</abbr>
                </div>
                <div class="in-line">
                  <span>客户反馈</span>
                  <abbr>{{ e.feedback }}</abbr>
                </div>
              </div>
              <img src="/img/weekly/icon-6.png" @click="upSet('expertVisits',i)">
            </div>

            <div class="input-info-box add" @click="upSet('expertVisits',i)" :key="'expertVisits_'+i" v-if="e.name==''">
              <img src="/img/weekly/icon-6.png">
              <span>完善拜访信息</span>
            </div>
          </template>

          <div class="add-more" @click="upAdd('expertVisits')">添加更多</div>
        </template>




<!--        【省区销售、KA传】新经销商开发进展列表-->
        <template v-if="weeklyShowState===1||weeklyShowState===3">
          <div class="title cut-line">新经销商开发进展</div>
          <template v-for="(e,i) in dealerDevProgresses" >
            <div class="input-info-box" :key="'dealerDevProgresses_'+i" v-if="e.company!==''">
              <div class="in-line-box">
                <div class="in-line">
                  <span>公司名称</span>
                  <abbr>{{ e.company}}</abbr>
                </div>
                <div class="in-line">
                  <span>经销商类型</span>
                  <abbr>{{ DictionariesDealerDevProgressesGm[e.distributorType]}}</abbr>
                </div>
                <div class="in-line">
                  <span>进展</span>
                  <abbr>{{ DictionariesDealerDevProgresses[e.progress] }}</abbr>
                </div>
                <div class="in-line">
                  <span>背景及现状</span>
                  <abbr>{{ e.situation }}</abbr>
                </div>
              </div>
              <img src="/img/weekly/icon-6.png" @click="upSet('dealerDevProgresses',i)">
            </div>

            <div class="input-info-box add" @click="upSet('dealerDevProgresses',i)" :key="'dealerDevProgresses_'+i" v-if="e.company==''">
              <img src="/img/weekly/icon-6.png">
              <span>完善信息</span>
            </div>
          </template>
          <div class="add-more" @click="upAdd('dealerDevProgresses')">添加更多</div>
        </template>

        <!--        【KA传】新连锁开发进展列表-->
        <template v-if="weeklyShowState===3">
          <div class="title cut-line">新连锁开发进展</div>
          <template v-for="(e,i) in chainDevProgresses" >
            <div class="input-info-box" :key="'chainDevProgresses_'+i" v-if="e.company!==''">
              <div class="in-line-box">
                <div class="in-line">
                  <span>公司名称</span>
                  <abbr>{{ e.company}}</abbr>
                </div>
                <div class="in-line">
                  <span>进展</span>
                  <abbr>{{ DictionariesDealerDevProgresses[e.progress]}}</abbr>
                </div>
                <div class="in-line">
                  <span>背景及现状</span>
                  <abbr>{{ e.situation }}</abbr>
                </div>
              </div>
              <img src="/img/weekly/icon-6.png" @click="upSet('chainDevProgresses',i)">
            </div>

            <div class="input-info-box add" @click="upSet('chainDevProgresses',i)" :key="'chainDevProgresses_'+i" v-if="e.company==''">
              <img src="/img/weekly/icon-6.png">
              <span>完善信息</span>
            </div>
          </template>
          <div class="add-more" @click="upAdd('chainDevProgresses')">添加更多</div>
        </template>

<!--        【客户成功经理传】培训列表-->
        <template v-if="weeklyShowState===4">
          <div class="title">培训列表</div>
          <template v-for="(e,i) in trainings" >
            <div class="input-info-box" :key="'trainings_'+i" v-if="e.company!==''">
              <div class="in-line-box">
                <div class="in-line">
                  <span>公司名称</span>
                  <abbr>{{ e.company }}</abbr>
                </div>
                <div class="in-line">
                  <span>培训日期</span>
                  <abbr>{{ e.trainingDate }}</abbr>
                </div>
                <div class="in-line">
                  <span>主题</span>
                  <abbr>{{ e.theme }}</abbr>
                </div>
              </div>
              <img src="/img/weekly/icon-6.png" @click="upSet('trainings',i)">
            </div>

            <div class="input-info-box add" @click="upSet('trainings',i)" :key="'trainings_'+i" v-if="e.company==''">
              <img src="/img/weekly/icon-6.png">
              <span>完善信息</span>
            </div>
          </template>
          <div class="add-more" @click="upAdd('trainings')">添加更多</div>
        </template>


        <div class="title" v-if="weeklyShowState===1||weeklyShowState===2">STC项目进展</div>
        <!--        【省区销售、大区经理】必传是否需要上传stc 信息-->
        <template v-if="weeklyShowState===1||weeklyShowState===2">
          <div class="item-checkbox">
            <div class="item-checkbox__title">是否涉及STC项目进展：</div>
            <div class="item-checkbox__value">
              <input type="radio" value="1" name="cbx" id="inputr1" v-model="hasStc"><label for="inputr1">是</label>
              <input type="radio" value="0" name="cbx" id="inputr2" v-model="hasStc"><label for="inputr2">否</label>
            </div>
          </div>
        </template>

        <div class="title" v-if="weeklyShowState===4">B4B项目进展</div>
        <div class="title" v-if="weeklyShowState===3">终端客户拜访</div>
          <template v-if="weeklyShowState===4">
    <!--        【客户成功经理传】B4B项目进展列表-->
<!--            <div class="sub-title">项目进展</div>-->
            <template v-for="(e,i) in b4bList" >
              <div class="input-info-box" :key="'b4bList_'+i" v-if="e.projectName!==''">
                <div class="in-line-box">
                  <div class="in-line">
                    <span>项目名称</span>
                    <abbr>{{ e.projectName }}</abbr>
                  </div>
                  <div class="in-line">
                    <span>项目进展</span>
                    <abbr>{{ e.progress }}</abbr>
                  </div>
                </div>
                <img src="/img/weekly/icon-6.png" @click="upSet('b4bList',i)">
              </div>

              <div class="input-info-box add" @click="upSet('b4bList',i)" :key="'b4bList_'+i" v-if="e.projectName==''">
                <img src="/img/weekly/icon-6.png">
                <span>完善信息</span>
              </div>
            </template>
            <div class="add-more" @click="upAdd('b4bList')">添加更多</div>
          </template>

<!--        【省区销售、大区经理、KA传】STC列表-->
        <template v-if="weeklyShowState===1 || weeklyShowState===2 || weeklyShowState===3">
          <template v-for="(e,i) in stcList" >
            <div class="input-info-box" :key="'stcList_'+i" v-if="e.codeBuildingProgress!=='' || e.pharmacyDealerDev!=='' || e.pharmacyName!==''">
              <div class="in-line-box">
                <template v-if="weeklyShowState===1">
                  <div class="in-line">
                    <span>建码进展</span>
                    <abbr>{{ e.codeBuildingProgress }}</abbr>
                  </div>
                  <div class="in-line">
                    <span>贴柜培训</span>
                    <abbr>{{ e.storeTraining }}</abbr>
                  </div>
                  <div class="in-line">
                    <span>陈列维护</span>
                    <abbr>{{ e.displayItems }}</abbr>
                  </div>
                  <div class="in-line">
                    <span>销售管理</span>
                    <abbr>{{ e.salesManagement }}</abbr>
                  </div>
                  <div class="in-line">
                    <span>用户活动</span>
                    <abbr>{{ e.userActivity }}</abbr>
                  </div>
                </template>

                <template v-if="weeklyShowState===2">
                  <div class="in-line">
                    <span>药房经销商开发维护</span>
                    <abbr>{{ e.pharmacyDealerDev }}</abbr>
                  </div>
                  <div class="in-line">
                    <span>重点连锁维护开发维护</span>
                    <abbr>{{ e.keyChainMaintenDev }}</abbr>
                  </div>
                </template>

                <template v-if="weeklyShowState===3">
                  <div class="in-line">
                    <span>药店名称</span>
                    <abbr>{{ e.pharmacyName }}</abbr>
                  </div>
                  <div class="in-line">
                    <span>门店名称</span>
                    <abbr>{{ e.storeName }}</abbr>
                  </div>
                  <div class="in-line">
                    <span>陈列项目</span>
                    <abbr>{{ e.displayItems }}</abbr>
                  </div>
                  <div class="in-line">
                    <span>贴店培训</span>
                    <abbr>{{ e.storeTraining }}</abbr>
                  </div>
                  <div class="in-line">
                    <span>售后处理</span>
                    <abbr>{{ e.afterSales }}</abbr>
                  </div>
                  <div class="in-line">
                    <span>用户活动</span>
                    <abbr>{{ e.userActivity }}</abbr>
                  </div>
                  <div class="in-line">
                    <span>情报收集</span>
                    <abbr>{{ e.intelligenceCollection }}</abbr>
                  </div>
                </template>
              </div>
              <img src="/img/weekly/icon-6.png" @click="upSet('stcList',i)">
            </div>

            <div class="input-info-box add" @click="upSet('stcList',i)" :key="'stcList_'+i"
                 v-if="weeklyShowState===1 && e.codeBuildingProgress=='' || weeklyShowState===2 && e.pharmacyDealerDev=='' || weeklyShowState===3 && e.pharmacyName==''"
            >
              <img src="/img/weekly/icon-6.png">
              <span>完善信息</span>
            </div>
          </template>
          <div class="add-more" @click="upAdd('stcList')">添加更多</div>
        </template>


        <template v-if="weeklyShowState===1">
          <div class="title">店面陈列</div>
  <!--        【省区销售传】门头-->
          <div class="input-box">
            <span>门头</span>
            <textarea placeholder="请填写具体事项" v-model="doorHead"></textarea>
          </div>
  <!--        【省区销售传】体验中心-->
          <div class="input-box">
            <span>体验中心</span>
            <textarea placeholder="请填写具体事项" v-model="experienceCenter"></textarea>
          </div>
        </template>


        <template v-if="weeklyShowState===1">
          <div class="title">市场活动</div>
<!--        【省区销售传】市场活动列表-->
          <template v-for="(e,i) in activities" >
            <div class="input-info-box" :key="'activities_'+i" v-if="e.activityName!==''">
              <div class="in-line-box">
                <div class="in-line">
                  <span>活动名称</span>
                  <abbr>{{ e.activityName }}</abbr>
                </div>
                <div class="in-line">
                  <span>活动时间</span>
                  <abbr>{{ e.activityDate }}</abbr>
                </div>
                <div class="in-line">
                  <span>目标及反馈</span>
                  <abbr>{{ e.feedback }}</abbr>
                </div>
              </div>
              <img src="/img/weekly/icon-6.png" @click="upSet('activities',i)">
            </div>

            <div class="input-info-box add" @click="upSet('activities',i)" :key="'activities_'+i" v-if="e.activityName==''">
              <img src="/img/weekly/icon-6.png">
              <span>完善信息</span>
            </div>
          </template>
          <div class="add-more" @click="upAdd('activities')">添加更多</div>
        </template>


        <div class="title cut-line">市场及竞品情况</div>
        <div class="input-box">
          <textarea class="only" v-model="marketAndRival" placeholder="请填写具体事项"></textarea>
        </div>

        <div class="btns-box">
          <div @click="sendWeekly(0)">保存草稿</div>
          <div @click="upInfo='1'">提交周报</div>
        </div>
        <div class="add-more back" @click="close()">返回</div>
      </div>

      <WeeklyShow v-if="info" :weeklyId="weeklyId" :weeklyInfoCache="weeklyInfoCache" @add="addWeeklyInfo()" @back="info=false"></WeeklyShow>
      <Footer v-model="user" :id="3"></Footer>
      <Upbox @close="upError=''"  v-if="upError" :type="'tips'" :data="{back:'返回',state:upState,title:title,desc:upError}"></Upbox>

      <Upbox v-if="upInfo" @ok="sendWeekly(1)" @close="sendWeekly(0)"   :type="'tips'" :data="{back:'保存草稿',ok:'提交周报',state:'',title:'周报提交后不可修改',desc:'如仍需继续填写请选择保存草稿'}"></Upbox>
    </div>

</template>
<script>
import Footer from '../../components/Footer.vue'
import Upbox from '../../components/Upbox.vue'
import WeeklyShow from '../../components/weekly/WeeklyShow.vue'
export default {
  name: 'Files',
  props:{
    weeklyState:String,
    weeklyInfo:Boolean
  },
  components: { Footer,Upbox,WeeklyShow},
  watch:{
    user(){
      // weeklyShowState:1, //1-省区销售填写周报;2-大区经理填写周报;3-KA填写周报;4-客户成功经理填写周报

      if(this.user.user.weeklyRole == 1){
        this.weeklyShowState = 1
      }
      if(this.user.user.weeklyRole == 2){
        this.weeklyShowState = 2
      }

      if(this.user.user.weeklyRole == 3){
        this.weeklyShowState = 3
      }

      if(this.user.user.weeklyRole == 4 || this.user.user.weeklyRole == 5){
        this.weeklyShowState = 4
      }
    }
  },

  data: function () {
    return {
      upInfo:'',
      title:'提示',
      upError:false,
      upState:'error',
      user:{},
      isSelect:'send',
      info:false,
      up:false,
      minSize:0,
      maxSize:0,
      myWeeklyList:{},
      myWeeklyListPage:0,
      myWeeklyListData:[],
      myWeekById:{},
      weeklyId:0,
      year:'',
      week:'',
      weeklyInfoCache:'',
      DictionariesDealerDevProgresses:[
          '初步沟通','确认意向','洽谈指标','签约','进货'
      ],
      DictionariesDealerDevProgressesGm:[
          '院线','流通'
      ],
      weeklyProgressState:0,
      weeklyShowState:1, //1-省区销售填写周报;2-大区经理填写周报;3-KA填写周报;4-客户成功经理填写周报
      marketAndRival:'',
      workPlan:[//本周任务
        {
          content:'',
          sortId:1
        },
        {
          content:'',
          sortId:2
        },
        {
          content:'',
          sortId:3
        }
      ],
      workSummary:[//下周任务
        {
          content:'',
          sortId:1
        },
        {
          content:'',
          sortId:2
        },
        {
          content:'',
          sortId:3
        }
      ],
      upData:[],
      upId:0,
      cacheState:'',
      agentVisits:[//客户拜访—代理商列表
        {
          name:'',
          company:'',
          theme:'',
          feedback:''
        },
        {
          name:'',
          company:'',
          theme:'',
          feedback:''
        },
        {
          name:'',
          company:'',
          theme:'',
          feedback:''
        }
      ],
      // expertUp:false,
      expertVisits:[//客户拜访—专家列表
        {
          name:'',
          company:'',
          theme:'',
          feedback:''
        },
        {
          name:'',
          company:'',
          theme:'',
          feedback:''
        },
        {
          name:'',
          company:'',
          theme:'',
          feedback:''
        }
      ],
      completedAmount:'',
      completedRatio:'',
      riskCrisisFaced:'',
      termsOfSettlement:'',
      doorHead:'',
      experienceCenter:'',
      newAgent:'',
      keyAgent:'',
      memberStatus:'',
      progressPlan:'',
      masterPlan:'',
      dealerDevProgresses:[//新经销商开发进展列表
        {
          company:'',
          progress:'', //进展：1-初步沟通，2-确认意向，3-洽谈指标，4-签约，5-进货
          situation:''
        },
        {
          company:'',
          progress:'', //进展：1-初步沟通，2-确认意向，3-洽谈指标，4-签约，5-进货
          situation:''
        },
        {
          company:'',
          progress:'', //进展：1-初步沟通，2-确认意向，3-洽谈指标，4-签约，5-进货
          situation:''
        }
      ],
      chainDevProgresses:[//新连锁开发进展列表
        {
          company:'',
          progress:'', //进展：1-初步沟通，2-确认意向，3-洽谈指标，4-签约，5-进货
          situation:''
        },{
          company:'',
          progress:'', //进展：1-初步沟通，2-确认意向，3-洽谈指标，4-签约，5-进货
          situation:''
        }
      ],
      hasStc: 0, //是否涉及STC项目进展：0-否，1是。如果此角色不涉及STC，就传0
      stcList:[//【省区销售、大区经理、KA传】STC列表
        {
          codeBuildingProgress:'',
          storeTraining:'',
          displayItems:'',
          salesManagement:'',
          userActivity:'',

          pharmacyDealerDev:'',
          keyChainMaintenDev:'',

          pharmacyName:'',
          storeName:'',
          afterSales:'',
          intelligenceCollection:'',
        },
        {
          codeBuildingProgress:'',
          storeTraining:'',
          displayItems:'',
          salesManagement:'',
          userActivity:'',

          pharmacyDealerDev:'',
          keyChainMaintenDev:'',

          pharmacyName:'',
          storeName:'',
          afterSales:'',
          intelligenceCollection:'',
        }
      ],
      activities:[//【省区销售传】市场活动列表
        {
          activityName:'',
          activityDate:'',
          feedback:''
        },
        {
          activityName:'',
          activityDate:'',
          feedback:''
        },
        {
          activityName:'',
          activityDate:'',
          feedback:''
        }
      ],
      trainings:[//【客户成功经理传】培训列表
        {
          company:'',
          trainingDate:'',
          theme:''
        },
        {
          company:'',
          trainingDate:'',
          theme:''
        }
      ],
      b4bList:[//【客户成功经理传】B4B项目进展列表
        {
          projectName:'',
          progress:''
        },
        {
          projectName:'',
          progress:''
        },
        {
          projectName:'',
          progress:''
        },
        {
          projectName:'',
          progress:''
        }
      ]
    }
  },

  methods:{
    sendWeekly(submitFlag){

      let vm =this

      // 验证数据是否合法
      if((vm.weeklyShowState == 1 || vm.weeklyShowState == 2) && vm.hasStc == 1){
        // 是否包含了符合条件的stc内容
        let hasStc = false
        for (let i=0;i < vm.stcList.length; i++) {
          if(
              (vm.weeklyShowState == 1
                  && !(vm.stcList[i].codeBuildingProgress==undefined
                      || vm.stcList[i].codeBuildingProgress==null
                      || vm.stcList[i].codeBuildingProgress==""))
              ||
              (vm.weeklyShowState == 2
                  && !(vm.stcList[i].pharmacyDealerDev==undefined
                      || vm.stcList[i].pharmacyDealerDev==null
                      || vm.stcList[i].pharmacyDealerDev==""))
          ){
            hasStc = true
          }
        }
        if(!hasStc){
          vm.title = '操作失败！'
          vm.upState = 'error'
          vm.upError = '请完善STC项目进展'
          vm.upInfo=''
          return;
        }
      }

      vm.upInfo=''
      vm.$axios.post('/business/weekly/submit', {
        year:vm.year,
        week:vm.week,
        submitFlag:submitFlag,
        agentVisits:vm.agentVisits,
        expertVisits:vm.expertVisits,
        completedAmount:vm.completedAmount,
        completedRatio:vm.completedRatio,
        riskCrisisFaced:vm.riskCrisisFaced,
        termsOfSettlement:vm.termsOfSettlement,
        doorHead:vm.doorHead,
        experienceCenter:vm.experienceCenter,
        newAgent:vm.newAgent,
        keyAgent:vm.keyAgent,
        memberStatus:vm.memberStatus,
        progressPlan:vm.progressPlan,
        masterPlan:vm.masterPlan,
        dealerDevProgresses:vm.dealerDevProgresses,
        chainDevProgresses:vm.chainDevProgresses,
        hasStc: vm.hasStc,
        stcList:vm.stcList,
        activities:vm.activities,
        trainings:vm.trainings,
        b4bList:vm.b4bList,
        workSummary:vm.workSummary,
        workPlan:vm.workPlan,
        marketAndRival:vm.marketAndRival
      },{
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(function (res) {
        console.log(res)
        if(res.code==200){
          if(submitFlag!=0){
            vm.upState = 'ok'
            vm.upError = '提交成功！'
            vm.isSelect = 'get'
            vm.title = ''
            vm.getMyWeekly()
            vm.myWeeklyListData=[]
            vm.getMyWeeklyList()
          }else{
            vm.title = ''
            vm.upState = 'ok'
            vm.upError = '保存成功！'
          }

        }else{
          vm.title = '操作失败！'
          vm.upState = 'error'
          vm.upError = res.msg
        }

      })
    },
    removeUp(id){
      let name = this.cacheState
      //客户拜访—代理商列表
      if(name=='expertVisits'){
        this.expertVisits.splice(id, 1)
      }
      //客户拜访—专家列表
      if(name=='agentVisits'){
        this.agentVisits.splice(id, 1)
      }

      //【省区销售、KA传】新经销商开发进展列表
      if(name=='dealerDevProgresses'){
        this.dealerDevProgresses.splice(id, 1)
      }

      //【省区销售、大区经理、KA传】STC列表
      if(name=='stcList'){
        this.stcList.splice(id, 1)
      }

      // 【省区销售传】市场活动列表
      if(name=='activities'){
        this.activities.splice(id, 1)
      }
      // 【客户成功经理传】培训列表
      if(name=='trainings'){
        this.trainings.splice(id, 1)
      }

      // 【客户成功经理传】B4B项目进展列表
      if(name=='b4bList'){
        this.b4bList.splice(id, 1)
      }
      // 【KA传】新连锁开发进展列表
      if(name=='chainDevProgresses'){
        this.chainDevProgresses.splice(id, 1)
      }

      this.up = false
    },
    saveUp(id){
      let name = this.cacheState
      //客户拜访—代理商列表
      if(name=='agentVisits'){
        let cache = {}
        this.upData.map(u=>{
          cache[u.id] = u.value
        })
        if(id!==undefined){
          this.agentVisits[id]=cache
        }else{
          this.agentVisits.push(cache)
        }
      }
      //客户拜访—专家列表
      if(name=='expertVisits'){
        let cache = {}
        this.upData.map(u=>{
          cache[u.id] = u.value
        })
        if(id!==undefined){
          this.expertVisits[id]=cache
        }else{
          this.expertVisits.push(cache)
        }
      }

      //【省区销售、KA传】新经销商开发进展列表
      if(name=='dealerDevProgresses'){
        let cache = {}
        this.upData.map(u=>{
          cache[u.id] = u.value
        })
        if(id!==undefined){
          this.dealerDevProgresses[id]=cache
        }else{
          this.dealerDevProgresses.push(cache)
        }
      }

      //【省区销售、大区经理、KA传】STC列表
      if(name=='stcList'){
        let cache = {}
        this.upData.map(u=>{
          cache[u.id] = u.value
        })
        if(id!==undefined){
          this.stcList[id]=cache
        }else{
          this.stcList.push(cache)
        }
      }

      // 【省区销售传】市场活动列表
      if(name=='activities'){
        let cache = {}
        this.upData.map(u=>{
          cache[u.id] = u.value
        })
        if(id!==undefined){
          this.activities[id]=cache
        }else{
          this.activities.push(cache)
        }
      }

      // 【客户成功经理传】培训列表
      if(name=='trainings'){
        let cache = {}
        this.upData.map(u=>{
          cache[u.id] = u.value
        })
        if(id!==undefined){
          this.trainings[id]=cache
        }else{
          this.trainings.push(cache)
        }
      }

      // 【客户成功经理传】B4B项目进展列表
      if(name=='b4bList'){
        let cache = {}
        this.upData.map(u=>{
          cache[u.id] = u.value
        })
        if(id!==undefined){
          this.b4bList[id]=cache
        }else{
          this.b4bList.push(cache)
        }
      }

      // 【KA传】新连锁开发进展列表
      if(name=='chainDevProgresses'){
        let cache = {}
        this.upData.map(u=>{
          cache[u.id] = u.value
        })
        if(id!==undefined){
          this.chainDevProgresses[id]=cache
        }else{
          this.chainDevProgresses.push(cache)
        }
      }
      this.up = false
    },
    upAdd(name){
      //客户拜访—专家列表
      if(name=='expertVisits'){
        this.expertVisits.push({
          name:'',
          company:'',
          theme:'',
          feedback:''
        })
      }
      //客户拜访—代理商列表
      if(name=='agentVisits'){
        this.agentVisits.push({
          name:'',
          company:'',
          theme:'',
          feedback:''
        })
      }
      //【省区销售、KA传】新经销商开发进展列表
      if(name=='dealerDevProgresses'){
        this.dealerDevProgresses.push({
          company:'',
          progress:'', //进展：1-初步沟通，2-确认意向，3-洽谈指标，4-签约，5-进货
          situation:''
        })
      }

      // 【省区销售、大区经理、KA传】STC列表
      if(name=='stcList'){
        this.stcList.push({
          codeBuildingProgress:'',
          storeTraining:'',
          displayItems:'',
          salesManagement:'',
          userActivity:'',

          pharmacyDealerDev:'',
          keyChainMaintenDev:'',

          pharmacyName:'',
          storeName:'',
          afterSales:'',
          intelligenceCollection:''
        })
      }

      // 【省区销售传】市场活动列表
      if(name=='activities'){
        this.activities.push({
          activityName:'',
          activityDate:'',
          feedback:''
        })
      }

      // 【客户成功经理传】培训列表
      if(name=='trainings'){
        this.trainings.push({
          company:'',
          trainingDate:'',
          theme:''
        })
      }
      // 【客户成功经理传】B4B项目进展列表
      if(name=='b4bList'){
        this.b4bList.push({
          projectName:'',
          progress:''
        })
      }

      //【KA传】新连锁开发进展列表
      if(name=='chainDevProgresses'){
        this.chainDevProgresses.push({
          company:'',
          progress:'', //进展：1-初步沟通，2-确认意向，3-洽谈指标，4-签约，5-进货
          situation:''
        })
      }

    },
    upSet(name,id){
      this.up = true
      this.upData=[]
      this.upId = id
      this.cacheState = name
      // 代理商
      if(name=='agentVisits'){
        this.minSize = 3
        this.maxSize = this.agentVisits.length
        this.upData = [
          {
            title:'姓名',
            value:this.agentVisits[id].name,
            state:'input',
            id:'name'
          },
          {
            title:'公司/医院',
            value:this.agentVisits[id].company,
            state:'input',
            id:'company'
          },
          {
            title:'拜访主题',
            value:this.agentVisits[id].theme,
            state:'textarea',
            id:'theme'
          },
          {
            title:'客户反馈',
            value:this.agentVisits[id].feedback,
            state:'textarea',
            id:'feedback'
          }
        ]
      }
      // 专家
      if(name=='expertVisits'){
        this.minSize = 3
        this.maxSize = this.expertVisits.length
        this.upData = [
          {
            title:'姓名',
            value:this.expertVisits[id].name,
            state:'input',
            id:'name'
          },
          {
            title:'公司/医院',
            value:this.expertVisits[id].company,
            state:'input',
            id:'company'
          },
          {
            title:'拜访主题',
            value:this.expertVisits[id].theme,
            state:'textarea',
            id:'theme'
          },
          {
            title:'客户反馈',
            value:this.expertVisits[id].feedback,
            state:'textarea',
            id:'feedback'
          }
        ]
      }

      //【省区销售、KA传】新经销商开发进展列表
      if(name=='dealerDevProgresses'){
        this.minSize = 3
        this.maxSize = this.dealerDevProgresses.length
        this.upData = [
          {
            title:'公司/医院',
            value:this.dealerDevProgresses[id].company,
            state:'input',
            id:'company'
          },
          {
            title:'经销商类型',
            value:this.dealerDevProgresses[id].distributorType,
            state:'select',
            id:'distributorType',
            dictionaries:this.DictionariesDealerDevProgressesGm,
            select:{
              0:'院线',
              1:'流通'
            }
          },
          {
            title:'进展',
            value:this.dealerDevProgresses[id].progress,
            state:'select',
            id:'progress',
            dictionaries:this.DictionariesDealerDevProgresses,
            select:{
              0:'初步沟通',
              1:'确认意向',
              2:'洽谈指标',
              3:'签约',
              4:'进货'
            }
          },
          {
            title:'背景及现状',
            value:this.dealerDevProgresses[id].situation,
            state:'textarea',
            id:'situation'
          }
        ]
      }

      // 【省区销售、大区经理、KA传】STC列表
      if(name=='stcList'){
        let setData = []
        if(this.weeklyShowState === 1){
          setData = [
            {
              title:'建码进展',
              value:this.stcList[id].codeBuildingProgress,
              state:'textarea',
              id:'codeBuildingProgress'
            },
            {
              title:'贴柜培训',
              value:this.stcList[id].storeTraining,
              state:'textarea',
              id:'storeTraining'
            },
            {
              title:'陈列维护',
              value:this.stcList[id].displayItems,
              state:'textarea',
              id:'displayItems'
            },
            {
              title:'销售管理',
              value:this.stcList[id].salesManagement,
              state:'textarea',
              id:'salesManagement'
            },
            {
              title:'用户活动',
              value:this.stcList[id].userActivity,
              state:'textarea',
              id:'userActivity'
            },
          ]
        }
        if(this.weeklyShowState === 2){
          setData = [
            {
              title:'药房经销商开发维护',
              value:this.stcList[id].pharmacyDealerDev,
              state:'textarea',
              id:'pharmacyDealerDev'
            },
            {
              title:'重点连锁维护开发维护',
              value:this.stcList[id].keyChainMaintenDev,
              state:'textarea',
              id:'keyChainMaintenDev'
            },
          ]
        }
        if(this.weeklyShowState === 3){
          setData = [
            {
              title:'药店名称',
              value:this.stcList[id].pharmacyName,
              state:'input',
              id:'pharmacyName'
            },
            {
              title:'门店名称',
              value:this.stcList[id].storeName,
              state:'input',
              id:'storeName'
            },
            {
              title:'陈列项目',
              value:this.stcList[id].displayItems,
              state:'textarea',
              id:'displayItems'
            },
            {
              title:'贴店培训',
              value:this.stcList[id].storeTraining,
              state:'textarea',
              id:'storeTraining'
            },
            {
              title:'售后处理',
              value:this.stcList[id].afterSales,
              state:'textarea',
              id:'afterSales'
            },
            {
              title:'用户活动',
              value:this.stcList[id].userActivity,
              state:'textarea',
              id:'userActivity'
            },
            {
              title:'情报收集',
              value:this.stcList[id].intelligenceCollection,
              state:'textarea',
              id:'intelligenceCollection'
            }
          ]
        }

        this.minSize = 2
        this.maxSize = this.stcList.length
        this.upData = setData
      }

      // 【省区销售传】市场活动列表
      if(name=='activities'){
        this.minSize = 3
        this.maxSize = this.activities.length
        this.upData = [
          {
            title:'活动名称',
            value:this.activities[id].activityName,
            state:'input',
            id:'activityName'
          },
          {
            title:'活动日期',
            value:this.activities[id].activityDate,
            state:'date',
            id:'activityDate'
          },
          {
            title:'目标及反馈',
            value:this.activities[id].feedback,
            state:'textarea',
            id:'feedback'
          }
        ]
      }

      // 【客户成功经理传】培训列表
      if(name=='trainings'){
        this.minSize = 2
        this.maxSize = this.trainings.length
        this.upData = [
          {
            title:'公司名称',
            value:this.trainings[id].company,
            state:'input',
            id:'company'
          },
          {
            title:'培训日期',
            value:this.trainings[id].trainingDate,
            state:'date',
            id:'trainingDate'
          },
          {
            title:'主题',
            value:this.trainings[id].theme,
            state:'textarea',
            id:'theme'
          }
        ]
      }

      // 【客户成功经理传】B4B项目进展列表
      if(name=='b4bList'){
        this.minSize = 4
        this.maxSize = this.b4bList.length
        this.upData = [
          {
            title:'项目名称',
            value:this.b4bList[id].projectName,
            state:'input',
            id:'projectName'
          },
          {
            title:'项目进展',
            value:this.b4bList[id].progress,
            state:'textarea',
            id:'progress'
          }
        ]
      }

      //【KA传】新连锁开发进展列表
      if(name=='chainDevProgresses'){
        this.minSize = 2
        this.maxSize = this.chainDevProgresses.length
        this.upData = [
          {
            title:'公司/医院',
            value:this.chainDevProgresses[id].company,
            state:'input',
            id:'company'
          },
          {
            title:'进展',
            value:this.chainDevProgresses[id].progress,
            state:'select',
            id:'progress',
            dictionaries:this.DictionariesDealerDevProgresses,
            select:{
              0:'初步沟通',
              1:'确认意向',
              2:'洽谈指标',
              3:'签约',
              4:'进货'
            }
          },
          {
            title:'背景及现状',
            value:this.chainDevProgresses[id].situation,
            state:'textarea',
            id:'situation'
          }
        ]
      }
    },
    back(){
      if(this.weeklyState!='admin'){
        this.info=false
      }else{
        this.$emit('back')
      }

    },
    change(e){
      // this.name=''
      // this.minPath=e
    },
    nullToStr(data) {
      for (var x in data) {
        if (data[x] === null) { // 如果是null 把直接内容转为 ''
          data[x] = '';
        } else {
          if (Array.isArray(data[x])) { // 是数组遍历数组 递归继续处理
            data[x] = data[x].map(z => {
              return this.nullToStr(z);
            });
          }
          if(typeof(data[x]) === 'object'){ // 是json 递归继续处理
            data[x] = this.nullToStr(data[x])
          }
        }
      }
      return data;
    },
    getMyWeekly(){
      //
      let vm =this
      vm.$axios.get('/business/weekly/item?year='+vm.year+'&week='+vm.week, null,{
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(function (res) {

        vm.weeklyProgressState = res.data.status
        if(res.data.status!=1){
          // 根据角色判断是否赋值stc
          if(vm.weeklyShowState == 1 || vm.weeklyShowState == 2){
            vm.hasStc = 1
          }
          return
        }
        res.data = vm.nullToStr(res.data)
        vm.agentVisits= res.data.agentVisits
        vm.expertVisits= res.data.expertVisits
        vm.completedAmount= res.data.completedAmount
        vm.completedRatio= res.data.completedRatio
        vm.riskCrisisFaced= res.data.riskCrisisFaced
        vm.termsOfSettlement= res.data.termsOfSettlement
        vm.doorHead= res.data.doorHead
        vm.experienceCenter= res.data.experienceCenter
        vm.newAgent= res.data.newAgent
        vm.keyAgent= res.data.keyAgent
        vm.memberStatus= res.data.memberStatus
        vm.progressPlan= res.data.progressPlan
        vm.masterPlan= res.data.masterPlan
        vm.dealerDevProgresses= res.data.dealerDevProgresses
        vm.chainDevProgresses= res.data.chainDevProgresses
        vm.hasStc= res.data.hasStc
        vm.stcList= res.data.stcList
        vm.activities= res.data.activities
        vm.trainings= res.data.trainings
        vm.b4bList= res.data.b4bList

        if(res.data.workSummary.length<1){
          res.data.workSummary.push({content:'', sortId:1})
        }
        if(res.data.workSummary.length<2){
          res.data.workSummary.push({content:'', sortId:2})
        }
        if(res.data.workSummary.length<3){
          res.data.workSummary.push({content:'', sortId:3})
        }
        vm.workSummary= res.data.workSummary


        if(res.data.workPlan.length<1){
          res.data.workPlan.push({content:'', sortId:1})
        }
        if(res.data.workPlan.length<2){
          res.data.workPlan.push({content:'', sortId:2})
        }
        if(res.data.workPlan.length<3){
          res.data.workPlan.push({content:'', sortId:3})
        }
        vm.workPlan= res.data.workPlan
        vm.marketAndRival = res.data.marketAndRival
      })
    },
    getMyWeeklyList(){
      //
      let vm =this
      vm.myWeeklyListPage++
      vm.$axios.get('/business/weekly/list/my?pageNum='+vm.myWeeklyListPage, null,{
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(function (res) {
        console.log(res)
        vm.myWeeklyList = res
        res.rows.map(r=>{
          vm.myWeeklyListData.push(r)
        })
      })
    },
    getMyWeekById(id){
      let vm =this
      vm.$axios.get('/business/weekly/'+id, null,{
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(function (res) {
        console.log(res)
        vm.myWeekById = res.data
        vm.info = true
      })
    },
    close(){
      this.$emit('close')
    },
    showWeeklyInfo(m){
      if(m.weeklyStatus==1||m.weeklyStatus==0){
        // this.upError='请提交周报后查看'



        this.weeklyId=m.weeklyId
        this.info=true
        this.weeklyInfoCache=m
      }else{
        this.weeklyId=m.weeklyId
        this.info=true
        this.weeklyInfoCache=""
      }
    },
    addWeeklyInfo(){
      this.year = this.weeklyInfoCache.year
      this.week = this.weeklyInfoCache.week
      this.info=false
      this.getMyWeekly()
      this.isSelect='send'
    },
  },

  mounted(){
    this.isSelect = this.weeklyState
    this.info = this.weeklyInfo
    this.getMyWeekly()
    this.getMyWeeklyList()

    // var vm = this
    // this.getFiles()
  }



}
</script>
