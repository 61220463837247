<template>
  <div class="activity examine-activity">
    <div class="activity-conetnt" id="scdom">
      <div class="activity-center">
        <template v-if="user.roles&&user.roles[0]!='sale_manager'">

          <div :class="tableShow==true?'my-in on is-table':'my-in is-table'" v-for="(t,i) in treeList" v-bind:key="i" @click="tableShow=!tableShow" >
            <div class="table-box">
              <div class="title">
                <span>{{t.name}}费用预算</span>
                <img src="/img/order/icon-1.png" class="icon">
              </div>

              <table>
                <tr>
                  <th class="center no-left">费用类别</th>
                  <th>项目名称</th>
                  <th>预算金额</th>
                </tr>

                <template v-for="(d,a) in t.data" >
                  <tr  v-for="(p,i) in d" v-bind:key="i +  'demo-' +new Date() + ((Math.random() * 1000).toFixed(0)) ">
                    <td :rowspan="d.length" v-if="i==0" class="center no-left" v-bind:key="a">{{a}}</td>
                    <td>{{p.projectName}}</td>
                    <td>
                      <div>预算：{{getFloat2(p.budget)}}</div>
                      <div>剩余：{{getFloat2(p.budget-p.budgetApply)}}</div>
                    </td>
                  </tr>
                </template>
              </table>
            </div>
          </div>
        </template>

        <div :class="isShow==i?'my-in on':'my-in'" v-for="(l,i) in list" v-bind:key="i +  'demo-' +new Date() + ((Math.random() * 1000).toFixed(0))" @click="isShow=i;">
          <div class="info-box">
            <div class="info">
              <div class="title-box">
                <div>{{l.user.nickName}}:{{l.activityName}}预算申请</div>
                <div v-if="getIsApproval(l.approvals)==0" >待审核</div>
                <div class="on" v-if="getIsApproval(l.approvals)==1">已审核</div>
                <div class="on" v-if="getIsApproval(l.approvals)==2">已拒绝</div>
              </div>
              <img src="/img/order/icon-1.png" class="icon">
            </div>
          </div>
          <div class="list-box">
              <div class="list-in">
                <span>申请人</span>
                <div class="list-info">
                  <div class="fonts">{{l.user.nickName}}</div>
                </div>
              </div>
              <div class="list-in">
                <span>项目名称</span>
                <div class="list-info">
                  <div class="fonts">{{l.activityName}}</div>
                </div>
              </div>
              <div class="list-in">
                <span>项目开始时间</span>
                <div class="list-info">
                  <div class="fonts">{{l.activityBeginTime}}</div>
                </div>
              </div>
              <div class="list-in">
                <span>项目结束时间</span>
                <div class="list-info">
                  <div class="fonts">{{l.activityEndTime}}</div>
                </div>
              </div>
              <div class="list-in">
                <span>项目地址</span>
                <div class="list-info">
                  <div class="fonts">{{l.activityAddr}}</div>
                </div>
              </div>

              <div class="list-in">
                <span>申请金额</span>
                <div class="list-info">
                  <div class="fonts">￥{{getFloat2(l.activityAmount)}}</div>
                </div>
              </div>
              <div class="list-in">
                <span>申请原因</span>
                <div class="list-info">
                  <div class="fonts">{{l.reasonDes}}</div>
                </div>
              </div>
              <br v-if="l.fileInvite||l.fileSchedule||l.fileContract||l.fileOther||l.isHcp==1">
              <div class="activity-center" v-if="l.fileInvite||l.fileSchedule||l.fileContract||l.fileOther||l.isHcp==1">
                ——会议活动信息——
              </div>
              <div class="list-in" v-if="l.fileInvite">
                <span>会议邀请函</span>
                <div class="list-info">
                  <div class="fonts is-show">{{getDotData(l.fileNameInvite)}}</div>
                  <div class="show" @click="linkUrl(l.fileInvite)">查看</div>
                </div>
              </div>
              <div class="list-in" v-if="l.fileSchedule">
                <span>会议日程</span>
                <div class="list-info">
                  <div class="fonts is-show">{{getDotData(l.fileNameSchedule)}}</div>
                  <div class="show" @click="linkUrl(l.fileSchedule)">查看</div>
                </div>
              </div>
              <div class="list-in" v-if="l.fileContract">
                <span>协议或合同</span>
                <div class="list-info">
                  <div class="fonts is-show">{{getDotData(l.fileNameContract)}}</div>
                  <div class="show" @click="linkUrl(l.fileContract)">查看</div>
                </div>
              </div>
              <div class="list-in" v-if="l.fileOther">
                <span>其他相关文件</span>
                <div class="list-info">
                  <div class="fonts is-show">{{getDotData(l.fileNameOther)}}</div>
                  <div class="show" @click="linkUrl(l.fileOther)">查看</div>
                </div>
              </div>
              <div class="list-in center" v-if="l.isHcp==1">
                <abbr>涉及专业人士/组织支付（如讲课费，展位，卫星会等等）</abbr>
              </div>
              <br v-if="l.fileApplicationForm||l.fileCaption||l.fileDocuments">
              <div class="activity-center" v-if="l.fileApplicationForm||l.fileCaption||l.fileDocuments">
                ——其他费用信息——
              </div>
              <div class="list-in" v-if="l.fileApplicationForm">
                <span>申请表</span>
                <div class="list-info">
                  <div class="fonts is-show">{{getDotData(l.fileApplicationFormTitle)}}</div>
                  <div class="show" @click="linkUrl(l.fileApplicationForm)">查看</div>
                </div>
              </div>

              <div class="list-in" v-if="l.fileCaption">
                <span>图片说明 </span>
                <div class="list-info">
                  <div class="fonts is-show">{{getDotData(l.fileCaptionTitle)}}</div>
                  <div class="show" @click="linkUrl(l.fileCaption)">查看</div>
                </div>
              </div>

              <div class="list-in" v-if="l.fileDocuments">
                <span>协议/合同</span>
                <div class="list-info">
                  <div class="fonts is-show">{{getDotData(l.fileDocumentsTitle)}}</div>
                  <div class="show" @click="linkUrl(l.fileDocuments)">查看</div>
                </div>
              </div>
              <div class="list-in">
                <span>申请时间</span>
                <div class="list-info">
                  <div class="fonts">{{l.createTime}}</div>
                </div>
              </div>
<!--              <template v-if="l.projects">-->
<!--                <div class="state-box" v-if="l.projects.regionName">-->
<!--                  <div class="state-title">-->
<!--                    所占费用：-->
<!--                  </div>-->
<!--                  <div class="state-info">-->
<!--                    <div>{{l.projects.regionName}}</div>-->
<!--                    <div>{{l.projects.projectType}}</div>-->
<!--                    <div>-->
<!--                      <span v-for="(p,i) in l.projects.project" v-bind:key="i + 'demo-' +new Date() + ((Math.random() * 1000).toFixed(0))">-->
<!--                        {{p.projectName}}(￥{{p.budgetUse}})-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </template>-->
<!--              <div class="state-box"  v-if="getIsApproval(l.approvals)==0&&user.roles[0]!='regional_marketing_manager'&&user.roles[0]!='sale_manager'">-->
<!--                <div class="state-title">-->
<!--                  请选择所占费用：-->
<!--                </div>-->
<!--                <div class="state-select">-->

<!--                  <div class="show">{{l.projectSelect?l.projectSelect:'请选择'}}</div>-->
<!--                  <div class="icon">-->
<!--                    <img src="/img/order/icon-1.png">-->
<!--                  </div>-->
<!--                  <select v-model="l.projectSelect" @change="clearDots()">-->
<!--                    <template v-if="treeList.length>0">-->
<!--                      <template v-for="(d,a) in treeList[0].data">-->
<!--                        &lt;!&ndash; v-if="a!='门头展示'" &ndash;&gt;-->
<!--                        <option :value="a" v-bind:key="a" >{{a}}</option>-->
<!--                      </template>-->
<!--                    </template>-->

<!--                  </select>-->
<!--                </div>-->
<!--                <template v-if="l.projectSelect" >-->
<!--                  <template v-if="treeList[0].data[l.projectSelect].length!=0">-->
<!--                    <div class="state-in" v-for="(d,a) in treeList[0].data[l.projectSelect]" v-bind:key="a" @click="d.dot=!d.dot;checkDots();changeDemo();" >-->
<!--                      <div class="confirm-box" :key="demoKey"><img src="/img/order/icon-2.png" v-if="d.dot"></div>-->
<!--                      <span  >{{d.projectName}} 剩余预算  {{getFloat2(d.budget-d.budgetApply)}}元 </span>-->
<!--                    </div>-->
<!--                  </template>-->
<!--                  <template v-if="treeList[0].data[l.projectSelect].length==0">-->
<!--                    <div class="state-error">暂无预算</div>-->
<!--                  </template>-->
<!--                </template>-->
<!--              </div>-->

              <div :class="getIsApproval(l.approvals)==0?user.roles[0]!='sale_manager'?'state-box':'state-box':'state-box'" >
                <!-- <div class="state-in" v-for="(a,b) in l.approvals" v-bind:key="b">
                  <div class="confirm-box">
                    <img src="/img/order/icon-2.png" v-if="a.approvalStatus==1">
                    <img src="/img/order/icon-3.png" v-if="a.approvalStatus==2">
                  </div>
                  <span>{{approvalsCache[a.approvalRole]}}审批</span>
                </div> -->
                <template  v-for="(a,b) in l.approvals" >
                  <div class="state-in" v-bind:key="b">
                    <div class="confirm-box">
                      <img src="/img/order/icon-2.png" v-if="a.approvalStatus==1">
                      <img src="/img/order/icon-3.png" v-if="a.approvalStatus==2">
                    </div>
<!--                    {{a.approvalRole}}-->
                    <span>{{approvalsCache[a.approvalRole]}} <span v-if="!a.approvalNickName">待审批</span>  <abbr v-if="a.approvalRole!='dept_first_trial'">{{a.approvalNickName}}</abbr> <span class="confirm-up-info" v-if="a.approvalStatus==2 && a.approvalDes && a.approvalDes!=''" @click.stop="setApprovalDesShow=a.approvalDes">查看拒批原因</span></span>
                  </div>
                  <div class="state-in" v-bind:key="b" v-if="a.approvalRole=='sales_director'">
                    <div class="confirm-box">
                      <img src="/img/order/icon-2.png" v-if="a.approvalStatus==1">
                      <img src="/img/order/icon-3.png" v-if="a.approvalStatus==2">
                    </div>
                    <span>预算负责人 <span v-if="a.approvalStatus!=2 && !a.approvalStatus!=3">待审批</span> {{a.approvalStatus==2||a.approvalStatus==1?a.approvalNickName:''}}</span>
                  </div>

                </template>
              </div>
              <div class="control-box" v-if="getIsApproval(l.approvals)==0">

                <div class="info" v-if="getIsApproval(l.approvals)==0">
<!--                  <span>订单总价：¥{{getFloat2(l.activityAmount)}}</span>-->
<!--                  <span v-if="selectDots.length>0">剩余预算：¥{{getFloat2(priceAll-l.activityAmount)}}</span>-->
<!--                  <span v-if="selectDots.length==0">请选择所占费用</span>-->
                </div>

<!--                <div class="info" v-if="getIsApproval(l.approvals)!=0">-->
<!--                  <span>订单总价：¥{{getFloat2(l.activityAmount)}}</span>-->
<!--                  <template v-if="l.projects">-->
<!--                    <span v-if="l.projects.regionName"  >剩余预算：¥{{getFloat2(getBudgetLeft(l.projects)-l.activityAmount)}}</span>-->
<!--                  </template>-->
<!--                  &lt;!&ndash; <span class="sm">-->
<!--                    <abbr>使用预算：</abbr>-->
<!--                    <abbr>-->
<!--                      测试：¥320.00<br>-->
<!--                      测试3：¥320.00<br>-->
<!--                    </abbr>-->
<!--                  </span> &ndash;&gt;-->
<!--                </div>-->

                <div class="btns-box" v-if="getIsApproval(l.approvals)==0">
                  <div @click.stop="isUpState=1;examineId=l.activityId;upData.isShow=true;upData.title='正在进行通过审批操作'">审批</div>
                  <div @click.stop="isUpState=2;examineId=l.activityId;upData.isShow=true;upData.title='正在进行拒绝审批操作'">拒绝</div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <Upbox @close="upData.isShow=false" @ok="sendExamine()" v-if="upData.isShow" :type="'tips'" :data="{back:upData.back,ok:upData.ok,title:upData.title,desc:upData.desc}"></Upbox>
    <Upbox @close="enterUp=false" v-if="enterUp" :type="'tips'" :data="{state:'ok',title:'提交成功！',desc:'请耐心等待<br>区域经理，总监以及财务分析团队的审批!',ok:'查看我的申请',back:'返回'}"></Upbox>
    <Upbox @close="sendError=''"  v-if="sendError" :type="'tips'" :data="{back:'确认',title:sendError}"></Upbox>
    <Upbox @close="setExamineIsShow=false" v-if="setExamineIsShow" :type="'text'" @ok="(e)=>{setExamine(e)}" :data="{title:'请填写拒批原因',ok:'提交',back:'返回'}"></Upbox>
    <Upbox @close="setApprovalDesShow=''" v-if="setApprovalDesShow" :type="'tips'" :data="{state:'error',title:'申请未通过',back:'返回',desc:setApprovalDesShow}"></Upbox>
    <Footer v-model="user" :id="5"></Footer>
  </div>
</template>
<script>

import Footer from '../../../components/Footer.vue'
import Upbox from '../../../components/Upbox.vue'
export default {
  name: 'Home',
   data: function () {
    return {
      setApprovalDesShow:'',
      baseUrl:process.env.VUE_APP_BASE_URL,
      isSelect:'activity',
      orderClick:false,
      orderState:'reviewed',
      enterUp:false,
      time:'',
      treeList:[],
      list:[],
      isShow:'off',
      tableShow:false,
      demoKey:'demo-' + +new Date() + ((Math.random() * 1000).toFixed(0) + ''),
      selectDots:[],
      priceAll:0,
      examineId:'',
      isUpState:2,
      sendError:'',
      approvalsCache:{
        regional_manager:'大区经理',
        regional_marketing_manager:'区域市场经理',
        sales_director:'总监',
        financial_staff:'财务分析团队',
        product_manager:'产品经理',
        medical_regional_assistant:'医用大区助理',
        sale_manager:'销售经理',
        dept_first_trial:'部门初审',
        sales_empowerment_manager:'销售赋能经理',
        channel_manager:'渠道经理'
      },
      upData:{
        isShow:false,
        ok:'继续',
        back:'返回',
        title:'',
        desc:'该操作不可撤销,请确认！'
      },
      user:{},
      pageNum:1,
      approvalDes:'',
      setExamineIsShow:false
    }
  },
  components: {Footer,Upbox},
  watch:{
    isShow(){
      this.clearDots()
    }
  },
  methods:{
    getIsApproval(a){
      let data=0
      a.map(r=>{
        if(r.approvalRole==this.user.roles[0]){
          data=r.approvalStatus
        }
      })
      return data
    },
    getBudgetLeft(projects){
      let price=0
      if(projects.project){
        projects.project.map(p=>{
          price+=p.budgetLeft
        })
      }
      return price
    },
    setExamine(e){
      this.approvalDes = e
      this.enterExamine()
    },
    enterExamine(){
      let vm=this
      let examine={
        billId:vm.examineId,
        approvalStatus:vm.isUpState,
        projectIds:vm.selectDots,
        approvalDes:vm.approvalDes
      }
      this.$axios.put('/business/activity/approve', examine).then(function (res) {
        vm.sendError=res.msg
        vm.upData.isShow=false
        vm.setExamineIsShow = false
        vm.list=[]
        vm.pageNum=1
        vm.getList()
        vm.getTreeList()
        vm.clearDots()
      })
    },
    sendExamine(){
      let vm=this
      if(vm.isUpState==2){
        vm.setExamineIsShow=true
        return
      }
      vm.enterExamine()
    },
    linkUrl(url){
      window.open(this.baseUrl+url)
    },
    changeDemo(){
      this.demoKey='demo-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
    },
    getDotData(data){
      // if(data){
      //   let d = data.split('.')
      //   return '.'+d[d.length-1]
      // }else{
      //   return false
      // }
      return data
    },
    checkDots(){
      this.selectDots=[]
      this.priceAll=0
      this.treeList.map(t=>{
        for(var i in t.data){
            t.data[i].map((a,c)=>{
              if(a.dot){
                this.selectDots.push(a.projectId)
                this.priceAll+=(a.budget-a.budgetApply)
              }
            })
        }
      })
    },
    clearDots(){
      this.treeList.map(t=>{
        for(var i in t.data){
            t.data[i].map((a,c)=>{
              a.dot=false
            })
        }
      })
      this.checkDots()
    },
    getFloat2: function(x){
      if (x != '.'){
        var f = Math.round(x * 100) / 100;
        var s = f.toString();
        var rs = s.indexOf('.');
        if (rs <= 0) {
          rs = s.length;
          s += '.';
        }
        while (s.length <= rs + 2) {
          s += '0';
        }
        return s;
      }else{
        return '0.00';
      }
    },
    linkShop:function(){
      this.$router.push({path:'/shop'})
    },
    getTreeList(){
      // /business/project/treeList
      var vm = this
      this.$axios.get('/business/project/treeList', {}).then(function (res) {
        // console.log(res.data)
        vm.treeList=res.data
      })
    },
    getList(){
      var vm = this
      this.$axios.get('/business/activity/approveList', {params: {
        pageNum: this.pageNum,
        pageSize: 10,
      }}).then(function (res) {
        // vm.list=res.rows
        if(vm.pageNum<=Math.ceil(res.total/10)){
            res.rows.map(r=>{
              vm.list.push(r)
            })
            vm.pageNum++
        }
      })
    }
  },
  mounted(){
    this.getTreeList()
    this.getList()

    // 滚动条高度计算
    var scdom = document.getElementById("scdom");   // 获取滚动条的dom
    scdom.onscroll = () => {
      // 获取距离顶部的距离
      var scrollTop = scdom.scrollTop;
      // 获取可视区的高度
      var windowHeight = scdom.clientHeight;
      // 获取滚动条的总高度
      var scrollHeight = scdom.scrollHeight;
      // console.log("距顶部"+(scrollTop+windowHeight)+"可视区高度"+windowHeight+"滚动条总高度"+scrollHeight);

      if(scrollTop+windowHeight>=scrollHeight){
        // this.shows = false
        // console.log(13123)
        this.getList()
      }
    }
  }

}
</script>
