<template>
    <div class=" up-examine weekly">
      <div class="weekly-box region" v-if="!regionIn">
<!--        @click="regionIn=true"-->
        <div class="top-back-btn" @click="close()" >返回</div>
        <div :class="'weekly-box-line state-'+(parseFloat(w.status)+1)" v-for="(w,i) in weeksGroupData" v-bind:key="'weeksGroup_'+i" @click="
        w.allNum==0?upError='暂无周报，无法查看':getWeeksGroupWeekType(w.year,w.week,i)
        " >
          <div class="weekly-box-line-left">
            <span></span>
            <abbr v-if="w.status==0">进行中</abbr>
            <abbr v-if="w.status==1">全部完成</abbr>
            <abbr v-if="w.status==2">部分完成</abbr>
            <abbr v-if="w.status==3">未完成</abbr>


          </div>
          <div class="weekly-box-line-center">
            <span>{{w.year}}年第{{ w.week }}周</span>
            <abbr>{{ w.beginTime.split('T')[0] }} - {{ w.endTime.split('T')[0] }}</abbr>
            <div class="progress">
              <div class="progress-box">
                <div class="progress" :style="'width:'+(w.submitNum/w.allNum*100)+'%'"></div>
              </div>
              <abbr>{{w.submitNum }}/{{ w.allNum }}</abbr>
            </div>
          </div>
          <img src="/img/weekly/icon-5.png" class="weekly-box-line-right">
        </div>
        <div class="add-more n-bottom" @click="getWeeksGroup()" v-if="weeksGroupData.length!=0 && weeksGroup.list.total>weeksGroupData.length">加载更多</div>
<!--        <div class="add-more back" @click="close()">返回</div>-->
      </div>

<!--      详情列表-->
      <div class="weekly-box region" v-if="regionIn&!weeklyListShow">

        <div class="info-top">
          <div>
            <img src="/img/weekly/icon-8.png">
            <div>
              <span>{{ weeksGroupData[weeksGroupWeekShowId].year }}年第{{ weeksGroupData[weeksGroupWeekShowId].week }}周 周报</span>
              <span>{{ weeksGroupData[weeksGroupWeekShowId].beginTime.split('T')[0] }} - {{ weeksGroupData[weeksGroupWeekShowId].endTime.split('T')[0] }}</span>
            </div>
          </div>
          <div>
            <img src="/img/weekly/icon-9.png">
            <span>状态</span>
            <abbr v-if="weeksGroupData[weeksGroupWeekShowId].status==0">进行中</abbr>
            <abbr v-if="weeksGroupData[weeksGroupWeekShowId].status==1">全部完成</abbr>
            <abbr v-if="weeksGroupData[weeksGroupWeekShowId].status==2">部分完成</abbr>
            <abbr v-if="weeksGroupData[weeksGroupWeekShowId].status==3">未完成</abbr>
          </div>
          <div>
            <img src="/img/weekly/icon-10.png">
            <span>进度</span>
            <div class="progress">
              <div class="progress-box">
                <div class="progress-box-in" :style="'width:'+(weeksGroupData[weeksGroupWeekShowId].submitNum/weeksGroupData[weeksGroupWeekShowId].allNum*100)+'%'"></div>
              </div>
              <div class="progress-fonts">{{weeksGroupData[weeksGroupWeekShowId].submitNum }}/{{ weeksGroupData[weeksGroupWeekShowId].allNum }}</div>
            </div>
          </div>
        </div>
        <div :class="'weekly-box-line state-'+(parseFloat(w.weeklyStatus))" v-for="(w,i) in weeksGroupWeekTypeData" v-bind:key="i" @click="weeklyId=w.weeklyId;weeklyListShow=true">
          <div class="weekly-box-line-left">
            <span></span>
            <abbr v-if="w.weeklyStatus==0">未填报</abbr>
            <abbr v-if="w.weeklyStatus==2">完成</abbr>
            <abbr v-if="w.weeklyStatus==3">超期完成</abbr>
          </div>
          <div class="weekly-box-line-center">
            <span>{{ w.nickName }}  {{ w.regionName }}</span>
            <abbr :class="{'on':w.commentUnReadNum!=0}" v-if="w.commentUnReadNum!=0 || w.commentNum!=0">
              <p :class="{'dot':true,'on':w.commentUnReadNum!=0}"></p>
              <p>{{w.commentUnReadNum?w.commentUnReadNum:w.commentNum}}条留言</p>
            </abbr>

          </div>
          <img src="/img/weekly/icon-5.png" class="weekly-box-line-right">
        </div>
        <div class="add-more back" @click="weeksGroupWeekTypeData=[];regionIn=false">返回</div>
      </div>

      <Footer v-model="user" :id="3"></Footer>



      <WeeklyShow v-if="weeklyListShow"  :weeklyId="weeklyId"  @back="weeklyListShow=false"></WeeklyShow>
      <Upbox @close="upError=''"  v-if="upError" :type="'tips'" :data="{back:'返回',state:upState,title:'操作失败！',desc:upError}"></Upbox>
    </div>

</template>
<script>
import Footer from '../../components/Footer.vue'
import WeeklyShow from '../../components/weekly/WeeklyShow.vue'
import Upbox from '../../components/Upbox.vue'
export default {
  name: 'Files',
  props:{
    weeklyTypes:String,
    weeklyType:String,
    commissioner:Boolean
  },
  data: function () {
    return {
      user:{},
      regionIn:false,
      weeklyListShow:false,
      weeklyId:0,
      upError:'',
      upState:'error',
      weeklyListPages:0,
      weeksGroupData:[],
      weeksGroup:{
        list:{
          rows:[]
        }
      },
      weeksGroupWeekTypeData:[],
      weeksGroupWeekType:{},
      weeksGroupWeekShowId:0
    }
  },
  components: { Footer,WeeklyShow,Upbox},
  watch:{
    user(){
      this.getWeeksGroup()
    }
  },
  methods:{

    change(e){
      // this.name=''
      // this.minPath=e

    },
    close(){
      this.$emit('close')
    },
    getWeeksGroup(){
      let vm =this
      vm.weeklyListPages++
      vm.$axios.get('/business/weekly/list/group/data?pageSize=10&pageNum='+vm.weeklyListPages+'&weeklyTypes='+ vm.weeklyTypes, null,{
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(function (res) {
        console.log(res)
        vm.weeksGroup = res.data
        //
        res.data.list.rows.map(r=>{
          vm.weeksGroupData.push(r)
        })
      })
    },
    getCommissionerData(year,week){
      let vm =this
      vm.weeklyListPages++
      vm.$axios.get('/business/weekly/list/group/type?year='+year+'&week='+week, null,{
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(function (res) {
        // console.log(res)
        vm.commissionerData = res.data
      })
    },
    getWeeksGroupWeekType(year,week,id) {
      let vm =this

      vm.weeksGroupWeekShowId = id
      vm.$axios.get('/business/weekly/list/by/week_type?year='+year+'&week='+week+'&weeklyType='+ vm.weeklyType, null,{
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(function (res) {
        console.log(res)
        vm.weeksGroupWeekType = res.data
        vm.regionIn=true
        vm.weeksGroupWeekTypeData=[]
        res.data.list.map(r=>{
          vm.weeksGroupWeekTypeData.push(r)
        })
      })

    }
    },
  mounted(){

    // var vm = this
    // this.getFiles()
  }



}
</script>
