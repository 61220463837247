<template>
  <div class="files folder">

    <!-- 文件夹 -->
    <template v-for="(f,i) in files">
      <div  class="files-inline" v-bind:key="i" v-if="setFiles(f.key)[0]" @click="openFolder(setFiles(f.key)[2])">
        <div class="fonts-box">
          <div class="img-box">
            <img src="/img/files/icon-8.png">
          </div>
          <span>{{setFiles(f.key)[1]}}</span>
        </div>
        <div class="open-folder">打开文件夹</div>
      </div>
    </template>

    <!-- 文件 -->
    <template v-for="(f,i) in files">
      <div  class="files-inline" v-bind:key="i" v-if="!setFiles(f.key)[0]">
        <div class="fonts-box">
          <div class="img-box">
            <img :src="'/img/files/'+getDot(setFiles(f.key)[1])+'.png'" :onerror="errorImg">
          </div>
          <span>{{setFiles(f.key)[1]}}<br><abbr>{{(f.size/1000/1000).toFixed(2)}}MB</abbr></span>
        </div>
        <div class="open-folder no-bg">
          <!-- v-if="canOpen.indexOf(getDot(setFiles(f.key)[1])) != -1" -->
          
          <span @click="openFile(setFiles(f.key)[2])" v-if="canOpen.indexOf(getDot(setFiles(f.key)[1])) != -1" >打开</span>
          <!-- 判断不同设备文件是否可以被直接下载 -->
          <!-- v-if="!isPC()&&isIos()&&isWechat()" -->
          <!-- v-if="isPC()||!isIos()||!isWechat()" -->
          <span  @click="shareFile(setFiles(f.key)[2])">复制链接</span>
          <span v-if="isPC()" @click="openFile(setFiles(f.key)[2],'down')">下载文件</span>
        </div>
      </div>
    </template>
    <PDF v-if="pdf" :pdf="pdf" @back="pdf=''" ></PDF>
    <ImageViewer v-if="images" :images="images" @back="images=''" ></ImageViewer>
    <Mp4Viewer v-if="mp4" :mp4="mp4" @back="mp4=''" ></Mp4Viewer>
    <OfficeViewer v-if="office" :office="office" @back="office=''" ></OfficeViewer>

    <div class="copy-box" v-if="message">
      <div class="copy-show">
        <div class="copy-title">分享链接<br><span>{{message}}</span></div>
        <div class="copy-btn">
          <span  @click="message=''">返回</span>
          <span @click="doCopy()">复制</span>
        </div>
      </div>
    </div>

    <div class="copy-box" v-if="copyOk" @click="copyOk=false">
      <div class="copy-show">
        <div class="copy-title">复制成功</div>
        <div class="copy-btn" >
          <span  >返回</span>
        </div>
      </div>
    </div>
    <div class="file-loading" v-if="loadingShow">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: rgb(246, 246, 254); display: block;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="50" cy="50" r="32" stroke-width="8" stroke="rgba(162, 126, 153, 0.7788709677419354)" stroke-dasharray="50.26548245743669 50.26548245743669" fill="none" stroke-linecap="round">
          <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
        </circle>
        <circle cx="50" cy="50" r="23" stroke-width="8" stroke="#955388" stroke-dasharray="36.12831551628262 36.12831551628262" stroke-dashoffset="36.12831551628262" fill="none" stroke-linecap="round">
          <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 50;-360 50 50"></animateTransform>
        </circle>
      </svg>
    </div>
  </div>
</template>

<script>
import PDF from '../../components/Files/PDF.vue'
import ImageViewer from '../../components/Files/Image.vue'
import Mp4Viewer from '../../components/Files/Mp4.vue'
import OfficeViewer from '../../components/Files/Office.vue'
export default {
  name: 'Folder',
  data: function () {
    return {
      path:'',
      files:[],
      pdf:'',
      errorImg:"this.src='/img/files/other.png'",
      images:'',
      mp4:'',
      canOpen:[
        'pdf','jpg','png','jpeg','gif','mp4','doc','docx','xls','xlsx','ppt','pptx'
      ],
      message:'',
      copyOk:false,
      dotName:this.user.roles[0]!='distributor'?'sales/':'dealer/',
      office:'',
      loadingShow:true
    }
  },
  // user.roles[0]!='distributor'
  components: {PDF,ImageViewer,Mp4Viewer,OfficeViewer},
  props: {
    minPath:String,
    name:String,
    back:String,
    user:Object
  },
  watch:{
    name(){
      this.getFiles()
    },
    back(){
      
      if(!this.path||(this.path.split('/').length==2 && ((this.path.split('/')[0].indexOf('医用')!=-1 || this.path.split('/')[0].indexOf('家用')!=-1) && this.user.roles[0].indexOf('distributor')!=-1) )){
          this.$emit('back');
      }else{
        // console.log(this.path.split('/'))
        let path = this.path.split('/')
        this.path=''
        path.map((p,i)=>{
          if((i+2)<path.length){
            this.path+=p+'/'
          }
        })
        // console.log(this.path)
        this.getFiles()
      }
    }
  },
  methods:{
    change(e){
      this.$emit('change',e);
    },
    doCopy() {
      let vm = this
      this.$copyText(this.message).then(function (e) {
        // alert(1)
        // console.log(e)
        vm.copyOk=true
        vm.message=''
      }, function (e) {
        // alert(2)
        // console.log(e)
      })
    },
    isWechat(){
      let wx = navigator.userAgent.toLowerCase();
      if (wx.match(/MicroMessenger/i) == "micromessenger") {
          return true
      }
      return false
    },
    isIos(){
      var u = navigator.userAgent;
      return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    },
    isPC(){  
      var userAgentInfo = navigator.userAgent;
      var Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod");  
      var flag = true;  
      for (var v = 0; v < Agents.length; v++) {  
          if (userAgentInfo.indexOf(Agents[v]) > 0) { flag = false; break; }  
      }  
      return flag;  
    },
    shareFile(file){
      let vm = this
      // 重组file路径
      if(file.split('/').length==1){
        file = this.dotName + this.minPath + this.path +file 
      }
      // console.log(file)
      // 获取授权
      this.$axios.post('/downloads/share', 
      {
        "object": file
      }).then(function (res) {
        vm.message='http://'+window.location.host+'/share?type=share&id='+res.data.shareId
        // vm.doCopy()
      })
    },
    openFile(file,state){
        let vm = this
        // 重组file路径
        if(file.split('/').length==1){
          file = this.dotName + this.minPath + this.path +file 
        }
        // console.log(file)
        // 获取授权
        this.$axios.post('/downloads/sign', 
        {
          "object": file
        }).then( function (res) {
          
          if(state!='down'){

          
            // 打开文件
            let dot = vm.getDot(file)
            if(dot=='pdf'){
              let u = navigator.userAgent;
              let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);     //判断是否是 iOS终端
              if(isIOS){
                window.location.href =  res.data.objectDownloadUrl
              }else{
                vm.pdf=res.data.objectUrl
              }
              return
            }
            if(dot=='jpg'||dot=='png'||dot=='jpeg'||dot=='gif'){
              vm.images=res.data.objectUrl
              return
            }
            if(dot=='mp4'){
              vm.mp4=res.data.objectUrl
              return
            }
            if(dot=='doc'||dot=='docx'||dot=='xls'||dot=='xlsx'||dot=='ppt'||dot=='pptx'){
              vm.office=res.data.objectUrl
              return
            }
            // if(dot=='docx'){
            //   window.open(res.data.objectUrl)
            //   return
            // }
          }
          // alert(1)
          setTimeout(()=>{
            // window.open()
            window.location.href=res.data.objectDownloadUrl
          },50)
          
          // if(vm.isPC){
          //   window.open(res.objectDownloadUrl)
          // }else{
          //   window.open(res.objectDownloadUrl)
          // }
        })
    },
   
    openFolder(folder){
     
      // console.log(folder.split(this.dotName))

      let f = folder.split(this.dotName)
      if(f.length==2){
        this.change(f[1].split('/')[0]+'/')
        setTimeout(()=>{
          let cf = f[1].split('/')
          // console.log(cf)
          this.path=''
          cf.map((c,i) =>{
              // console.log(f[1].split('/')[0])
              if(c!=''&&c!=f[1].split('/')[0]){
                this.path+=c+'/'
              }
          })
          this.getFiles()
        },100)
        
      }else{
        this.path+=folder
        this.getFiles()
      }
      
      
    },
    getDot(file){
      let dot = file.split('.')
      return dot[dot.length-1].toLowerCase()
    },
    getFiles(){
      let vm = this
      vm.loadingShow=true
      this.$axios.post('/downloads/sales', 
      {
        "path": this.name?this.dotName:this.dotName+this.minPath+this.path,
        "name": this.name,
        "loop": this.name?true:false
      }).then(function (res) {
        vm.files=res.data
        setTimeout(()=>{
          vm.loadingShow=false
        },200)
        if(vm.chenckKey()){
          vm.openFolder(vm.files[0].key)
        }
      })
    },
    chenckKey(){
      if(this.files.length!=0 && this.files[0].key.split('/').length==4 && (this.files[0].name.indexOf("医用") != -1 || this.files[0].name.indexOf("家用") != -1 ) && this.user.roles[0].indexOf('distributor')!=-1){
        return true
      }
      return false
    },
    setFiles(d){
        let vd = d.split('/')
        if(vd[vd.length-1]==''){
          return [true,vd[vd.length-2],d]
        }else{
          return [false,vd[vd.length-1],d]
        }
    }
  },
  mounted(){
    // var vm = this
    this.getFiles()

    // this.dotName=this.user.roles[0]!='distributor'?'sales/':'dealer/'
  }
}
</script>
