<template>
  <div class="news content">
    <div class="back" @click="back()">返回</div>
    <div class="center">
      <div class="title">{{list.title}}</div>
      <div class="time">{{list.createTime}}</div>
      <div class="text-body">
        <div v-html="list.content" class="ql-container"></div>
        <div class="watermark-box">
          <span v-for="i in 100" v-bind:key="i">{{user.user.phonenumber}}</span>
        </div>
      </div>
      <div class="watermark-right">
        <span >{{user.user.phonenumber}}</span>
      </div>
      <div class="watermark-top">
        <span >{{user.user.phonenumber}}</span>
      </div>
      <div class="watermark-top-2">
        <span >{{user.user.phonenumber}}</span>
      </div>
      <div class="watermark-left">
        <span v-for="i in 5" v-bind:key="i">{{user.user.phonenumber}}</span>
      </div>
      <div class="return" @click="back()">返回</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'News',
  data: function () {
    return {
    }
  },
  props: {
    id: Number,
    user:Object,
    list:Object
  },
  methods:{
    back(){
      this.$emit('back');
     }
  },
  mounted(){
    // var vm = this
    // this.$axios.get('/getInfo', {}).then(function (res) {
    //   console.log(res)
    //   if(res.code!=200){
    //     window.location.href='/login'
    //     return
    //   }
    //   vm.user=res
    //   vm.$emit('input',res);
    // })
  }
}
</script>
