<template>
  <!-- <input type="file" @change="upFlie($event)" class="input"> -->
  <div class="input-box upfile">
    <div class="upfile-box" v-if="fileName==''">
      <img src="/img/activity/icon-1.png">
      <span>{{state}}</span>
    </div>
    <abbr v-if="fileName!=''">{{fileName}}</abbr>
    <input type="file" @change="upFlie($event)" class="input">
  </div>
</template>

<script>
export default {
  name: 'Upbox',
  data: function () {
    return {
      state:'上传文件',
      fileName:""
    }
  },
  props: {
    type: String,
    data:Object,
    express:Array
  },
  methods:{
    close(){
        this.$emit('close');
    },
    ok(){
      this.$emit('ok');
    },
    linkUrl(o){
      window.open('https://www.baidu.com/s?wd='+o)
    },
    upFlie(e){
      // 文件上传
      let vm=this
      let file = e.target.files[0]; //获取文件
      // console.log(file.name)
      var formData = new FormData();
      formData.append('file',file);
      vm.state='上传中……';
      this.$axios.post('/common/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
      }).then(function (res) {
        // vm.$emit('state','上传成功');
        // vm.state='上传成功';
        // vm.state=file.name;
        vm.fileName=file.name;
        vm.$emit('input',res.fileName+','+file.name);
      }).catch(function (error) {
        vm.state='上传失败';
        // vm.$emit('state','上传失败');
        return
      });
    }
  }
}
</script>
