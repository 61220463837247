<template>
  <div class="activity">
    <div class="nav-bar">
      <div class="select-box" >
        <div :class="isSelect=='activity'?'on':''" @click="isSelect='activity'">申请详情</div>
        <div :class="isSelect=='my'?'on':''" @click="isSelect='my'">我的申请</div>
      </div>
    </div>
    <div class="activity-conetnt" v-if="isSelect=='activity'">
      <div class="activity-center">
        <div class="activity-in">
          <span>项目名称</span>
          <div class="input-box">
            <input type="text" class="input" v-model="activityName" placeholder="请填写项目名称">
          </div>
        </div>
        <div class="activity-in">
          <span>项目开始时间</span>
          <div class="input-box time">
            <div class="input-show" >{{!activityBeginTime?'请选择项目开始时间':''}}</div>
            <!-- <div class="input-show">{{activityBeginTime?resizeTime(activityBeginTime):'请选择项目开始时间'}}</div>
            <div class="icon">
              <img src="/img/shop/icon-3.png">
            </div> -->
            <input type="datetime-local" @blur="checkTime()"  class="input time " style="opacity: 1;width:440px;"  v-model="activityBeginTime" >
          </div>
        </div>
        <div class="activity-in">
          <span>项目结束时间</span>
          <div class="input-box time">
            <div class="input-show" >{{!activityBeginTime?'选择开始时间后可选':''}}</div>
            <!-- <div class="input-show">{{activityBeginTime?(activityEndTime?resizeTime(activityEndTime):'请选择项目结束时间'):'选择开始时间后可选'}}</div> -->
            <!-- <div class="icon">
              <img src="/img/shop/icon-3.png">
            </div> -->
            <input v-if="activityBeginTime" @blur="checkTime()" type="datetime-local" style="opacity: 1;width:440px" class="input time " :min="activityBeginTime" v-model="activityEndTime" >
          </div>
        </div>
        <div class="activity-in">
          <span>项目地址</span>
          <div class="input-box">
            <input type="text" class="input" v-model="activityAddr" placeholder="请填写项目地址">
          </div>
        </div>
        <div class="activity-in">
          <span>申请金额</span>
          <div class="input-box">
            <input type="number" class="input" v-model="activityAmount" placeholder="请填写申请金额">
          </div>
        </div>
        <div class="activity-in">
          <span>申请原因</span>
          <div class="input-box textarea">
            <textarea v-model="reasonDes" placeholder="请填写具体原因"></textarea>
          </div>
        </div>

        <div class="activity-center">
          ——会议活动请填写以下信息——
        </div>

        <div class="activity-in">
          <span>会议邀请函</span>

          <Upfile v-model="fileInvite"></Upfile>
        </div>

        <div class="activity-in">
          <span>会议日程</span>
          <Upfile v-model="fileSchedule"></Upfile>
        </div>

        <div class="activity-in">
          <span>协议/合同</span>
          <Upfile v-model="fileContract"></Upfile>
        </div>

        <div class="activity-in">
          <span>其他相关文件</span>
          <Upfile v-model="fileOther"></Upfile>
        </div>
        <div class="activity-in center">
          <abbr>
            是否涉及专业人士/组织支付（如讲课费，展位，卫星会等等）
            <div class="dot-line">
              <div class="dot-box" @click="isHcp=1">
                <div class="dots" >
                  <span v-if="isHcp"></span>
                </div>
                是，涉及
              </div>
              <div class="dot-box" @click="isHcp=0">
                <div class="dots" >
                  <span v-if="!isHcp"></span>
                </div>
                否，不涉及
              </div>
            </div>
          </abbr>

        </div>
        <div class="activity-center">
          ——其他费用请填写以下信息——
        </div>
        <div class="activity-in">
          <span>申请表</span>
          <Upfile v-model="fileApplicationForm"></Upfile>
        </div>
        <div class="activity-in">
          <span>图片说明 </span>
          <Upfile v-model="fileCaption"></Upfile>
        </div>
        <div class="activity-in">
          <span>协议/合同</span>
          <Upfile v-model="fileDocuments"></Upfile>
        </div>

        <div class="activity-enter" @click="createActive()">提交</div>
      </div>
    </div>
    <div class="activity-conetnt" v-if="isSelect=='my'">
      <center v-if="list.length==0">
          <h2 style="color:#ccc">
            暂无申请
          </h2>
      </center>
      <div class="activity-center">
        <div :class="isShow==i?'my-in on':'my-in'" v-for="(l,i) in list" v-bind:key="i" @click="isShow=i">
          <div class="info-box">
            <div class="info">
              <div class="title-box">
                <div>{{l.activityName}}预算申请</div>
                <div v-if="l.approvalStatus==0" >待审核</div>
                <div class="on" v-if="l.approvalStatus==1">已审核</div>
                <div class="on" v-if="l.approvalStatus==2">已拒绝</div>
              </div>
              <img src="/img/order/icon-1.png" class="icon">
            </div>
          </div>
          <div class="list-box">
              <div class="list-in">
                <span>申请人</span>
                <div class="list-info">
                  <div class="fonts">{{l.user.nickName}}</div>
                </div>
              </div>
              <div class="list-in">
                <span>项目名称</span>
                <div class="list-info">
                  <div class="fonts">{{l.activityName}}</div>
                </div>
              </div>
              <div class="list-in">
                <span>项目开始时间</span>
                <div class="list-info">
                  <div class="fonts">{{l.activityBeginTime}}</div>
                </div>
              </div>
              <div class="list-in">
                <span>项目结束时间</span>
                <div class="list-info">
                  <div class="fonts">{{l.activityEndTime}}</div>
                </div>
              </div>
              <div class="list-in">
                <span>项目地址</span>
                <div class="list-info">
                  <div class="fonts">{{l.activityAddr}}</div>
                </div>
              </div>

              <div class="list-in">
                <span>申请金额</span>
                <div class="list-info">
                  <div class="fonts">￥{{l.activityAmount}}</div>
                </div>
              </div>

              <div class="list-in" v-if="l.reasonDes">
                <span>申请原因</span>
                <div class="list-info">
                  <div class="fonts">{{l.reasonDes}}</div>
                </div>
              </div>
              <br/>
              <div class="activity-center" v-if="l.fileInvite||l.fileSchedule||l.fileContract||l.fileOther||l.isHcp==1">
                ——会议活动信息——
              </div>
              <div class="list-in" v-if="l.fileInvite">
                <span>会议邀请函</span>
                <div class="list-info">
                  <div class="fonts is-show">{{getDotData(l.fileNameInvite)}}</div>
                  <div class="show" @click="linkUrl(l.fileInvite)">查看</div>
                </div>
              </div>
              <div class="list-in" v-if="l.fileSchedule">
                <span>会议日程</span>
                <div class="list-info">
                  <div class="fonts is-show">{{getDotData(l.fileNameSchedule)}}</div>
                  <div class="show" @click="linkUrl(l.fileSchedule)">查看</div>
                </div>
              </div>
              <div class="list-in" v-if="l.fileContract">
                <span>协议/合同</span>
                <div class="list-info">
                  <div class="fonts is-show">{{getDotData(l.fileNameContract)}}</div>
                  <div class="show" @click="linkUrl(l.fileContract)">查看</div>
                </div>
              </div>

              <div class="list-in" v-if="l.fileOther">
                <span>其他指示文件</span>
                <div class="list-info">
                  <div class="fonts is-show">{{getDotData(l.fileNameOther)}}</div>
                  <div class="show" @click="linkUrl(l.fileOther)">查看</div>
                </div>
              </div>

              <div class="list-in center" v-if="l.isHcp==1">
                <abbr >涉及专业人士/组织支付（如讲课费，展位，卫星会等等）</abbr>
              </div>

              <div class="activity-center" v-if="l.fileApplicationForm||l.fileCaption||l.fileDocuments">
                ——其他费用信息——
              </div>

              <div class="list-in" v-if="l.fileApplicationForm">
                <span>申请表</span>
                <div class="list-info">
                  <div class="fonts is-show">{{getDotData(l.fileApplicationFormTitle)}}</div>
                  <div class="show" @click="linkUrl(l.fileApplicationForm)">查看</div>
                </div>
              </div>

              <div class="list-in" v-if="l.fileCaption">
                <span>图片说明 </span>
                <div class="list-info">
                  <div class="fonts is-show">{{getDotData(l.fileCaptionTitle)}}</div>
                  <div class="show" @click="linkUrl(l.fileCaption)">查看</div>
                </div>
              </div>

              <div class="list-in" v-if="l.fileDocuments">
                <span>协议/合同</span>
                <div class="list-info">
                  <div class="fonts is-show">{{getDotData(l.fileDocumentsTitle)}}</div>
                  <div class="show" @click="linkUrl(l.fileDocuments)">查看</div>
                </div>
              </div>

              <div class="list-in">
                <span>申请时间</span>
                <div class="list-info">
                  <div class="fonts">{{l.createTime}}</div>
                </div>
              </div>
<!--              <template v-if="l.projects">-->
<!--                <div class="state-box" v-if="l.projects.regionName">-->
<!--                  <div class="state-title">-->
<!--                    所占费用：-->
<!--                  </div>-->
<!--                  <div class="state-info">-->
<!--                    <div>{{l.projects.regionName}}</div>-->
<!--                    <div>{{l.projects.projectType}}</div>-->
<!--                    <div>-->
<!--                      <span v-for="(p,i) in l.projects.project" v-bind:key="i + 'demo-' +new Date() + ((Math.random() * 1000).toFixed(0))">-->
<!--                        {{p.projectName}}(￥{{p.budgetUse}})-->
<!--                      </span>-->
<!--                    </div>-->
<!--                    &lt;!&ndash; <div>-->
<!--                      <span v-for="(p,i) in l.projects.project" v-bind:key="i + 'demo-' +new Date() + ((Math.random() * 1000).toFixed(0))">-->
<!--                        ￥{{getFloat2(p.budgetLeft-p.budgetUse)}}-->
<!--                      </span>-->
<!--                    </div> &ndash;&gt;-->
<!--                  </div>-->
<!--                </div>-->
<!--              </template>-->

              <div class="state-box">
                <template  v-for="(a,b) in l.approvals" >
                  <div class="state-in" v-bind:key="b">
                    <div class="confirm-box">
                      <img src="/img/order/icon-2.png" v-if="a.approvalStatus==1">
                      <img src="/img/order/icon-3.png" v-if="a.approvalStatus==2">
                    </div>
                    <span>{{approvalsCache[a.approvalRole]}} <span v-if="!a.approvalNickName">待审批</span> <abbr v-if="a.approvalRole!='dept_first_trial'">{{a.approvalNickName}}</abbr> <span class="confirm-up-info" v-if="a.approvalStatus==2 && a.approvalDes && a.approvalDes!=''" @click.stop="setApprovalDesShow=a.approvalDes">查看拒批原因</span></span>
                  </div>
                  <div class="state-in" v-bind:key="b" v-if="a.approvalRole=='sales_director'">
                    <div class="confirm-box">
                      <img src="/img/order/icon-2.png" v-if="a.approvalStatus==1">
                      <img src="/img/order/icon-3.png" v-if="a.approvalStatus==2">
                    </div>
                    <span>预算负责人 <span v-if="a.approvalStatus!=2 && !a.approvalStatus!=3">待审批</span> {{a.approvalStatus==2||a.approvalStatus==1?a.approvalNickName:''}}</span>
                  </div>

                </template>

              </div>

          </div>
        </div>
      </div>
    </div>

    <Upbox @close="enterUp=false" @ok="enterUp=false;isSelect='my'" v-if="enterUp" :type="'tips'" :data="{state:'ok',title:'提交成功！',desc:'请耐心等待<br>区域经理，总监以及财务分析团队的审批!',ok:'查看我的申请',back:'返回'}"></Upbox>
    <Upbox @close="errorData=''" v-if="errorData" :type="'tips'" :data="{state:'error',title:'提交失败！',desc:errorData,back:'返回'}"></Upbox>
    <Upbox @close="setApprovalDesShow=''" v-if="setApprovalDesShow" :type="'tips'" :data="{state:'error',title:'申请未通过',back:'返回',desc:setApprovalDesShow}"></Upbox>
    <Footer :id="5"></Footer>
  </div>
</template>
<script>

import Footer from '../components/Footer.vue'
import Upbox from '../components/Upbox.vue'
import Upfile from '../components/Upfile.vue'
export default {
  name: 'Home',
   data: function () {
    return {
      baseUrl:process.env.VUE_APP_BASE_URL,
      isSelect:'activity',
      orderClick:false,
      orderState:'reviewed',
      enterUp:false,
      errorData:'',
      list:[],
      approvalsCache:{
        regional_manager:'大区经理',
        regional_marketing_manager:'区域市场经理',
        sales_director:'总监',
        financial_staff:'财务分析团队',
        product_manager:'产品经理',
        medical_regional_assistant:'医用大区助理',
        sale_manager:'销售经理',
        dept_first_trial:'部门初审',
        sales_empowerment_manager:'销售赋能经理',
        channel_manager:'渠道经理'
      },
      isShow:0,
      activityName:'',
      activityBeginTime:'',
      activityEndTime:'',
      activityAddr:'',
      activityAmount:'',
      reasonDes:'',
      fileInvite:'',
      fileSchedule:'',
      fileContract:'',
      fileCompliance:'',
      fileOther:'',
      isHcp:0,
      fileApplicationForm:'',
      fileCaption:'',
      fileDocuments:'',
      setApprovalDesShow:false
    }
  },
  components: {Footer,Upbox,Upfile},
  methods:{
    checkTime(){
      if(this.activityEndTime){
        if(new Date(this.activityBeginTime)>new Date(this.activityEndTime)){
          this.activityEndTime=''
          this.errorData='项目结束时间不可提前与项目开始时间'
        }
      }

    },
    linkUrl(url){
      window.open(this.baseUrl+url)
    },
    linkShop:function(){
      this.$router.push({path:'/shop'})
    },
    resizeTime(t){
      if(t){
        if(t.split('.').length>1){
          t= t.split('.')[0]
          return t.split('T')[0]+' '+t.split('T')[1]
        }
        return t.split('T')[0]+' '+t.split('T')[1]+':00'
      }else{
        return ''
      }

    },
    getDotData(data){
      return data
      // if(data){
      //   let d = data.split('.')
      //   return '.'+d[d.length-1]
      // }else{
      //   return false
      // }

    },
    createActive(){
      let vm=this
      this.$axios.post('/business/activity', {
        activityName:this.activityName,
        activityBeginTime:this.resizeTime(this.activityBeginTime),
        activityEndTime:this.resizeTime(this.activityEndTime),
        activityAddr:this.activityAddr,
        activityAmount:this.activityAmount,
        reasonDes:this.reasonDes,

        fileInvite:this.fileInvite?this.fileInvite.split(',')[0]:"",
        fileNameInvite:this.fileInvite?this.fileInvite.split(',')[1]:"",

        fileSchedule:this.fileSchedule?this.fileSchedule.split(',')[0]:'',
        fileNameSchedule:this.fileSchedule?this.fileSchedule.split(',')[1]:'',

        fileContract:this.fileContract?this.fileContract.split(',')[0]:"",
        fileNameContract:this.fileContract?this.fileContract.split(',')[1]:"",

        fileCompliance:this.fileCompliance?this.fileCompliance.split(',')[0]:"",
        fileNameCompliance:this.fileCompliance?this.fileCompliance.split(',')[1]:"",

        fileOther:this.fileOther?this.fileOther.split(',')[0]:'',
        fileNameOther:this.fileOther?this.fileOther.split(',')[1]:'',
        isHcp:this.isHcp,

        fileApplicationForm:this.fileApplicationForm?this.fileApplicationForm.split(',')[0]:'',
        fileApplicationFormTitle:this.fileApplicationForm?this.fileApplicationForm.split(',')[1]:'',

        fileCaption:this.fileCaption?this.fileCaption.split(',')[0]:'',
        fileCaptionTitle:this.fileCaption?this.fileCaption.split(',')[1]:'',

        fileDocuments:this.fileDocuments?this.fileDocuments.split(',')[0]:'',
        fileDocumentsTitle:this.fileDocuments?this.fileDocuments.split(',')[1]:''
      }).then(function (res) {
        console.log(res)
        if(res.code!=200){
          vm.errorData=res.msg
        }else{
          vm.enterUp=true
          vm.getList()
        }
      })
    },
    getList(){
      var vm = this
      this.$axios.get('/business/activity/list', {}).then(function (res) {
        // console.log(res)
        vm.list=res.rows
      })
    }
  },
  mounted(){
    this.getList()
  }

}
</script>
