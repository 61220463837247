import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Shop from '../views/Shop.vue'
import Order from '../views/Order.vue'
import ExamineOrder from '../views/Examine/Order.vue'
import Activity from '../views/Activity.vue'
import Files from '../views/Files.vue'
import ExamineManagerActivity from '../views/Examine/Manager/Activity.vue'
import ExamineDirectorActivity from '../views/Examine/Director/Activity.vue'
import Share from '../views/Share.vue'
import TrackOrder from '../views/Track/Order.vue'
import TrackActivity from '../views/Track/Activity.vue'
import News from '../views/News.vue'
import Airview from '../views/Airview.vue'
import Weekly from '../views/Weekly.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta:{title:"ResMed Belonging"},
    component: Home,
    
  },
  {
    path: '/weekly',
    name: 'Weekly',
    meta:{title:"ResMed Belonging"},
    component: Weekly,

  },

  {
    path: '/login',
    name: 'Login',
    meta:{title:"ResMed Belonging"},
    component: Login,
  },
  {
    path: '/shop',
    name: 'Shop',
    meta:{title:"订购系统"},
    component: Shop,
  },
  {
    path: '/order',
    name: 'Order',
    meta:{title:"订购系统"},
    component: Order,
  },
  {
    path: '/examine/order',
    name: 'ExamineOrder',
    meta:{title:"我的审批"},
    component: ExamineOrder,
  },
  {
    path: '/activity',
    name: 'Activity',
    meta:{title:"区域费用申请"},
    component: Activity,
  },
  {
    path: '/examine/manager/activity',
    name: 'ExamineManagerActivity',
    meta:{title:"活动审批"},
    component: ExamineManagerActivity,
  },
  {
    path: '/examine/director/activity',
    name: 'ExamineDirectorActivity',
    meta:{title:"活动审批"},
    component: ExamineDirectorActivity,
  },
  {
    path: '/files',
    name: 'Files',
    meta:{title:"下载系统"},
    component: Files,
  },
  {
    path: '/share',
    name: 'Share',
    meta:{title:"文件分享"},
    component: Share,
  },
  {
    path: '/track/order',
    name: 'TrackOrder',
    meta:{title:"订单追踪"},
    component: TrackOrder,
  },
  {
    path: '/track/activity',
    name: 'TrackActivity',
    meta:{title:"活动追踪"},
    component: TrackActivity,
  },
  {
    path: '/news',
    name: 'News',
    meta:{title:"信息发布"},
    component: News,
  },
  {
    path: '/airview',
    name: 'Airview',
    meta:{title:"Airview反馈"},
    component: Airview,
  },
  
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next)=>{
  if(to.meta.title){
    //判断是否有标题
    document.title = to.meta.title
  }
  //进入路由
  next()
})
export default router
