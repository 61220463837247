<template>
  <div class="viewers" >
      <div id="demo"></div>
      
      <!-- <iframe :src="pdf" frameborder="0" style="width:100%;height:100%;position: absolute;top: 0;left: 0;"></iframe> -->
      <div class="back" @click="back()">返回</div>
  </div>
</template>
<style>
iframe *{
  width: 100% !important;
}
</style>
<script>
import Pdfh5 from "pdfh5";
import "pdfh5/css/pdfh5.css";
export default {
  name: 'Folder',
  data: function () {
    return {

    }
  },
  props: {
    pdf:String
  },
  methods:{
     back(){
      //  pdf
      this.$emit('back');
     }
  },
  mounted(){
    let vm = this
    // window.open(this.pdf)
    // window.location.href=this.pdf
    new Pdfh5("#demo", {
      pdfurl: vm.pdf
    });
  }
}
</script>

