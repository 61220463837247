<template>
  <div class="up-examine">
    <div class="up-examine-content">
<!--      && user.roles[0]!='sales_empowerment_manager'-->
      <div class="up-examine-in" @click="linkExamine()"
          v-if="
            user.roles[0]!='financial_staff'
          "
        >
        <div >
          <img src="/img/examine/icon-1.png" class="icon-1">
          <span>订单审批</span>
        </div>
        <img src="/img/examine/icon-2.png" class="icon-2">
      </div>
      <div class="up-examine-in " @click="linkActivityExamine()"
        v-if="
          user.roles[0]!='product_manager'&&
          user.roles[0]!='medical_regional_assistant'
        "
      >
        <div>
          <img src="/img/examine/icon-3.png" class="icon-1">
          <span>活动审批</span>
        </div>
        <img src="/img/examine/icon-4.png" class="icon-2">
      </div>

      <!-- <div class="up-examine-in">
        <div>
          <img src="/img/examine/icon-5.png" class="icon-1">
          <span>门头审批</span>
        </div>
        <img src="/img/examine/icon-6.png" class="icon-2">
      </div> -->
    </div>

  </div>
</template>

<script>
export default {
  name: 'Upbox',
  data: function () {
    return {

    }
  },
  props: {
    type: String,
    data:Object,
    user:Object
  },
  methods:{
    close(){
        this.$emit('close');
    },
    ok(){
      this.$emit('ok');
    },
    linkExamine:function(){
      this.$router.push({path:'/examine/order'})
    },
    linkActivityExamine:function(){
      if(this.user.roles[0]=='sales_director'||this.user.roles[0]=='financial_staff'||this.user.roles[0]=='regional_marketing_manager'||this.user.roles[0]=='sales_empowerment_manager'){
        this.$router.push({path:'/examine/director/activity'})
      }else{
        this.$router.push({path:'/examine/manager/activity'})
      }

    },
  },
  mounted(){

  }
}
</script>
