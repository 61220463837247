<template>
  <div class="my-order">
    <div class="nav-bar">
      <div class="select-box" >
        <div :class="isSelect=='0'?'on':''" @click="isSelect='0'">日常资料</div>
        <div :class="isSelect=='2'?'on':''" @click="isSelect='2'">样品相关</div>
        <div :class="isSelect=='1'?'on':''" @click="isSelect='1'">动销物料</div>
      </div>
    </div>
    <div class="order-conetnt">
      <div class="left">
        <span :class="orderState=='0'?'on':''" @click="orderState='0'">待审批订单({{listNumber[isSelect].wait}})</span>
        <span :class="orderState=='1'?'on':''" @click="orderState='1'">已审批订单({{listNumber[isSelect].adopt}})</span>
        <span :class="orderState=='2'?'on':''" @click="orderState='2'">拒批订单({{listNumber[isSelect].refuse}})</span>
      </div>
      <div class="right">
        <center v-if="
          (listNumber[isSelect].wait==0&&orderState==0)||
          (listNumber[isSelect].adopt==0&&orderState==1)||
          (listNumber[isSelect].refuse==0&&orderState==2)
        ">
          <h2 style="color:#ccc">
            暂无订单
          </h2>
        </center>
        <template v-for="(l , i) in list"   >
          <div
              :class="isItem==i?'order-in-line on':'order-in-line'"
              v-bind:key="i"
              @click="isItem==i?isItem='off':isItem=i"
              v-if="
                l.orderType==isSelect&&
                l.approvalStatus==orderState
              "
          >
              <div class="order-title">
                <span>{{l.createTime}} 订单</span>
                <img src="/img/shop/icon-3.png" class="icon">
              </div>
              <div class="info">
                <div class="info-left">
                  <div class="order-number">订单号：{{l.orderId}}</div>
                  <div class="prize">¥{{l.orderAmount}}</div>
                </div>
                <div class="info-right" v-if="orderState=='1'&&l.delivers.length==0">
                  <span>待发货</span>
                </div>
                <div class="info-right" v-if="orderState=='1'&&l.delivers.length!=0">
                  <div  @click.stop="express=l.delivers;expShow=true">查看快递信息</div>
                </div>
              </div>
              <div class="order-list" v-if="secondCategoryHas(l.details,0)">
                <div class="buy-inline">
                  <div class="buy-left">品牌提示物</div>
                    <div class="buy-right">
                      <template v-for="(d,a) in l.details">
                        <div class="goods-line"  v-bind:key="a" v-if="d.secondCategory==0&&d.firstCategory==0">
                          <Goods :id="i" :isOrder="true" :data="reChange(d)"></Goods>
                        </div>
                      </template>
                      <div class="info">
                        <div>品牌提示物订单总价：¥{{secondCategoryAllPrice(l.details,0)}}</div>
                        <div>品牌提示物剩余预算：¥{{getFloat2(l.user.budgetGift-l.user.budgetGiftApply)}}</div>
                      </div>
                  </div>
                </div>
              </div>
              <div class="order-list" v-if="secondCategoryHas(l.details,1)">
                <div class="buy-inline">
                  <div class="buy-left">彩页</div>
                    <div class="buy-right">
                      <template v-for="(d,a) in l.details">
                        <div class="goods-line" v-bind:key="a" v-if="d.secondCategory==1&&d.firstCategory==0">
                          <Goods :id="i" :isOrder="true" :data="reChange(d)"></Goods>
                        </div>
                      </template>
                      <div class="info">
                        <div>彩页订单总价：¥{{secondCategoryAllPrice(l.details,1)}}</div>
                        <div>彩页剩余预算：¥{{getFloat2(l.user.budgetColorPage-l.user.budgetColorPageApply)}}</div>
                      </div>
                  </div>
                </div>
              </div>
              <div class="order-list" v-if="secondCategoryHas(l.details,2)">
                <div class="buy-inline">
                  <div class="buy-left">购物袋</div>
                    <div class="buy-right">
                      <template v-for="(d,a) in l.details">
                        <div class="goods-line"  v-bind:key="a" v-if="d.secondCategory==2&&d.firstCategory==0">
                          <Goods :id="i" :isOrder="true" :data="reChange(d)"></Goods>
                        </div>
                      </template>
                      <div class="info">
                        <div>购物袋订单总价：¥{{secondCategoryAllPrice(l.details,2)}}</div>
                        <div>购物袋剩余预算：¥{{getFloat2(l.user.budgetShoppingBag-l.user.budgetShoppingBagApply)}}</div>
                      </div>
                  </div>
                </div>
              </div>

              <div class="order-list" v-if="isSelect!=0">
                <div class="buy-inline">
                    <!-- <div class="buy-left">购物袋</div> -->
                    <div class="buy-right">
                      <template v-for="(d,a) in l.details">
                        <div class="goods-line"  v-bind:key="a" v-if="d.firstCategory==isSelect">
                          <Goods :id="i" :isOrder="true" :data="reChange(d)"></Goods>
                        </div>
                      </template>
                      <div class="info">
                        <div>{{isSelect==1?'动销物料':'样品相关'}}订单总价：¥{{firstCategoryAllPrice(l.details,isSelect)}}</div>
                        <div>
                          {{isSelect==1?'动销物料':'样品相关'}}剩余预算：¥
                          {{
                            isSelect==1?
                            getFloat2(l.user.budgetMovingSales-l.user.budgetMovingSalesApply):
                            getFloat2(l.user.budgetSample-l.user.budgetSampleApply)
                          }}
                        </div>
                      </div>
                  </div>
                </div>
              </div>
              <div class="state-box" >
                <div class="state-in" v-for="(a,b) in l.approvals" v-bind:key="b">
                  <div class="confirm-box">
                    <img src="/img/order/icon-2.png" v-if="a.approvalStatus==1">
                    <img src="/img/order/icon-3.png" v-if="a.approvalStatus==2">
                  </div>
                  <span v-if="l.approvals[0].approvalRole!='sales_director'" >{{approvalsCache[a.approvalRole]}}审批</span>
                  <span v-if="l.approvals[0].approvalRole=='sales_director'" >已审批</span>
                </div>
              </div>
            <!-- <div class="state-box">
              <div class="state-in">
                <div class="confirm-box">
                  <img src="/img/order/icon-2.png" v-if="checkApprovals(l.approvals,'regional_manager')==1">
                  <img src="/img/order/icon-3.png" v-if="checkApprovals(l.approvals,'regional_manager')==2">
                </div>
                <span>区域经理审批</span>
              </div>
              <div class="state-in">
                <div class="confirm-box">
                  <img src="/img/order/icon-2.png" v-if="checkApprovals(l.approvals,'product_manager')==1">
                  <img src="/img/order/icon-3.png" v-if="checkApprovals(l.approvals,'product_manager')==2">
                </div>
                <span>产品经理审批</span>
              </div>
              <div class="state-in">
                <div class="confirm-box">
                  <img src="/img/order/icon-2.png" v-if="checkApprovals(l.approvals,'sales_director')==1">
                  <img src="/img/order/icon-3.png" v-if="checkApprovals(l.approvals,'sales_director')==2">
                </div>
                <span>总监审批</span>
              </div>
            </div> -->

          </div>
        </template>
      </div>
    </div>
    <Upbox :type="'express'" @close="expShow=false" v-if="expShow" :express="express"></Upbox>
    <Footer v-model="user" :id="2"></Footer>
  </div>
</template>
<script>

import Footer from '../components/Footer.vue'
import Goods from '../components/Goods.vue'
import Upbox from '../components/Upbox.vue'
export default {
  name: 'Home',
   data: function () {
    return {
      isSelect:'0',
      orderClick:false,
      orderState:'0',
      expShow:false,
      list:[],
      isItem:0,
      express:[],
      user:[],
      approvalsCache:{
        regional_manager:'大区经理',
        regional_marketing_manager:'区域市场经理',
        sales_director:'总监',
        financial_staff:'财务分析团队',
        product_manager:'产品经理',
        medical_regional_assistant:'医用大区助理',
        sale_manager:'销售经理',
        sales_empowerment_manager:'销售赋能经理',
        channel_manager:'渠道经理'
      },
      listNumber:[
        {
          'wait':0,
          'adopt':0,
          'refuse':0
        },
        {
          'wait':0,
          'adopt':0,
          'refuse':0
        },
        {
          'wait':0,
          'adopt':0,
          'refuse':0
        }
      ]
    }
  },
  components: {Footer,Goods,Upbox},
  methods:{
    getIsApproval(a){
      let data=0
      a.map(r=>{
        if(r.approvalRole==this.user.roles[0]){
          data=r.approvalStatus
        }
      })
      return data
    },
    getListNumber(){
      let vm = this
      vm.listNumber=[
        {
          'wait':0,
          'adopt':0,
          'refuse':0
        },
        {
          'wait':0,
          'adopt':0,
          'refuse':0
        },
        {
          'wait':0,
          'adopt':0,
          'refuse':0
        }
      ]
      this.list.map(r=>{
        if(r.orderType==0&&r.approvalStatus==0){
          vm.listNumber[0].wait+=1
        }
        if(r.orderType==1&&r.approvalStatus==0){
          vm.listNumber[1].wait+=1
        }
        if(r.orderType==2&&r.approvalStatus==0){
          vm.listNumber[2].wait+=1
        }

        if(r.orderType==0&&r.approvalStatus==1){
          vm.listNumber[0].adopt+=1
        }
        if(r.orderType==1&&r.approvalStatus==1){
          vm.listNumber[1].adopt+=1
        }
        if(r.orderType==2&&r.approvalStatus==1){
          vm.listNumber[2].adopt+=1
        }

        if(r.orderType==0&&r.approvalStatus==2){
          vm.listNumber[0].refuse+=1
        }
        if(r.orderType==1&&r.approvalStatus==2){
          vm.listNumber[1].refuse+=1
        }
        if(r.orderType==2&&r.approvalStatus==2){
          vm.listNumber[2].refuse+=1
        }


        console.log(r)

        // l.orderType==isSelect&&
        // l.approvalStatus==orderState
      })
    },
    checkApprovals(approvals,check){
      var set=0
      approvals.map(a=>{
        if(a.approvalRole==check){
          set = a.approvalStatus
        }
      })
      return set
    },
    reChange:function(d){
      d.size=d.number
      d.productPrice=d.price
      return d
    },
    linkShop:function(){
      this.$router.push({path:'/shop'})
    },
    getFloat2: function(x){
      if (x != '.'){
        var f = Math.round(x * 100) / 100;
        var s = f.toString();
        var rs = s.indexOf('.');
        if (rs <= 0) {
          rs = s.length;
          s += '.';
        }
        while (s.length <= rs + 2) {
          s += '0';
        }
        return s;
      }else{
        return '0.00';
      }
    },
    secondCategoryHas(l,n){
      let has=false
        l.map(d=>{

          if(d.firstCategory==0&&d.secondCategory==n&&d.number){
            has=true
          }
        })
      return has
    },
    secondCategoryAllPrice(l,n){
      let price=0
      l.map(d=>{
        if(d.firstCategory==0&&d.secondCategory==n&&d.number){
          price+=d.productPrice*d.number
        }
      })
      return this.getFloat2(price)
    },
    firstCategoryAllPrice(l,n){
        let price=0
        // console.log(this.list)
        l.map(d=>{
          if(d.firstCategory==n&&d.size){
            price+=d.productPrice*d.size
          }
        })

        return this.getFloat2(price)
    },
  },
  mounted(){
    var vm = this
    this.$axios.get('business/order/list', {}).then(function (res) {
      console.log(res)
      vm.list=res.rows
      vm.getListNumber()
    })
  }

}
</script>
