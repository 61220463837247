<template>
  <div class="images-up">
    <div class="in-line" v-for=" (img,i) in images" v-bind:key="i">
      <img src="/img/upimages/close.png" class="close" v-if="img!=''" @click="removeImasge(i)">
      <div class="in-line-box">
        <img src="/img/upimages/box.png"  class="box-img">
        <img :src="baseUrl+img" v-if="img!=''" class="show-img">
        <input type="file" @change="upFlie($event)" class="input" v-if="img==''" accept="image/*">
      </div>
    </div>
    <div class="in-line" >
      <!-- <img src="/img/upimages/close.png" class="close" v-if="i!=''" @click="i=''"> -->
      <div class="in-line-box">
        <img src="/img/upimages/box.png"  class="box-img">
        <!-- <img :src="baseUrl+i" v-if="i!=''" class="show-img"> -->
        <input type="file" @change="upFlie($event)" class="input" accept="image/*">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Upbox',
  data: function () {
    return {
      state:'上传文件',
      fileName:"",
      baseUrl:process.env.VUE_APP_BASE_URL,
      images:[]
    }
  },
  props: {
    // images:Array,
    getImages: Array,
    type: String,
    data:Object,
    express:Array
  },
  watch:{
    images:function(){
      if(this.images){
        this.$emit('input',this.images.toString());
      }else{
        this.$emit('input',[]);
      }
      
    }
  },
  methods:{
    removeImasge(i){
      // console.log(i)
      this.images.splice(i,1)
      // console.log(this.images)
    },
    close(){
        this.$emit('close');
    },
    ok(){
      this.$emit('ok');
    },
    linkUrl(o){
      window.open('https://www.baidu.com/s?wd='+o)
    },
    upFlie(e){
      // 文件上传
      let vm=this
      let file = e.target.files[0]; //获取文件
      // console.log(file.name)
      var formData = new FormData();
      formData.append('file',file);
      vm.state='上传中……';
      this.$axios.post('/common/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
      }).then(function (res) {
        // vm.$emit('state','上传成功');
        // vm.state='上传成功';
        // vm.state=file.name;
        vm.fileName=res.fileName;
        // vm.$emit('input',res.fileName+','+file.name);
        
        vm.images.push(res.fileName)
        // console.log(vm.images)
      }).catch(function (error) {
        vm.state='上传失败';
        // vm.$emit('state','上传失败');
        return
      });
    }
  },
  mounted(){
    // 
    this.images=this.getImages
  }
}
</script>
