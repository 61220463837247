<template>
  <div class="viewers" >
      <div class="images">
        <img :src="images" alt="" srcset="">
      </div>
      <div class="back" @click="back()">返回</div>
  </div>
</template>
<script>
export default {
  name: 'Folder',
  data: function () {
    return {

    }
  },
  props: {
    images:String
  },
  methods:{
    back(){
    this.$emit('back');
    }
  },
  mounted(){

  }
}
</script>
