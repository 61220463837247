<template>
  <div class="my-order examine">
    <div class="nav-bar">
      <div class="select-box" >
        <div :class="isSelect=='0'?'on':''" @click="isSelect='0';getList()">日常资料</div>
        <div :class="isSelect=='2'?'on':''" @click="isSelect='2';getList()">样品相关</div>
        <div :class="isSelect=='1'?'on':''" @click="isSelect='1';getList()">动销物料</div>
      </div>
    </div>
    <div class="order-conetnt">
      <div class="left">
        <span :class="orderState=='0'?'on':''" @click="orderState='0';getList()"  >待审批订单(<abbr :key="demoKey">{{listSize[0]}}</abbr>)</span>
        <span :class="orderState=='1'?'on':''" @click="orderState='1';getList()"  >已审批订单(<abbr :key="demoKey">{{listSize[1]}}</abbr>)</span>
        <span :class="orderState=='2'?'on':''" @click="orderState='2';getList()"  >拒批订单(<abbr :key="demoKey">{{listSize[2]}}</abbr>)</span>
      </div>
      <div class="right">
        <center v-if="
          (listSize[0]==0&&orderState==0)||
          (listSize[1]==0&&orderState==1)||
          (listSize[2]==0&&orderState==2)
        ">
          <h2 style="color:#ccc">
            暂无订单
          </h2>
        </center>
        <template v-for="(l , i) in list"   >
          <div
              :class="isItem==i?'order-in-line on':'order-in-line'"
              v-bind:key="i"
              @click="isItem=i"
              v-if="
                l.orderType==isSelect&&
                getIsApproval(l.approvals)==orderState
              "

          >

              <div class="examine-box">
                <div class="dots" v-if="orderState=='0'" @click="l.dots=!l.dots;changeDemo()" :key="demoKey" ><span v-if="l.dots"></span></div>
                <div class="other-box">
                  <div class="order-title">
                    <span>{{user.roles[0]=='sales_director'?l.regionName+' ':''}}{{l.user.nickName}}<br>{{l.createTime}} 订单</span>
                    <img src="/img/shop/icon-3.png" class="icon">
                  </div>

                  <div class="info">
                      <div class="info-left" style="max-width:400px">
                        <div class="order-number">订单号：{{l.orderId}}</div>
                        <div class="prize">订单总价：¥{{l.orderAmount}}</div>
                        <!-- <br v-if="l.reasonDes"> -->
                        <!-- <div class="order-number" v-if="l.reasonDes" >
                          申请原因：{{l.reasonDes}}
                        </div> -->
                        <br v-if="l.budgetOverReason">
                        <div class="order-number" v-if="l.budgetOverReason" >
                          超预算申请原因：{{l.budgetOverReason}}
                        </div>
                        <!--  -->
                        <div class="info-more" v-if="isItem==i"  @click="data=l">查看申请原因</div>
                      </div>
                      <!-- <div class="info-right" v-if="orderState=='1'&&l.delivers.length==0">
                        <span>待发货</span>
                      </div>
                      <div class="info-right" v-if="orderState=='1'&&l.delivers.length!=0">
                        <abbr  @click.stop="express=l.delivers;expShow=true">查看快递信息</abbr>
                      </div> -->
                  </div>

                </div>

              </div>

              <div class="order-list" v-if="secondCategoryHas(l.details,0)">
                <div class="buy-inline">
                  <div class="buy-left">品牌提示物</div>
                    <div class="buy-right">
                      <template v-for="(d,a) in l.details">
                        <div class="goods-line"  v-bind:key="a" v-if="d.secondCategory==0&&d.firstCategory==0">
                          <Goods :id="i" :isOrder="true" :data="reChange(d)"></Goods>
                        </div>
                      </template>
                      <div class="info">
                        <div>品牌提示物订单总价：¥{{secondCategoryAllPrice(l.details,0)}}</div>
                        <div>个人剩余预算：¥{{getFloat2(l.user.budgetGift-l.user.budgetGiftApply)}}</div>
                        <div>大区预算：¥{{getFloat2(l.user.dept.budgetGift)}}</div>
                        <div>大区剩余预算：¥{{getFloat2(l.user.dept.budgetGift-l.user.dept.budgetGiftApply)}}</div>
                        <div></div>
                      </div>
                  </div>
                </div>
              </div>
              <div class="order-list" v-if="secondCategoryHas(l.details,1)">
                <div class="buy-inline">
                  <div class="buy-left">彩页</div>
                    <div class="buy-right">
                      <template v-for="(d,a) in l.details">
                        <div class="goods-line" v-bind:key="a" v-if="d.secondCategory==1&&d.firstCategory==0">
                          <Goods :id="i" :isOrder="true" :data="reChange(d)"></Goods>
                        </div>
                      </template>
                      <div class="info">
                        <div>彩页订单总价：¥{{secondCategoryAllPrice(l.details,1)}}</div>
                        <div>个人剩余预算：¥{{getFloat2(l.user.budgetColorPage-l.user.budgetColorPageApply)}}</div>
                        <div>大区预算：¥{{getFloat2(l.user.dept.budgetColorPage)}}</div>
                        <div>大区剩余预算：¥{{getFloat2(l.user.dept.budgetColorPage-l.user.dept.budgetColorPageApply)}}</div>
                        <div></div>
                      </div>
                  </div>
                </div>
              </div>
              <div class="order-list" v-if="secondCategoryHas(l.details,2)">
                <div class="buy-inline">
                  <div class="buy-left">购物袋</div>
                    <div class="buy-right">
                      <template v-for="(d,a) in l.details">
                        <div class="goods-line"  v-bind:key="a" v-if="d.secondCategory==2&&d.firstCategory==0">
                          <Goods :id="i" :isOrder="true" :data="reChange(d)"></Goods>
                        </div>
                      </template>
                      <div class="info">
                        <div>购物袋订单总价：¥{{secondCategoryAllPrice(l.details,2)}}</div>
                        <div>个人剩余预算：¥{{getFloat2(l.user.budgetShoppingBag-l.user.budgetShoppingBagApply)}}</div>
                        <div>大区预算：¥{{getFloat2(l.user.dept.budgetShoppingBag)}}</div>
                        <div>大区剩余预算：¥{{getFloat2(l.user.dept.budgetShoppingBag-l.user.dept.budgetShoppingBagApply)}}</div>
                      </div>
                  </div>
                </div>
              </div>

              <div class="order-list" v-if="isSelect!=0">
                <div class="buy-inline">
                    <!-- <div class="buy-left">购物袋</div> -->
                    <div class="buy-right">
                      <template v-for="(d,a) in l.details">
                        <div class="goods-line"  v-bind:key="a" v-if="d.firstCategory==isSelect">
                          <Goods :id="i" :isOrder="true" :data="reChange(d)"></Goods>
                        </div>
                      </template>
                      <div class="info">
                        <div>{{isSelect==1?'动销物料':'样品相关'}}订单总价：¥{{firstCategoryAllPrice(l.details,isSelect)}}</div>
                        <div v-if="l.user.dept">
                          {{isSelect==1?'个人':'个人'}}剩余预算：¥
                          {{
                            isSelect==1?
                            getFloat2(l.user.budgetMovingSales-l.user.budgetMovingSalesApply):
                            getFloat2(l.user.budgetSample-l.user.budgetSampleApply)
                          }}
                        </div>
                        <div v-if="l.user.dept">
                          大区预算：¥
                          {{
                            isSelect==1?
                            getFloat2(l.user.dept.budgetMovingSales):
                            getFloat2(l.user.dept.budgetSample)
                          }}
                        </div>
                        <div v-if="l.user.dept">
                          大区剩余预算：¥
                          {{
                            isSelect==1?
                            getFloat2(l.user.dept.budgetMovingSales-l.user.dept.budgetMovingSalesApply):
                            getFloat2(l.user.dept.budgetSample-l.user.dept.budgetSampleApply)
                          }}
                        </div>
                        <div v-if="!l.user.dept">
                          用户信息已变更，无预算信息
                        </div>
                        <div></div>
                      </div>
                  </div>
                </div>
              </div>
          </div>
        </template>
        <div class="order-add-line" @click="getOrder()" v-if="listSize[orderState] > list.length & list.length>0">
          加载更多
        </div>
      </div>
    </div>
    <div class="examine-bottom" :key="demoKey" >
      <div class="examine-all" @click="selectAllDots()">
        <div class="dots"><span v-if="isSelectAll"></span></div>
        <span>全选</span>
      </div>
      <div class="examine-price">
        合计：￥{{getFloat2(priceAll)}}
      </div>
      <div class="examine-btn">
        <span @click="isUpState=1;upData.isShow=true;upData.title='正在进行通过审批操作'">审批</span>
        <span @click="isUpState=2;upData.isShow=true;upData.title='正在进行拒绝审批操作'">拒绝</span>
      </div>
    </div>

    <div class="up-info" v-if="data" @click="data=''">
      <div class="up-info-box">
        <!-- 科室会 -->
        <template v-if="data.reasonType==0">
          <div class="track-in-box-line" >
            <div class="lable">申请原因</div>
            <div class="info">科室会</div>
          </div>
          <div class="track-in-box-line" >
            <div class="lable">会议时间</div>
            <div class="info">{{cutTime(data.reasonOfDept.meetingTime)}}</div>
          </div>
          <div class="track-in-box-line" >
            <div class="lable">医院名称</div>
            <div class="info">{{data.reasonOfDept.hospitalName}}</div>
          </div>
          <div class="track-in-box-line" >
            <div class="lable">科室名称</div>
            <div class="info">{{data.reasonOfDept.deptName}}</div>
          </div>
          <div class="track-in-box-line">
            <div class="lable">申请说明</div>
            <div class="info">{{data.reasonDes}}</div>
          </div>
        </template>

        <!-- 区域会 -->
        <template v-if="data.reasonType==1">
          <div class="track-in-box-line" >
            <div class="lable">申请原因</div>
            <div class="info">区域会</div>
          </div>
          <div class="track-in-box-line" >
            <div class="lable">会议时间</div>
            <div class="info">{{cutTime(data.reasonOfRegion.meetingTime)}}</div>
          </div>
          <div class="track-in-box-line" >
            <div class="lable">会议名称</div>
            <div class="info">{{data.reasonOfRegion.meetingName}}</div>
          </div>
          <div class="track-in-box-line" >
            <div class="lable">会议地点</div>
            <div class="info">{{data.reasonOfRegion.meetingAddr}}</div>
          </div>
          <div class="track-in-box-line">
            <div class="lable">具体原因</div>
            <div class="info">{{data.reasonDes}}</div>
          </div>
        </template>

        <!-- KOL拜访 -->
        <template v-if="data.reasonType==2">
          <div class="track-in-box-line" >
            <div class="lable">申请原因</div>
            <div class="info">KOL拜访</div>
          </div>
          <div class="track-in-box-line" >
            <div class="lable">拜访时间</div>
            <div class="info">{{cutTime(data.reasonOfKol.visitTime)}}</div>
          </div>

          <div class="track-in-box-line" >
            <div class="lable">医院名称</div>
            <div class="info">{{data.reasonOfKol.hospitalName}}</div>
          </div>
          <div class="track-in-box-line" >
            <div class="lable">科室名称</div>
            <div class="info">{{data.reasonOfKol.deptName}}</div>
          </div>
          <div class="track-in-box-line" >
            <div class="lable">拜访人</div>
            <div class="info">{{data.reasonOfKol.kolName}}</div>
          </div>
          <div class="track-in-box-line">
            <div class="lable">申请说明</div>
            <div class="info">{{data.reasonDes}}</div>
          </div>
        </template>

        <!-- 产品动销 -->
        <template v-if="data.reasonType==3">
          <div class="track-in-box-line" >
            <div class="lable">申请原因</div>
            <div class="info">产品动销</div>
          </div>
          <div class="track-in-box-line" >
            <div class="lable">活动类别</div>
            <div class="info">{{data.reasonOfSale.activeCate}}</div>
          </div>

          <div class="track-in-box-line">
            <div class="lable">地区</div>
            <div class="info">{{data.reasonOfSale.distributor.addrProvince}} {{data.reasonOfSale.distributor.addrCity}}</div>
          </div>
          <div class="track-in-box-line">
            <div class="lable">经销商</div>
            <div class="info">{{data.reasonOfSale.distributor.distributorName}}</div>
          </div>
          <div class="track-in-box-line">
            <div class="lable">年度指标</div>
            <div class="info">{{data.reasonOfSale.distributor.annualIndicators}}</div>
          </div>
          <div class="track-in-box-line">
            <div class="lable">地址</div>
            <div class="info">{{data.reasonOfSale.distributor.addrDetail}}</div>
          </div>
          <div class="track-in-box-line">
            <div class="lable">申请说明</div>
            <div class="info">{{data.reasonDes}}</div>
          </div>
        </template>

        <!-- 其他原因 -->
        <template v-if="data.reasonType==4">
            <div class="track-in-box-line" >
              <div class="lable">申请原因</div>
              <div class="info">其他原因</div>
            </div>
            <div class="track-in-box-line">
              <div class="lable">申请说明</div>
              <div class="info">{{data.reasonDes}}</div>
            </div>
        </template>
      </div>
    </div>
    <Upbox :type="'express'" @close="expShow=false" v-if="expShow" :express="express"></Upbox>
    <Upbox @close="upData.isShow=false" @ok="sendExamine()" v-if="upData.isShow" :type="'tips'" :data="{back:upData.back,ok:upData.ok,title:upData.title,desc:upData.desc}"></Upbox>
    <Upbox @close="sendError=''"  v-if="sendError" :type="'tips'" :data="{back:'确认',title:sendError}"></Upbox>
    <Footer v-model="user" :id="2"></Footer>
  </div>
</template>
<script>

import Footer from '../../components/Footer.vue'
import Goods from '../../components/Goods.vue'
import Upbox from '../../components/Upbox.vue'
export default {
  name: 'Home',
   data: function () {
    return {
      isSelect:'0',
      orderClick:false,
      orderState:'0',
      expShow:false,
      list:[],
      isItem:0,
      user:'',
      demoKey:'demo-' + +new Date() + ((Math.random() * 1000).toFixed(0) + ''),
      isSelectAll:false,
      priceAll:0,
      isUpState:2,
      sendError:'',
      express:[],
      upData:{
        isShow:false,
        ok:'继续',
        back:'返回',
        title:'',
        desc:'该操作不可撤销,请确认！'
      },
      data:'',
      listSize:[0,0,0],
      pageNum:1,
      pageSize:15
    }
  },
  components: {Footer,Goods,Upbox},
  watch:{
    isSelect(){
      this.list.map((l,i)=>{
        this.list[i].dots=false
      })
      this.changeDemo()
    }
  },
  methods:{
    linkShop:function(){
      this.$router.push({path:'/shop'})
    },
    getIsApproval(a){
      let data=0
      a.map(r=>{
        if(r.approvalRole==this.user.roles[0]){
          data=r.approvalStatus
        }
      })
      return data
    },
    cutTime(date){
      return date.split(' ')[0]
    },
    sendExamine(){
      let vm=this
      let examine={
        billIds:[],
        approvalStatus:vm.isUpState
      }
      this.list.map((l,i)=>{
        if(l.dots){
          examine.billIds.push(l.orderId)
        }
      })
      if(examine.billIds.length==0){
        vm.sendError='请选择订单后进行操作！'
        vm.upData.isShow=false
        return
      }
      // examine.billIds=String(examine.billIds)
      this.$axios.put('/business/order/approve', examine).then(function (res) {
        vm.sendError=res.msg
        vm.upData.isShow=false
        vm.getList()
      })
    },
    selectAllDots(){
      let vm=this
      this.list.map((l,i)=>{
        if(l.details[0].firstCategory==vm.isSelect&&l.approvalStatus==0){
          if(vm.isSelectAll){
            l.dots=false
          }else{
            l.dots=true
          }
        }
      })
      this.checkSelectAll()
    },
    checkSelectAll(){
      let vm=this
      let number=0
      let dots=0
      let price=0
      this.list.map((l,i)=>{
        if(l.details[0].firstCategory==vm.isSelect&&l.approvalStatus==0){
          number++

          if(l.dots==true){
            dots++
            price+=l.orderAmount
          }

        }
      })
      if(number==dots){
        this.isSelectAll=true
      }else{
        this.isSelectAll=false
      }
      this.priceAll=price
    },
    changeDemo(){
      this.checkSelectAll()
      this.demoKey='demo-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
    },
    checkApprovals(approvals,check){
      var set=0
      approvals.map(a=>{
        if(a.approvalRole==check){
          set = a.approvalStatus
        }
      })
      return set
    },
    reChange:function(d){
      d.size=d.number
      d.productPrice=d.price
      return d
    },
    getFloat2: function(x){
      if (x != '.'){
        var f = Math.round(x * 100) / 100;
        var s = f.toString();
        var rs = s.indexOf('.');
        if (rs <= 0) {
          rs = s.length;
          s += '.';
        }
        while (s.length <= rs + 2) {
          s += '0';
        }
        return s;
      }else{
        return '0.00';
      }
    },
    secondCategoryHas(l,n){
      let has=false
        l.map(d=>{

          if(d.firstCategory==0&&d.secondCategory==n&&d.number){
            has=true
          }
        })
      return has
    },
    secondCategoryAllPrice(l,n){
      let price=0
      l.map(d=>{
        if(d.firstCategory==0&&d.secondCategory==n&&d.number){
          price+=d.productPrice*d.number
        }
      })
      return this.getFloat2(price)
    },
    firstCategoryAllPrice(l,n){
        let price=0
        // console.log(this.list)
        l.map(d=>{
          if(d.firstCategory==n&&d.size){
            price+=d.productPrice*d.size
          }
        })

        return this.getFloat2(price)
    },
    getOrder(){
      var vm = this
      vm.pageNum++
      this.$axios.get('/business/order/approveList?pageNum='+this.pageNum+'&pageSize='+this.pageSize+'&approvalStatus='+this.orderState+'&orderType='+this.isSelect, {
      }).then(function (res) {
        // console.log(res.rows)
        res.rows.map(r=>{
          vm.list.push(r)
        })

        // console.log(vm.list)
      })
    },
    getList(){
      var vm = this
      vm.list=[]
      vm.pageNum = 0
      vm.getOrder()

      // this.$axios.get('/business/order/approveList?pageNum='+this.pageNum+'&pageSize='+this.pageSize+'&approvalStatus='+this.orderState+'&orderType='+this.isSelect, {
      // }).then(function (res) {
      //   // console.log(res.rows)
      //   vm.list=res.rows
      // })

      vm.$axios.get('/business/order/approveList?pageNum=1&pageSize=1&approvalStatus='+0+'&orderType='+vm.isSelect, {
      }).then(function (res) {
        vm.listSize[0]=res.total
        vm.changeDemo()
      })

      vm.$axios.get('/business/order/approveList?pageNum=1&pageSize=1&approvalStatus='+1+'&orderType='+vm.isSelect, {
      }).then(function (res) {
        vm.listSize[1]=res.total
        vm.changeDemo()
      })

      vm.$axios.get('/business/order/approveList?pageNum=1&pageSize=1&approvalStatus='+2+'&orderType='+vm.isSelect, {
      }).then(function (res) {
        vm.listSize[2]=res.total
        vm.changeDemo()
      })
    }
  },
  mounted(){
    this.getList()
  }
}
</script>
