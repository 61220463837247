<template>
  <div class="order-page">
    <div class="order-center">
      <div class="address-title">订单配送到：</div>
      <div class="dealer-select-box" v-if="reason.reason==3">
        <div class="dealer-in">
          <div class="dots" @click="isDealer=true"><span v-if="isDealer"></span></div><div class="fonts">邮寄给经销商</div>
        </div>
        <div class="dealer-in">
          <div class="dots" @click="isDealer=false"><span v-if="!isDealer"></span></div><div class="fonts">邮寄给个人</div>
        </div>
      </div>
      <div class="address-show" v-if="isDealer&&reason.dealer">
        <div class="address-line">
          <span>经销商</span>
          <div class="info-show">{{reason.dealer.distributorName}}</div>
        </div>
        <div class="address-line">
          <span></span>
          <div class="info-show">
            <abbr @click="reset">重新选择经销商</abbr>
          </div>
        </div>

        <div class="address-line">
          <span>年度指标</span>
          <div class="info-show">{{reason.dealer.annualIndicators}}</div>
        </div>
        <div class="address-line">
          <span>地址</span>
          <div class="info-show">
            <input type="text" v-model="sendData.consigneeAddr">
          </div>
        </div>
        <div class="address-line">
          <span>收件人</span>
          <div class="info-show">
            <input type="text" v-model="sendData.consignee" placeholder="请填写收货人">
          </div>
        </div>
        <div class="address-line">
          <span>联系电话</span>
          <div class="info-show">
            <input type="text" v-model="sendData.consigneeTelephone" placeholder="请填写联系电话">
          </div>
        </div>
      </div>
      <div class="address-show" v-if="!isDealer&&addressList.length>0" @click="isAddress=true">
        <div class="address">{{addressList[addressSelect].addrDetail}}</div>
        <div class="user-info">
          {{addressList[addressSelect].consignee}}<br>
          {{addressList[addressSelect].consigneeTelephone}}
        </div>
        <img src="/img/order/icon-1.png" class="icon">
      </div>
      <div class="address-show" v-if="!isDealer&&addressList.length==0" @click="isAddress=true">
        <div class="address">请填写收货地址</div>
        <div class="user-info">
          点击这里填写收件人信息<br>
        </div>
        <img src="/img/order/icon-1.png" class="icon">
      </div>

      <div class="express-select">
        <div class="express-in">
          <div class="dots" @click="sendData.expressType=0" ><span v-if="sendData.expressType==0"></span></div><div class="fonts">常规中通快递 <abbr class="sm">（公司月结，7个工作日发货）</abbr></div>
        </div>
        <div class="express-in">
          <div class="dots" @click="sendData.expressType=1"><span v-if="sendData.expressType!=0"></span></div><div class="fonts">申请加急 <abbr class="cl" >（顺丰到付，顺丰快递发货）</abbr></div>
        </div>
      </div>
      <div class="order-content">
          <div class="order-inline" v-if="secondCategoryHas(0)">
            <div class="left">品牌提示物</div>
            <div class="right sm">
              <template v-for="(l,i) in list">
                <div class="goods-line"  v-bind:key="i" v-if="l.firstCategory==0&&l.secondCategory==0&&l.size">
                  <Goods @change="changeDemo()" :id="i" :isOrder="true" :data="l" v-model="list[i].size" ></Goods>
                </div>
              </template>
              <div class="info">
                <div>品牌提示物订单总价：¥{{secondCategoryAllPrice(0)}}</div>
              </div>
            </div>
          </div>
          <div class="order-inline" v-if="secondCategoryHas(1)">
            <div class="left">彩页</div>
            <div class="right sm">
              <template v-for="(l,i) in list">
                <div class="goods-line"  v-bind:key="i" v-if="l.firstCategory==0&&l.secondCategory==1&&l.size">
                  <Goods @change="changeDemo()" :id="i" :isOrder="true" :data="l" v-model="list[i].size" ></Goods>
                </div>
              </template>
              <div class="info">
                <div>彩页订单总价：¥{{secondCategoryAllPrice(1)}}</div>
              </div>
            </div>
          </div>
          <div class="order-inline" v-if="secondCategoryHas(2)">
            <div class="left">样品</div>
            <div class="right sm">
              <template v-for="(l,i) in list">
                <div class="goods-line"  v-bind:key="i" v-if="l.firstCategory==0&&l.secondCategory==2&&l.size">
                  <Goods @change="changeDemo()" :id="i" :isOrder="true" :data="l" v-model="list[i].size" ></Goods>
                </div>
              </template>
              <div class="info">
                <div>样品订单总价：¥{{secondCategoryAllPrice(2)}}</div>
              </div>
            </div>
          </div>


          <div class="order-inline" v-if="firstCategoryHas(1)">
            <!-- <div class="left">动销</div> -->
            <div class="right sm">
              <template v-for="(l,i) in list">
                <div class="goods-line"  v-bind:key="i" v-if="l.firstCategory==1&&l.size">
                  <Goods @change="changeDemo()" :id="i" :isOrder="true" :data="l" v-model="list[i].size" ></Goods>
                </div>
              </template>
              <div class="info">
                <div>动销物料订单总价：¥{{firstCategoryAllPrice(1)}}</div>
              </div>
            </div>
          </div>
          <div class="order-inline" v-if="firstCategoryHas(2)">
            <!-- <div class="left">样品</div> -->
            <div class="right sm">
              <template v-for="(l,i) in list">
                <div class="goods-line"  v-bind:key="i" v-if="l.firstCategory==2&&l.size">
                  <Goods @change="changeDemo()" :id="i" :isOrder="true" :data="l" v-model="list[i].size" ></Goods>
                </div>
              </template>
              <div class="info">
                <div>样品相关订单总价：¥{{firstCategoryAllPrice(2)}}</div>
              </div>
            </div>
          </div>
          <!-- <div class="buy-inline" v-if="firstCategoryHas()">
            <div class="left"></div>
            <div class="right">
              <div class="goods-line" v-for="(l,i) in list" v-bind:key="i" v-if="l.firstCategory!=0&&l.size">
                <Goods @change="changeDemo()" :id="i" :data="l" v-model="list[i].size" ></Goods>
              </div>
              <div class="info">
                <div >{{isSelect=='material'?'动销物料':'样品相关'}}订单总价：¥{{firstCategoryAllPrice(selectMap[isSelect])}}</div>
                <div>{{isSelect=='material'?'动销物料':'样品相关'}}剩余预算：¥220.00</div>
              </div>
            </div>
          </div> -->

          <div class="pay-number">
            实付：<span>¥{{allPrice(0)}}</span>
          </div>
      </div>
      <div class="order-enter" @click="sendOrder()">
        {{isSend?'提交中，请稍后':'提交订单'}}
      </div>
    </div>

    <div class="exceed-up" v-if="isExceed">
      <div class="exceed-center">
        <div class="title">预算额度超支！</div>
        <div class="exceed-line">
          <span>原因</span>
          <div>
            <textarea v-model="sendData.budgetOverReason" placeholder="请填写具体原因"></textarea>
          </div>
        </div>
        <div class="tips">提交后请耐心等待区域经理的审核</div>
        <div class="btns">
          <span @click="isExceed=false">取消</span>
          <span @click="sendOrder()">{{isSend?'提交中，请稍后':'确认'}}</span>
        </div>
      </div>
    </div>
    <Upbox @close="tipsShow=false" @ok="linkOrder()" v-if="tipsShow" :type="'tips'" :data="{state:'ok',ok:'查看订单',title:'提交成功！',desc:'订单已发往下一级审批<br>具体进展点击查看订单获取'}"></Upbox>
    <Upbox @close="sendError=''"  v-if="sendError" :type="'tips'" :data="{state:'error',back:'返回',title:'提交失败',desc:sendError}"></Upbox>
    <Address v-model="addressSelect" :addressSelect="addressSelect"  @close="isAddress=false;addressChage()" v-if="isAddress"></Address>
  </div>
</template>

<script>
import Goods from '../components/Goods.vue'
import Upbox from '../components/Upbox.vue'
import Address from '../components/Address.vue'
export default {
  name: 'Order',
  components: {Goods,Upbox,Address},
  data: function () {
    return {
      tipsShow:false,
      isDealer:this.reason.reason==3 ? 1 : 0,
      isExceed:false,
      isAddress:false,
      addressList:[],
      addressSelect: 0,
      sendData:{},
      sendError:'',
      isSend:false
    }
  },
  watch:{
    // addressSelect:function(){
    //     this.getAddressList()
    // }
  },
  props: {
    isSelect:String,
    list:Array,
    reason:Object,
    user:Object
  },
  methods:{
    addressChage(){
      this.getAddressList()
    },
    linkOrder(){
      this.$router.push({path:'/order'})
    },
    close(){
        this.$emit('close');
    },
    ok(){
      this.$emit('ok');
    },
    reset(){
      this.$emit('reset');
    },
    sendOrder(){
      // console.log(this.user.user.budgetGift)
      // console.log(this.addressSelect)
      // console.log(this.isDealer)
      // return;
      if(!this.isDealer&!this.addressList[this.addressSelect]){
        this.sendError='请完善收货信息'
        return
      }
      if(this.isDealer && (this.sendData.consigneeAddr==''||this.sendData.consignee==''||this.sendData.consigneeTelephone=='')){
        this.sendError='请完善收货信息'
        return
      }
      if(!this.isDealer){
      // if(this.reason.reason!=3||!this.isDealer){
        this.sendData.consigneeAddr=this.addressList[this.addressSelect].addrDetail
        this.sendData.consignee=this.addressList[this.addressSelect].consignee
        this.sendData.consigneeTelephone=this.addressList[this.addressSelect].consigneeTelephone
      }
      // console.log(this.sendData)

      if(this.sendData.budgetOverReason==""){
        if(this.secondCategoryHas(0)&&this.secondCategoryAllPrice(0)!='0.00'){
          if(Number(this.getFloat2(this.user.user.budgetGift-(this.user.user.budgetGiftApply?this.user.user.budgetGiftApply:0)-this.secondCategoryAllPrice(0)))<0){
            this.isExceed=true

            // this.sendError="您的品牌提示物预算不足，请填写超预算原因"
            return
          }
        }

        // console.log(this.secondCategoryAllPrice(1))
        if(this.secondCategoryHas(1)&&this.secondCategoryAllPrice(1)!='0.00'){
          if(Number(this.getFloat2(this.user.user.budgetColorPage-(this.user.user.budgetColorPageApply?this.user.user.budgetColorPageApply:0)-this.secondCategoryAllPrice(1)))<0){
            this.isExceed=true

            // this.sendError="您的品牌提示物预算不足，请填写超预算原因"
            return
          }
        }

        if(this.secondCategoryHas(2)&&this.secondCategoryAllPrice(2)!='0.00'){
          if(Number(this.getFloat2(this.user.user.budgetShoppingBag-(this.user.user.budgetShoppingBagApply?this.user.user.budgetShoppingBagApply:0)-this.secondCategoryAllPrice(2)))<0){
            this.isExceed=true

            // this.sendError="您的品牌提示物预算不足，请填写超预算原因"
            return
          }
        }

        if(this.firstCategoryAllPrice(1)&&this.firstCategoryAllPrice(1)!='0.00'){
          if(Number(this.getFloat2(this.user.user.budgetMovingSales-(this.user.user.budgetMovingSalesApply?this.user.user.budgetMovingSalesApply:0)-this.firstCategoryAllPrice(1)))<0){
            this.isExceed=true

            // this.sendError="您的动销物料预算不足，请填写超预算原因"
            return
          }
        }

        // console.log(this.firstCategoryAllPrice(2))
        if(this.firstCategoryAllPrice(2)&&this.firstCategoryAllPrice(2)!='0.00'){
          if(Number(this.getFloat2(this.user.user.budgetSample-(this.user.user.budgetSampleApply?this.user.user.budgetSampleApply:0)-this.firstCategoryAllPrice(2)))<0){
            this.isExceed=true

            // this.sendError="您的样品预算不足，请填写超预算原因"
            return
          }
        }
      }


      let vm =this
      if(vm.isSend){return}
      vm.isSend=true
      vm.$axios.post('/business/order', this.sendData).then(function (res) {
        if(res.code==200){
          vm.tipsShow=true
        }else{
          vm.sendError=res.msg
          vm.isSend=false
        }
      }).catch(function (error) {
        console.log(error);
        vm.isSend=false
        return
      });
    },
    firstCategoryHas(n){
      let has=false
      this.list.map(l=>{
        if(l.firstCategory==n&&l.size){
          has=true
        }
      })
      return has
    },
    allPrice(){
      let price=0
        this.list.map(l=>{
          if(l.size){
            price+=l.productPrice*l.size
          }
        })
        return this.getFloat2(price)
    },
    firstCategoryAllPrice(n){
        let price=0
        this.list.map(l=>{
          if(l.firstCategory==n&&l.size){
            price+=l.productPrice*l.size
          }
        })

        return this.getFloat2(price)
    },
    secondCategoryHas(n){
      let has=false
      this.list.map(l=>{
        if(l.firstCategory==0&&l.secondCategory==n&&l.size){
          has=true
        }
      })
      return has
    },
    secondCategoryAllPrice(n){
      let price=0
      this.list.map(l=>{
        if(l.firstCategory==0&&l.secondCategory==n&&l.size){
          price+=l.productPrice*l.size
        }
      })
      return this.getFloat2(price)
    },
    getFloat2: function(x){
      if (x != '.'){
        var f = Math.round(x * 100) / 100;
        var s = f.toString();
        var rs = s.indexOf('.');
        if (rs <= 0) {
          rs = s.length;
          s += '.';
        }
        while (s.length <= rs + 2) {
          s += '0';
        }
        return s;
      }else{
        return '0.00';
      }
    },
    getAddressList(){
      var vm = this
      this.$axios.get('/business/address/list', {}).then(function (res) {
        if(res.rows){
          vm.addressList=res.rows
        }
        //
        // console.log(res)
      })
    }
  },
  mounted(){
    this.getAddressList()
    // console.log(this.reason)


    // 数据映射
    this.sendData={

      orderType:this.reason.order, //订单类型：0-日常资料，1-动销物料，2-样品相关
      reasonType:this.reason.reason, //申请原因：0-科室会，1-区域会，2-KOL拜访，3-产品动销，4-其他原因

      // 科室会
      reasonOfDept:{
        meetingTime:this.reason.cache.time ? this.reason.cache.time + " 00:00:00":"", //会议时间
        hospitalName:this.reason.cache.hospital, //医院名称
        deptName:this.reason.cache.department //科室名称
      },

      // 区域会
      reasonOfRegion:{
        meetingTime:this.reason.cache.time ? this.reason.cache.time + " 00:00:00":"",//会议时间
        meetingName:this.reason.cache.meetingTitle,//会议名称
        meetingAddr:this.reason.cache.meetingPlace//会议地点
      },

      // KOL拜访
      reasonOfKol:{
        visitTime:this.reason.cache.time ? this.reason.cache.time + " 00:00:00":"",//拜访时间
        hospitalName:this.reason.cache.hospital,//医院名称
        deptName:this.reason.cache.department,//科室名称
        kolName:this.reason.cache.userName//kol姓名
      },

      // 产品动销
      reasonOfSale:{
        activeCate:this.reason.cache.activityCategory,//活动类型
        distributorId:this.reason.cache.distributorId//经销商ID
      },
      expressType:0,//物流类型：0-常规中通快递，1-申请加急
      consignee:'',//收货人
      consigneeTelephone:'',//收货电话
      consigneeAddr:'',//收货地址
      details:[],//产品明细
      reasonDes:this.reason.cache.explain,//申请说明
      budgetOverReason:''//超预算原因
    }

    // 初始化地址
    if(this.reason.reason==3){
      this.sendData.consigneeAddr=this.reason.cache.address
    }
    // 映射购物列表
    this.list.map(l=>{
      if(l.size){
        this.sendData.details.push({productId:l.productId,number:l.size})
      }
    })
  }
}
</script>
