<template>
  <div class="track-in" >
    <div class="back" @click="back()">返回</div>
    <div class="title">订单追踪详情</div>
    <div class="track-in-box" v-if="data.orderType">
      <div class="track-in-box-line">
        <div class="lable">订单号</div>
        <div class="info">{{data.orderId}}</div>
      </div>
      <div class="track-in-box-line">
        <div class="lable">订单商品</div>
        <div class="info" v-html="getOrderInfo(data.details)">
          <!-- 呼吸机 X 1 <br>
          彩页 X 10 <br>
          购物袋 X 10 <br> -->
        </div>
      </div>
      <div class="track-in-box-line">
        <div class="lable">订单金额</div>
        <div class="info">¥{{getFloat2(data.orderAmount)}}</div>
      </div>
      <div class="track-in-box-line">
        <div class="lable">订单类型</div>
        <div class="info">{{typeMaps[data.orderType]}}</div>
      </div>

      <!-- 科室会 -->
      <template v-if="data.orderType==0">
        <div class="track-in-box-line" >
          <div class="lable">会议时间</div>
          <div class="info">{{cutTime(data.reasonOfDept.meetingTime)}}</div>
        </div>
        <div class="track-in-box-line" >
          <div class="lable">医院名称</div>
          <div class="info">{{data.reasonOfDept.hospitalName}}</div>
        </div>
        <div class="track-in-box-line" >
          <div class="lable">科室名称</div>
          <div class="info">{{data.reasonOfDept.deptName}}</div>
        </div>
        <div class="track-in-box-line" v-if="data.logoPics">
          <div class="lable">照片</div>
          <div class="info">
            <img :src="baseUrl+i" v-for="i in data.logoPics.split(',')" v-bind:key="i" class="images"  @click="showImg=baseUrl+i">
          </div>
        </div>
        <div class="track-in-box-line">
          <div class="lable">覆盖人数</div>
          <div class="info">
            {{data.numberOfPeople}}
          </div>
        </div>
      </template>

      <!-- 区域会 -->
      <template v-if="data.orderType==1">
        <div class="track-in-box-line" >
          <div class="lable">会议时间</div>
          <div class="info">{{cutTime(data.reasonOfRegion.meetingTime)}}</div>
        </div>
        <div class="track-in-box-line" >
          <div class="lable">会议名称</div>
          <div class="info">{{data.reasonOfRegion.meetingName}}</div>
        </div>
        <div class="track-in-box-line" >
          <div class="lable">会议地点</div>
          <div class="info">{{data.reasonOfRegion.meetingAddr}}</div>
        </div>
        <div class="track-in-box-line" v-if="data.logoPics">
          <div class="lable">照片</div>
          <div class="info">
            <img :src="baseUrl+i" v-for="i in data.logoPics.split(',')" v-bind:key="i" class="images"  @click="showImg=baseUrl+i">
          </div>
        </div>
        <div class="track-in-box-line">
          <div class="lable">覆盖人数</div>
          <div class="info">
            <input type="text" v-model="numberOfPeople" placeholder="请填写覆盖人数">
            <img src="/img/close.png" class="input-close">
          </div>
        </div>
      </template>

      <!-- KOL拜访 -->
      <template v-if="data.orderType==2">
        <div class="track-in-box-line" >
          <div class="lable">拜访时间</div>
          <div class="info">{{cutTime(data.reasonOfKol.visitTime)}}</div>
        </div>

        <div class="track-in-box-line" >
          <div class="lable">医院名称</div>
          <div class="info">{{data.reasonOfKol.hospitalName}}</div>
        </div>
        <div class="track-in-box-line" >
          <div class="lable">科室名称</div>
          <div class="info">{{data.reasonOfKol.deptName}}</div>
        </div>
        <div class="track-in-box-line" >
          <div class="lable">拜访人</div>
          <div class="info">{{data.reasonOfKol.kolName}}</div>
        </div>
      </template>

      <!-- 产品动销 -->
      <template v-if="data.orderType==3">
        <div class="track-in-box-line" >
          <div class="lable">活动类别</div>
          <div class="info">{{data.reasonOfSale.activeCate}}</div>
        </div>

        <div class="track-in-box-line">
          <div class="lable">地区</div>
          <div class="info">{{data.reasonOfSale.distributor.addrProvince}} {{data.reasonOfSale.distributor.addrCity}}</div>
        </div>
        <div class="track-in-box-line">
          <div class="lable">经销商</div>
          <div class="info">{{data.reasonOfSale.distributor.distributorName}}</div>
        </div>
        <div class="track-in-box-line">
          <div class="lable">年度指标</div>
          <div class="info">{{data.reasonOfSale.distributor.annualIndicators}}</div>
        </div>
        <div class="track-in-box-line">
          <div class="lable">地址</div>
          <div class="info">{{data.reasonOfSale.distributor.addrDetail}}</div>
          <!-- <div class="info">{{data.consigneeAddr}}</div> -->
        </div>
        <div class="track-in-box-line">
          <div class="lable">收货地址</div>
          <!-- <div class="info">{{data.reasonOfSale.distributor.addrDetail}}</div> -->
          <div class="info">{{data.consigneeAddr}}</div>
        </div>
        <div class="track-in-box-line" v-if="data.logoPics">
          <div class="lable">照片</div>
          <div class="info">
            <img :src="baseUrl+i" v-for="i in data.logoPics.split(',')" v-bind:key="i" class="images"  @click="showImg=baseUrl+i">
          </div>
        </div>
      </template>
      
      <!-- 其他原因 -->
      <template v-if="data.orderType==4">
        <div class="track-in-box-line">
          <div class="lable">具体原因</div>
          <div class="info">{{data.reasonDes}}</div>
        </div>
        <div class="track-in-box-line" v-if="data.logoPics">
          <div class="lable">照片</div>
          <div class="info">
            <img :src="baseUrl+i" v-for="i in data.logoPics.split(',')" v-bind:key="i" class="images" @click="showImg=baseUrl+i">
          </div>
        </div>
      </template>

      <div class="track-in-btns is-one">
        <div @click="edit()">修改</div>
        <!-- <div @click="enter()">确定</div> -->
      </div>
    </div>
    <Upbox @close="sendError=''"  v-if="sendError" :type="'tips'" :data="{state:upState,back:upBack,title:sendError}"></Upbox>
    <div class="show-img-box" v-if="showImg" @click="showImg=''">
      <img :src="showImg">
    </div>
  </div>
</template>
<script>



// import Images from '../../../components/Images.vue'
import Upbox from '../../../components/Upbox.vue'
export default {
  name: 'Track',
  data: function () {
    return {
      user:{},
      data:{},
      numberOfPeople:'',
      sendError:'',
      upBack:'返回',
      upState:'error',
      baseUrl:process.env.VUE_APP_BASE_URL,
      showImg:''
    }
  },
  props: {
    orderId: Number,
    typeMaps:Array
  },
  components: {Upbox},
  methods:{
    edit(){
      this.$emit('edit');
    },
    enter(){
      let vm=this
      this.$axios.put('/business/orderTrack', {
        orderId:this.orderId,
        logoPics:this.data.logoPics,
        numberOfPeople:this.numberOfPeople
      }).then(function (res) {
        if(res.code==200){
          vm.sendError='提交成功'
          vm.upBack=''
          vm.upState='ok'
          setTimeout(()=>{
            vm.$emit('back');
          },1000)
        }else{
          vm.sendError=res.msg
          vm.upState='error'
          vm.upBack='返回'
        }
        // 
      })
    },
    back(){
      this.$emit('back');
      // 
      // 
    },
    getOrderInfo(data){
      if(!data){return}
      let rd = ''

      data.map(d=>{
        rd += d.productName +'x'+ d.number +'<br>'
      })

      return rd
    },
    cutTime(date){
      return date.split(' ')[0]
    },
    getFloat2: function(x){
      if (x != '.'){
        var f = Math.round(x * 100) / 100;
        var s = f.toString();
        var rs = s.indexOf('.');
        if (rs <= 0) {
          rs = s.length;
          s += '.';
        }
        while (s.length <= rs + 2) {
          s += '0';
        }
        return s;
      }else{
        return '0.00';
      }
    }
    // linkShop:function(){
    //   this.$router.push({path:'/shop'})
    // },
    // linkOrder:function(){
    //   this.$router.push({path:'/order'})
    // },
    // linkActivity:function(){
    //   this.$router.push({path:'/activity'})
    // },
    // linkFiles:function(){
    //   this.$router.push({path:'/files'})
    // },
  },
  mounted(){
    var vm = this
    this.$axios.get('/business/orderTrack/'+this.orderId, {}).then(function (res) {
      console.log(res)
      vm.data=res.data
    })
    // if(this.$route.query.type){
    //   window.location.href='/'+this.$route.query.type+window.location.search
    // }
  }

  
  
}
</script>
