<template>
  <div class="viewers" >
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: rgb(246, 246, 254); display: block;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <circle cx="50" cy="50" r="32" stroke-width="8" stroke="rgba(162, 126, 153, 0.7788709677419354)" stroke-dasharray="50.26548245743669 50.26548245743669" fill="none" stroke-linecap="round">
        <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
      </circle>
      <circle cx="50" cy="50" r="23" stroke-width="8" stroke="#955388" stroke-dasharray="36.12831551628262 36.12831551628262" stroke-dashoffset="36.12831551628262" fill="none" stroke-linecap="round">
        <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 50;-360 50 50"></animateTransform>
      </circle>
    </svg>
      <iframe :src='"https://view.officeapps.live.com/op/embed.aspx?src="+encodeURIComponent(office)' width='100%' height='100%' frameborder='0'></iframe>
      <div class="back" @click="back()">返回</div>
  </div>
</template>
<script>

export default {
  name: 'Folder',
  data: function () {
    return {

    }
  },
  props: {
    office:String
  },
  methods:{
     back(){
      this.$emit('back');
     }
  },
  mounted(){

  }
}
</script>
