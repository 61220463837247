<template>
  <div class="login">
    <img src="/img/login/bg.jpg" class="bg">
    <div class="login-box">
      <div class="in-box">
        <div class="login-line">
          <img src="/img/login/icon-1.png" class="icon">
          <input v-model="phoneNumber" type="text" placeholder="手机号码">
        </div>
        <div class="login-line">
          <img src="/img/login/icon-2.png" class="icon">
          <input type="text" maxlength="6"  v-model="verCode" placeholder="手机验证码">
          <div class="send-vcode" @click="sendVcode()">{{vCodeShow}}</div>
        </div>
        <div class="login-line is-login">
          <div class="enter" @click="login()">登录</div>
        </div>
        <!-- <div class="login-line is-wxwork" v-if="wxwork">
          <div class="enter" @click="wxLogin()">企业微信登录</div>
        </div> -->
      </div>
      <Record class="login-record"></Record>
    </div>
    <Upbox @close="error='';"  v-if="error" :type="'tips'" :data="{back:'我知道了',state:'error',title:'操作失败！',desc:error}"></Upbox>
    <div class="noQuestionnaire" v-if="isAuto">
      <!-- <img src="/img/empty.png" class="empty"> -->
      <div class="title">加载中……</div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /sr
import Cookies from 'js-cookie'
import Upbox from '../components/Upbox.vue'
import Record from '../components/Record.vue'
export default {
  name: 'Login',
  components: {Upbox,Record},
  data: function () {
    return {
      phoneNumber:'',
      verCode:'',
      vCodeTimer:'',
      vCodeNumber:60,
      vCodeShow:'获取验证码',
      error:'',
      wxwork:false,
      isAuto:false
    }
  },
  methods:{
    sendVcode:function(){
      if(this.vCodeShow=='获取验证码'){
        this.vCodeShow='发送中……'
        // /sendCode
        let vm =this
        vm.$axios.post('/sendCode?phoneNumber='+Number(vm.phoneNumber),null,{
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
        .then(function (res) {
          console.log(res)
          if(res.code==200){
            vm.vCodeTimer=setInterval(()=>{
            vm.vCodeNumber--
            vm.vCodeShow=vm.vCodeNumber+'s后重新获取'
            if(vm.vCodeNumber<=0){
              vm.vCodeNumber=60
              clearInterval(vm.vCodeTimer)
              vm.vCodeShow='获取验证码'
            }
          },1000)
            
        }else{
            vm.vCodeShow='获取验证码'
            vm.error=res.msg
        }
          
        }).catch(function (error) {
          vm.vCodeShow='获取验证码'
          vm.error='发送失败，请重试！'
          console.log(error)
          return
        });

        
      }
    },
    wxLogin(){
      // 
      // location.replace('https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc2576b908e564845&redirect_uri=http%3A%2F%2Fwww-test.resmedbelonging.com%2Flogin&response_type=code&scope=snsapi_base&agentid=1000003#wechat_redirect')
      window.location.href='https://open.weixin.qq.com/connect/oauth2/authorize?appid=wwf45026496a1ed540&redirect_uri=http%3A%2F%2Fwww.resmedbelonging.com%2Flogin&response_type=code&scope=snsapi_base&agentid=1000010#wechat_redirect'
    },
    login:function(){
      let vm = this
      clearInterval(vm.vCodeTimer)
      // 用户登陆
      vm.$axios.post('login_h5?phoneNumber='+encodeURIComponent(vm.phoneNumber)+'&verCode='+vm.verCode,null,{
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then(function (res) {
        // console.log(res)
        if(res.code==200){
          // 登陆成功
          Cookies.set('token',res.token, { expires: 365 })
          vm.$router.push({path:'/'})
          // location.replace('/')

        }else{
          // 登陆失败
          vm.error=res.msg
          // vm.error=true
        }
        
      }).catch(function (error) {
        console.log(error)
        return
      });
    }
  },
  mounted(){
    Cookies.set('token','')
    if(this.$route.query.type){
      window.location.href='/'+this.$route.query.type+window.location.search
    }

    // 判断用户是否是企业微信
    let ua = navigator.userAgent.toLowerCase(); // 将用户代理头的值转为小写
    if(ua.match(/wxwork/i) == 'wxwork' || ua.match(/micromessenger/i) == 'micromessenger'){
      this.wxwork=true
    }
    if(this.$route.query.auto){
      this.isAuto=true
      this.wxLogin()
    }
    if(this.$route.query.code){
      // 
      this.isAuto=true
      let vm = this
      clearInterval(vm.vCodeTimer)
      // 企业微信用户登陆
      vm.$axios.post('/work-weixin/login',{
        code:this.$route.query.code
      },{
        // headers: {
        //   'Content-Type': 'application/x-www-form-urlencoded'
        // }
      })
      .then(function (res) {
        // console.log(res)
        if(res.code==200){
          // 登陆成功
          Cookies.set('token',res.token, { expires: 365 })
          // location.replace('/')
          vm.$router.push({path:'/',query:{history:true}})
        }else{
          // 登陆失败
          vm.error=res.msg
          // vm.error=true
          vm.isAuto=false
        }
        
      }).catch(function (error) {
        // console.log(error)
        vm.isAuto=false
        return
      });

    }

  }
}
</script>
